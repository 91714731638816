import { gql } from 'apollo-angular';

const WITHDRAWALS_FIELDS = `
  amountCents
  id
  insertedAt
  status
  updatedAt
  wallet {
    currentBalance
    blockedBalance
    seller {
      id
      person {
        name
        cpf
      }
      company {
        businessName
        cnpj
      }
    }
  }
  walletTransaction {
    id
    status
  }
`;

const LIST_WITHDRAWS_QUERY = gql`
  query ListWithdraws($filter: FilterWithdrawsParams) {
    listWithdraws(filters: $filter, pagination: { sortingOrder: DESC, orderBy: "inserted_at" }) {
      ${WITHDRAWALS_FIELDS}
    }
  }
`;

const GET_WITHDRAW_QUERY = gql`
  query GetWithdraw($id: UUID4!) {
    getWithdraw(id: $id) {
      ${WITHDRAWALS_FIELDS}
    }
  }
`;

export { LIST_WITHDRAWS_QUERY, GET_WITHDRAW_QUERY };
