import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { mergeMap, Observable, of, retry, take, throwError } from 'rxjs';

import { environment } from '@gen/environments';
import { AddressService } from '../address/address.service';
import { ResponseModel } from './../../models/response/response.model';
import { CreditCardModel } from '../../models/credit-card/credit-card.model';
import { CreditCardObjModel } from '../../models/credit-card/credit-card-obj.model';
import { TOKENIZE_CARD_NUMBER } from '../../graphql/mutations/credit-card.mutations';
import { ResponseCreditCardModel } from '../../models/credit-card/response-credit-card.model';

@Injectable()
export class CreditCardService {
  public marketplaceId: string = environment.marketplaceId;

  constructor(
    private readonly apollo: Apollo,
    private $address: AddressService,
    private readonly afStore: AngularFirestore,
    private readonly afFunc: AngularFireFunctions
  ) {}

  public tokenizeCreditCard(cardNumber: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: TOKENIZE_CARD_NUMBER,
      variables: { cardNumber },
    });
  }

  public tokenizeFirebase(cardNumber: string): Observable<any> {
    return this.tokenizeCreditCardFirebase({ cardNumber }).pipe(
      mergeMap((val) => {
        if (!val.body?.number_token) {
          return throwError(() => new Error('Erro ao tentar tokenizar o cartão.'));
        }

        return of(val);
      }),
      retry(3)
    );
  }

  public tokenizeCreditCardFirebase(data: any): Observable<any> {
    return this.afFunc.httpsCallable('createCreditCardToken')(data);
  }

  public getPublicCreditCard(creditCardId: string): Observable<CreditCardModel> | undefined {
    return this.afStore.doc(`creditCards/${creditCardId}`).valueChanges() as Observable<CreditCardModel>;
  }

  public getCreditCardObj(cardNumber: string): CreditCardObjModel {
    let obj: CreditCardObjModel = {
      creditCardMask: '0000 0000 0000 0000',
      cvvMask: '000',
      pathImage: '',
      brand: '',
      last4: cardNumber.substring(0, 4),
    };

    if (cardNumber.substring(0, 1) === '4') {
      obj.pathImage = '../../../assets/images/logos_visa.svg';
      obj.brand = 'VISA';
    } else if (parseInt(cardNumber.substring(0, 2), 10) >= 51 && parseInt(cardNumber.substring(0, 2), 10) <= 55) {
      obj.pathImage = '../../../assets/images/logos_mastercard.svg';
      obj.brand = 'MASTERCARD';
    } else if (
      cardNumber.substring(0, 2) === '36' ||
      cardNumber.substring(0, 2) === '38' ||
      cardNumber.substring(0, 4) === '2149' ||
      cardNumber.substring(0, 4) === '2014' ||
      (parseInt(cardNumber.substring(0, 3), 10) >= 300 && parseInt(cardNumber.substring(0, 3), 10) <= 305)
    ) {
      obj.creditCardMask = '0000 0000 0000 00';
      obj.pathImage = '../../../assets/images/logos_dinners-club.svg';
      obj.brand = 'DINNERS CLUB';
    } else if (cardNumber.substring(0, 4) === '6011' || cardNumber.substring(0, 2) === '65') {
      obj.creditCardMask = '0000 0000 0000 00';
      obj.pathImage = '../../../assets/images/logos_discover-club.svg';
      obj.brand = 'DISCOVER';
    } else if (
      cardNumber.substring(0, 2) === '35' ||
      cardNumber.substring(0, 4) === '1800' ||
      cardNumber.substring(0, 4) === '2131'
    ) {
      obj.brand = 'JCB';
      obj.pathImage = '../../../assets/images/logos_jcb.svg';
    } else if (cardNumber.substring(0, 2) === '34' || cardNumber.substring(0, 2) === '37') {
      obj.cvvMask = '0000';
      obj.brand = 'AMERICAN EXPRESS';
      obj.pathImage = '../../../assets/images/logos_amex.svg';
    }

    return obj;
  }

  public getCreditCards(customerId: string): Observable<CreditCardModel[]> | undefined {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/customers/${customerId}/creditCards`)
      .valueChanges() as Observable<CreditCardModel[]>;
  }

  public getCreditCard(customerId: string, creditCardId: string): Observable<CreditCardModel> | undefined {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/customers/${customerId}/creditCards`)
      .doc(creditCardId)
      .valueChanges() as Observable<CreditCardModel>;
  }

  public createCreditCardValidation(params: {
    securityCode: string;
    creditCardId: string;
  }): Observable<ResponseModel<any>> {
    return this.afFunc.httpsCallable('createCreditCardValidation')(params);
  }

  public confirmCreditCardValidation(params: {
    amountCents: number;
    creditCardId: string;
  }): Observable<ResponseModel<any>> {
    return this.afFunc.httpsCallable('confirmCreditCardValidation')(params);
  }

  public createCreditCard(params: any): Observable<ResponseModel<CreditCardModel>> {
    return this.afFunc.httpsCallable('publicCreateCreditCard')(params);
  }

  public deleteCreditCard(creditCardId: string): Observable<ResponseModel<any>> {
    return this.afFunc.httpsCallable('deleteCreditCard')(creditCardId);
  }

  public editCreditCard(
    customerId: string,
    creditCardId: string,
    data: { mainCard: boolean; cardName: string }
  ): Promise<void> {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/customers/${customerId}/creditCards`)
      .doc(creditCardId)
      .set(data, { merge: true });
  }

  public setCreditCardPayload(
    token: string,
    formAddress: FormGroup,
    creditCardForm: FormGroup,
    customerForm: FormGroup
  ): Partial<CreditCardModel> {
    const payLoad = {
      token,
      holderName: customerForm.get('name').value,
      cardNumber: creditCardForm.get('creditCardNumber').value,
      expirationMonth: creditCardForm.get('expiration').value.slice(0, 2),
      expirationYear: creditCardForm.get('expiration').value.slice(2, 4),
      securityCode: creditCardForm.get('securityCode').value,
      billingAddress: this.$address.getAddressPayload(formAddress),
      customerId: customerForm.get('id').value,
      marketplaceId: environment.marketplaceId,
    };

    return payLoad;
  }

  public getAllCreditCards(id: string): Observable<CreditCardModel[]> | undefined {
    return this.afStore.collection(`parkingUsers/${id}/creditCards`).valueChanges() as Observable<CreditCardModel[]>;
  }

  public saveCard(creditCard: ResponseCreditCardModel): void {
    const userId = localStorage.getItem('parkingUser');
    if (userId) {
      const payload = this.makeCreditCardPayload(creditCard);
      this.afStore.collection(`parkingUsers/${userId}/creditCards`).doc(payload.id).set(payload, { merge: true });
    }
  }

  public favoriteCard(creditCard: CreditCardModel): void {
    const userId = localStorage.getItem('parkingUser');
    if (userId) {
      this.afStore
        .collection(`parkingUsers/${userId}/creditCards`, (ref) => ref.where('isFavorite', '==', true))
        .valueChanges()
        .pipe(take(1))
        .subscribe((res: any[]) => {
          if (res.length > 0) {
            this.afStore.doc(`parkingUsers/${userId}/creditCards/${res[0].id}`).update({ isFavorite: false });
          }

          this.afStore.doc(`parkingUsers/${userId}/creditCards/${creditCard.id}`).update({ isFavorite: true });
        });
    }
  }

  public deleteCard(id: string): Promise<void> {
    const userId = localStorage.getItem('parkingUser');
    return this.afStore.doc(`parkingUsers/${userId}/creditCards/${id}`).delete();
  }

  public makeCreditCardPayload(creditCard: ResponseCreditCardModel): Partial<CreditCardModel> {
    const creditCardPayload: Partial<CreditCardModel> = {
      id: creditCard.id,
      brand: creditCard.brand,
      cardNumber: creditCard.numero,
      holderName: creditCard.portador,
      last4: creditCard.numero.slice(-4),
      expirationMonth: creditCard.validade.slice(0, 2),
      expirationYear: creditCard.validade.slice(2),
      customer: {
        name: creditCard.consumidor.nome || '',
        cpf: creditCard.consumidor.cpf,
        birthDate: creditCard.consumidor.dataNascimento || '',
        email: creditCard.consumidor.email,
        phone: creditCard.consumidor.telefone,
      },
      billingAddress: {
        line1: creditCard.consumidor.endereco.logradouro,
        line2: creditCard.consumidor.endereco.numero as string,
        line3: creditCard.consumidor.endereco.complemento || '',
        postalCode: creditCard.consumidor.endereco.cep,
        neighborhood: creditCard.consumidor.endereco.bairro,
        city: creditCard.consumidor.endereco.cidade,
        state: creditCard.consumidor.endereco.uf,
      },
    };

    return creditCardPayload;
  }
}
