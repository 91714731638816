import { NzMessageService } from 'ng-zorro-antd/message';
import { PixKeyParams, PixType } from './../../../models/pix/pix.model';
import { PixService } from './../../../services/pix/pix.service';
import { KeyTypesPix } from './../../../enums/pix-key-types';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Component, Input, OnInit } from '@angular/core';
import { ModalCreatePixKeySuccessComponent } from '../../modals/modal-create-pix-key-success/modal-create-pix-key-success.component';
import { StateManagementService } from '../../../state-management/state-management.service';
import { SellerModel } from '../../../models/sellers/sellers.model';

@Component({
  selector: 'app-drawer-register-pix-key',
  templateUrl: './drawer-register-pix-key.component.html',
  styleUrls: ['./drawer-register-pix-key.component.scss'],
})
export class DrawerRegisterPixKeyComponent implements OnInit {
  @Input() public type: PixType;
  public seller: SellerModel;
  public keyType: string;
  public key: string;
  public loadingButton: boolean = false;

  constructor(
    public drawerRef: NzDrawerRef,
    private $modal: NzModalService,
    private $notification: StateManagementService,
    private $pix: PixService,
    private $message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.getEnumKeyType();
    this.getSeller();
  }

  private openModal(): void {
    this.$modal.create({
      nzContent: ModalCreatePixKeySuccessComponent,
      nzWidth: '50%',
      nzCentered: true,
      nzZIndex: 999,
      nzFooter: null,
      nzComponentParams: {
        key: this.key,
      },
    });
  }

  private getEnumKeyType(): void {
    this.keyType = KeyTypesPix[this.type];
  }

  private getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.seller = res;
      }
    });
  }

  public processKey(): void {
    this.loadingButton = true;
    const params: PixKeyParams = {
      key: '',
      keyType: this.type,
      sellerId: this.seller.id,
    };
    this.$pix.createPixKey(params, this.seller.id).subscribe({
      next: (res) => {
        if (res) {
          this.key = res.data.createPixKey.key;
        }
        this.openModal();
        this.drawerRef.close();
        this.loadingButton = false;
      },
      error: (err) => {
        this.$message.error('Não foi possível criar esta chave. Tente novamente.');
        this.drawerRef.close();
        this.loadingButton = false;
        throw new Error(err);
      },
    });
  }
}
