import { TableSettingsModel } from '../../../models/table/table-settings.model';
import { TableHeaderModel } from '../../../models/table/table-header.model';
import { TableFooterModel } from '../../../models/table/table-footer.model';
import { Component, Input, OnInit } from '@angular/core';
import { PaymentInfo } from '../../../models/payments/payment-info.model';
import { ItemModel } from '../../../models/items/item.model';
import { PriceModel } from '../../../models/plans/price.model';
import { PlanDetailModel } from '../../../models/plans/plan-detail';
import { FooterType } from '../../../types/footer-type';
import { StateManagementService } from '../../../state-management/state-management.service';
import { InternalService } from '../../../services/internal/internal.service';
import { environment } from '@gen/environments';
import { PlansModel } from '../../../models/plans/plans.model';

@Component({
  selector: 'app-drawer-details',
  templateUrl: './drawer-details.component.html',
  styleUrls: ['./drawer-details.component.scss'],
})
export class DrawerDetailsComponent implements OnInit {
  @Input() paymentInfo: PaymentInfo;
  @Input() priceId: PaymentInfo;

  public totalAmount: number = 0;
  public products: ItemModel[] = [];
  public title: string = '';
  public productId: string = '';

  public selectedPrice: PriceModel;
  public planDetail: PlanDetailModel;

  public listOfData: Array<ItemModel> = [];
  public footerData: TableFooterModel;
  public footerType: FooterType = 'editableTableFooter';
  public listOfHeadersColumn: TableHeaderModel[] = [
    { name: 'Descrição' },
    { name: 'QTD' },
    { name: 'Preço Unit' },
    { name: 'Valor' },
  ];
  public settingValue: TableSettingsModel = {
    pagination: false,
    footer: true,
    expandable: false,
    checkbox: false,
    footerName: this.footerType,
  };

  constructor(private $notification: StateManagementService, private $internal: InternalService) {}

  public ngOnInit(): void {
    switch (this?.paymentInfo?.typePage) {
      case 'ORDER':
        this.setOrderDetail();
        break;
      case 'ITEM':
        this.setItemDetail();
        break;
      case 'PLAN':
        this.setPlanDetail();
        break;
      case 'SUBSCRIPTION':
        break;
    }
  }

  public copyContent(): void {
    /* const url = getTemporaryRoute(); */
    const url = environment.appDomain;

    if (this.paymentInfo.typePage === 'ORDER') {
      this.$internal.copyContent(`${url}/orders/${this.productId}`);
    }
    if (this.paymentInfo.typePage === 'PLAN') {
      this.$internal.copyContent(`${url}/plans/${this.productId}`);
    }
    if (this.paymentInfo.typePage === 'SUBSCRIPTION') {
      this.$internal.copyContent(`${url}/subscriptions/${this.paymentInfo.subscription?.id}`);
    }
  }

  public openPayment(): void {
    /* const url = getTemporaryRoute(); */
    const url = environment.appDomain;

    if (this.paymentInfo.typePage === 'ORDER') {
      window.open(`${url}/orders/${this.productId}`, '_blank');
    }

    if (this.paymentInfo.typePage === 'PLAN') {
      window.open(`${url}/plans/${this.productId}`, '_blank');
    }

    if (this.paymentInfo.typePage === 'SUBSCRIPTION') {
      window.open(`${url}/subscriptions/${this.paymentInfo.subscription?.id}`, '_blank');
    }
  }

  public getData(): void {
    this.listOfData = this.products;
    this.footerData = new TableFooterModel();
    this.footerData.totalAmount = this.totalAmount;
  }

  public setPlanDetail(): void {
    this.$notification.planDetails.subscribe((res) => {
      this.planDetail = res;
      this.productId = this.paymentInfo?.plans?.id;
    });

    this.selectedPriceAmount(this.paymentInfo.plans);
  }

  public getTotalAmount(array: Array<ItemModel>): number {
    return array?.reduce((acc, item) => (acc += item.unitPriceCents * item.quantity), 0);
  }

  public selectedPriceAmount(plan: PlansModel): Object {
    this.selectedPrice = plan?.prices?.filter((plan) => plan.id === this.priceId)[0];

    return this.selectedPrice;
  }

  public setItemDetail() {
    const product = {
      unitPriceCents: this.paymentInfo.item.amountCents,
      quantity: this.paymentInfo.item.quantity,
      description: this.paymentInfo.item.name,
      itemId: this.paymentInfo.item.id,
    };

    this.totalAmount = this.getTotalAmount([product]);
    this.products = [product];
    this.productId = product.itemId;
    this.title = 'Item';
    this.getData();
  }

  public setOrderDetail() {
    this.totalAmount = this.getTotalAmount(this.paymentInfo.order.items);
    this.products = this.paymentInfo.order.items;
    this.productId = this.paymentInfo.paymentLink?.id || this.paymentInfo.order.id;
    this.title = this.paymentInfo.order.haveLink ? 'Link de pagamento' : 'Fatura simples';
    this.getData();
  }
}
