export class CategoryModel {
  public id?: string;
  public name?: string;
  public sellerId?: string;
  public imageUrl?: string;
  public itemsId?: string[];
  public selected?: boolean;
  public insertedAt?: string;
  public updatedAt?: string;
  public file?: any;
}
