import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { ResponseModel } from '../../models/response/response.model';

@Injectable()
export class CampaignService {
  constructor(private $functions: AngularFireFunctions) {}

  public getCampaignFilter(data: any): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('getMallCampaign')(data);
  }
}
