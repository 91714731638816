import { firstValueFrom } from 'rxjs';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Message } from '../../../utils/message';
import { CpfValidator } from '../../../validators/cpfValidator';
import { CnpjValidator } from '../../../validators/cnpjValidator';
import { ContactModel } from '../../../models/contact/contact.model';
import { FullNameValidator } from '../../../validators/fullNameValidator';
import { IbgeStateModel } from '../../../models/address/ibge-state.model';
import { AddressService } from '../../../services/address/address.service';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { UserClaimsModel } from '../../../models/user-claims/user-claims.model';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-modal-create-contact',
  templateUrl: './modal-create-contact.component.html',
  styleUrls: ['./modal-create-contact.component.scss'],
})
export class ModalCreateContactComponent implements OnInit {
  @Input() value: ContactModel;

  public user: UserClaimsModel = new UserClaimsModel();
  public formDescription: FormGroup;
  public formDescriptionBusiness: FormGroup;
  public formDescriptionInfoPersonRepresenting: FormGroup;
  public optionStates: IbgeStateModel[] = [];
  public loading: boolean = false;
  public personalAddressResult: boolean = false;
  public businessAddressResult: boolean = false;
  public personalBusinessAddressResult: boolean = false;
  public isShowPersonalAddressInfo: boolean = false;
  public isShowBusinessAddressInfo: boolean = false;
  public isShowPersonalBusinessAddressInfo: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private $address: AddressService,
    private $contact: ContactsService,
    private readonly drawerRef: NzDrawerRef,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.createFormBusiness();
    this.createFormInfoPersonRepresenting();
    this.getStates();
    this.getContactsInfoById();
    this.getUser();
  }

  private createForm(): void {
    this.formDescription = this.fb.group({
      kindOfPerson: new FormControl('PERSONAL', [Validators.required]),
      personalDocument: new FormControl('', [Validators.required, CpfValidator.isValid()]),
      name: new FormControl('', [Validators.required, FullNameValidator.isValid()]),
      personalPhone: new FormControl('', [Validators.required]),
      personalEmail: new FormControl('', [Validators.required, Validators.email]),
      zipCodePersonal: new FormControl('', [Validators.required]),
      cityPersonal: new FormControl('', [Validators.required]),
      statePersonal: new FormControl('', [Validators.required]),
      streetPersonal: new FormControl('', [Validators.required]),
      neighborhoodPersonal: new FormControl('', [Validators.required]),
      numberPersonal: new FormControl(null, [Validators.required]),
      complementPersonal: new FormControl(),
    });
  }

  private createFormBusiness(): void {
    this.formDescriptionBusiness = this.fb.group({
      businessDocument: new FormControl('', [Validators.required, CnpjValidator.isValid()]),
      corporateName: new FormControl('', [Validators.required, FullNameValidator.isValid()]),
      businessPhone: new FormControl('', [Validators.required]),
      businessEmail: new FormControl('', [Validators.email, Validators.required]),
      zipCodeBusiness: new FormControl('', [Validators.required]),
      cityBusiness: new FormControl('', [Validators.required]),
      stateBusiness: new FormControl('', [Validators.required]),
      streetBusiness: new FormControl('', [Validators.required]),
      neighborhoodBusiness: new FormControl('', [Validators.required]),
      numberBusiness: new FormControl(null, [Validators.required]),
      complementBusiness: new FormControl(),
    });
  }

  private createFormInfoPersonRepresenting(): void {
    this.formDescriptionInfoPersonRepresenting = this.fb.group({
      personalDocument: new FormControl('', [Validators.required, CpfValidator.isValid()]),
      name: new FormControl('', [Validators.required, FullNameValidator.isValid()]),
      zipCodePersonalBusiness: new FormControl('', [Validators.required]),
      cityPersonalBusiness: new FormControl('', [Validators.required]),
      statePersonalBusiness: new FormControl('', [Validators.required]),
      streetPersonalBusiness: new FormControl('', [Validators.required]),
      neighborhoodPersonalBusiness: new FormControl('', [Validators.required]),
      numberPersonalBusiness: new FormControl(null, [Validators.required]),
      complementPersonalBusiness: new FormControl(),
    });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.user = res;
      }
    });
  }

  public async setHandleSubmit(): Promise<void> {
    try {
      let contacts: ContactModel[] | undefined = [];
      let errorMessage = '';

      if (this.formDescription.get('kindOfPerson').value === 'BUSINESS') {
        const cnpj = this.formDescriptionBusiness.get('businessDocument').value || '';
        if (this.value?.id) {
          if (this.value?.cnpj !== cnpj) {
            contacts = await firstValueFrom(
              this.$contact.getContactByCNPJ(this.user.marketplaceId, this.user.sellerId, cnpj)
            );
            errorMessage = Message.CNPJ_EXISTS;
          }
        } else {
          contacts = await firstValueFrom(
            this.$contact.getContactByCNPJ(this.user.marketplaceId, this.user.sellerId, cnpj)
          );
          errorMessage = Message.CNPJ_EXISTS;
        }
      } else {
        const cpf = this.formDescription.get('personalDocument').value;

        if (this.value?.id) {
          if (this.value?.cpf !== cpf) {
            contacts = await firstValueFrom(this.$contact.getContactByCPF(this.user.sellerId, cpf));
            errorMessage = Message.CPF_EXISTS;
          }
        } else {
          contacts = await firstValueFrom(this.$contact.getContactByCPF(this.user.sellerId, cpf));
          errorMessage = Message.CPF_EXISTS;
        }
      }

      if (contacts && contacts?.length > 0) {
        this.$message.create('error', errorMessage);
        return;
      }

      this.drawerRef.close({
        personalForm: this.formDescription.value,
        businessForm: { ...this.formDescriptionBusiness.value, ...this.formDescriptionInfoPersonRepresenting.value },
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  public getZipCode(value: string, type?: string): void {
    if (value.length === 8) {
      this.loading = true;

      this.$address.getCep(value).subscribe({
        next: (res: any) => {
          if (res) {
            if (this.formDescription.get('kindOfPerson').value === 'PERSONAL') {
              this.isShowPersonalAddressInfo = false;
              this.formDescription.patchValue({
                cityPersonal: res.localidade,
                statePersonal: res.uf,
                streetPersonal: res.logradouro,
                neighborhoodPersonal: res.bairro,
              });
              this.personalAddressResult = true;
            } else {
              if (type === 'BUSINESS_ADDRESS') {
                this.formDescriptionBusiness.patchValue({
                  cityBusiness: res.localidade,
                  stateBusiness: res.uf,
                  streetBusiness: res.logradouro,
                  neighborhoodBusiness: res.bairro,
                });
                this.businessAddressResult = true;
                this.isShowBusinessAddressInfo = false;
              } else {
                this.formDescriptionInfoPersonRepresenting.patchValue({
                  cityPersonalBusiness: res.localidade,
                  statePersonalBusiness: res.uf,
                  streetPersonalBusiness: res.logradouro,
                  neighborhoodPersonalBusiness: res.bairro,
                });
                this.personalBusinessAddressResult = true;
                this.isShowPersonalBusinessAddressInfo = false;
              }
            }
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$message.create('error', Message.CEP_NOT_FOUND);
          this.formDescription.get('zipCode').setErrors({ pattern: true });
          throw new Error(error);
        },
      });
    }
  }

  public getContactsInfoById(): void {
    if (this.value) {
      if (this.value?.cnpj) {
        this.businessAddressResult = true;
        this.personalBusinessAddressResult = true;
        this.formDescriptionBusiness.patchValue({
          businessDocument: this.value?.cnpj,
          businessPhone: this.value?.phone,
          businessEmail: this.value?.email,
        });
        this.formDescription.patchValue({
          personalDocument: this.value?.cpf,
          name: this.value?.name,
          zipCodePersonalBusiness: this.value?.address?.postalCode,
          cityPersonalBusiness: this.value?.address?.city,
          statePersonalBusiness: this.value?.address?.state,
          streetPersonalBusiness: this.value?.address?.line1,
          neighborhoodPersonalBusiness: this.value?.address?.neighborhood,
          numberPersonalBusiness: this.value?.address?.line2,
          complementPersonalBusiness: this.value?.address?.line3,
        });
      } else {
        this.personalAddressResult = true;
        this.formDescription.patchValue({
          personalDocument: this.value?.cpf,
          name: this.value?.name,
          personalPhone: this.value?.phone,
          personalEmail: this.value?.email,
          zipCodePersonal: this.value?.address?.postalCode,
          cityPersonal: this.value?.address?.city,
          statePersonal: this.value?.address?.state,
          streetPersonal: this.value?.address?.line1,
          neighborhoodPersonal: this.value?.address?.neighborhood,
          numberPersonal: this.value?.address?.line2,
          complementPersonal: this.value?.address?.line3,
        });
      }
    }
  }

  public showAddressInfo(type?): void {
    if (this.formDescription.get('kindOfPerson').value === 'BUSINESS') {
      if (type === 'BUSINESS_ADDRESS') {
        this.isShowBusinessAddressInfo = true;
      }

      if (type === 'PERSONAL_BUSINESS_ADDRESS') {
        this.isShowPersonalBusinessAddressInfo = true;
      }
    } else {
      this.isShowPersonalAddressInfo = true;
    }
  }

  public getStates(): void {
    this.$address.getStates().subscribe({
      next: (res) => {
        this.optionStates = res;
      },
      error: (error) => {
        this.$message.create('error', 'Estado não encontrado');
        throw new Error(error);
      },
    });
  }
}
