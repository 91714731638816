import { AddressModel } from "../address/address.model";
import { OrderModel } from "../orders/order.model";
import { PaymentModel } from "../payment/payment.model";
import { BasketModel } from "./basket.model";

export class CartModel {
 public id: string;
 public status: 'ACTIVE' | 'INACTIVE' | 'PAID';
 public orders: Array<OrderModel>;
 public payments: Array<PaymentModel>;
 public baskets: Array<BasketModel>;
 public pointId: string;
 public customerId: string;
 public isPaid: boolean;
 public coupon?: string;
 public insertedAt: string;
 public totalValue: number;
}
