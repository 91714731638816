import { NzMessageService } from 'ng-zorro-antd/message';
import { mergeMap, of, Subject, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { trimData } from '../../../utils/utils';
import { Message } from '../../../utils/message';
import { CnpjValidator } from '../../../validators/cnpjValidator';
import { UserClaimsModel } from '../../../models/user-claims/user-claims.model';
import { OnboardingService } from '../../../services/onboarding/onboarding.service';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {
  @Input() onSubmit: Subject<boolean>;

  public user!: UserClaimsModel;
  public formGroup!: FormGroup;
  private subscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    private $message: NzMessageService,
    private $onboarding: OnboardingService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getUser();
    this.getDashboardActivationCollection();

    this.formGroup.valueChanges.subscribe((data) => {
      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });

    this.subscription = this.onSubmit.subscribe((v) => {
      if (v) {
        if (this.formGroup.valid) {
          this.saveData();
        } else {
          Object.values(this.formGroup.controls).forEach((control) => {
            if (control.invalid) {
              control.markAsDirty();
              control.updateValueAndValidity({ onlySelf: true });
            }
          });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public changeType(): void {
    this.$onboarding.changeTypeSave(this.user.user_id, 'autonomous');
  }

  public goToOutside(): void {
    this.formGroup.get('terms').setValue(!this.formGroup.get('terms').value);
    window.open('https://gen.com.br/termos-e-condicoes', '_blank');
  }

  private async saveData(): Promise<void> {
    this.$notification.setOnboardingBtnLoading(true);

    this.$onboarding
      .createSeller('companyDetails', {
        done: true,
        data: trimData(this.formGroup.value),
      })
      .pipe(
        mergeMap((res) => {
          if (res.data.finishSellerOnboarding) {
            this.$onboarding.updateDashboardFirebase('stage', 1);
            this.$notification.setSeller(res.data.finishSellerOnboarding);
            return this.$onboarding.createContactOnHubspot(res.data.finishSellerOnboarding);
          } else {
            this.$onboarding.updateDashboardFirebase('resetCompanyDetails');
            this.$message.error(Message.ERROR_REGISTER_ACCOUNT);
            return of(undefined);
          }
        })
      )
      .subscribe({
        next: async (response) => {
          if (response?.status === 201) {
            await this.$onboarding.saveCompanyDetails(this.user);
          }

          this.$notification.setOnboardingBtnLoading(false);
        },
        error: (error) => {
          this.$notification.setOnboardingBtnLoading(false);
          this.$message.error(error?.message || Message.ERROR_REGISTER_ACCOUNT);
          throw new Error(error);
        },
      });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      cnpj: new FormControl('', [Validators.required, CnpjValidator.isValid()]),
      terms: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection) {
        const companyDetails = collection.companyDetails?.data;

        // To know if there is data on company collection.
        if (companyDetails?.cnpj) {
          this.formGroup.get('cnpj').setValue(companyDetails.cnpj);
        }
      }

      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });
  }
}
