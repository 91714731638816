import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { PlanCreateComponent } from './../plan-create/plan-create.component';
import { PlansService } from 'libs/shared/src/lib/services/plans/plans.service';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { environment } from '@gen/environments';
import { PlansModel } from 'libs/shared/src/lib/models/plans/plans.model';
import { Message } from 'libs/shared/src/lib/utils/message';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss'],
})
export class PlanDetailsComponent implements OnInit {
  @ViewChild('titleDrawer', { static: false }) titleDrawer: TemplateRef<any>;
  @Input() param: { id: string; sellerId: string; marketplaceId: string; userId: string };

  public data: PlansModel;
  public url: string;
  public title: string = '';
  public loading: boolean = false;
  public schedulePlans: Array<any> = [];

  constructor(
    private $plans: PlansService,
    private $internal: InternalService,
    public readonly $drawerRef: NzDrawerRef,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.setData();
  }

  public setData(): void {
    this.loading = true;
    this.$plans.getPlanDetail(this.param?.marketplaceId, this.param?.sellerId, this.param.id).subscribe((plan) => {
      if (plan) {
        this.data = plan;

        /* const url = getTemporaryRoute(); */
        const url = environment.appDomain;
        this.url = `${url}/plans/${this.param.id}`;

        if (plan.prices) {
          for (let p of plan.prices) {
            this.schedulePlans.push({
              order: this.getOrder(p.schedulePlans),
              amountCents: this.$internal.formatMoney(p.amountCents),
              schedulePlans: this.$internal.getSchedule(p.schedulePlans),
            });
          }

          this.schedulePlans.sort((a: any, b: any) => a.order - b.order);
          const filterArray = this.schedulePlans.reduce((acc: any, curr: any) => {
            if (acc.find((plan: any) => plan.schedulePlans === curr.schedulePlans)) return acc;
            return [...acc, curr];
          }, []);

          this.schedulePlans = filterArray;
        }

        this.loading = false;
      }
    });
  }

  public copyContent(): void {
    this.$internal.copyContent(this.url);
  }

  public openPayment(): void {
    window.open(this.url, '_blank');
  }

  public getOrder(price: any): number {
    switch (price) {
      case 'daily':
        return 1;
      case 'weekly':
        return 2;
      case 'biweekly':
        return 3;
      case 'monthly':
        return 4;
      case 'annually':
        return 5;
      default:
        return 0;
    }
  }

  public delete(): void {
    this.loading = true;

    this.$plans.deletePlan(this.data?.id).subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.$message.success(Message.PLAN_REMOVED_SUCCESS);
        } else {
          this.$message.error(Message.ERROR_DELETED_PLAN);
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error(Message.ERROR_DELETED_PLAN);
        throw new Error(error);
      },
    });
  }

  public inactiveOrActive(): void {
    this.loading = true;

    if (this.data) {
      if (this.data.visible === false) {
        this.data.visible = true;
      } else {
        this.data.visible = false;
      }

      this.$plans.updatePlan(this.data).subscribe({
        next: (result) => {
          if (result.status === 200) {
            this.$message.success(this.data.visible ? Message.PLAN_ACTIVE : Message.PLAN_INACTIVE);
          } else {
            this.$message.error(this.data.visible ? Message.ERROR_ACTIVE_PLAN : Message.ERROR_INACTIVE_PLAN);
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$message.error(this.data.visible ? Message.ERROR_ACTIVE_PLAN : Message.ERROR_INACTIVE_PLAN);
          throw new Error(error);
        },
      });
    }
  }

  public editPlan(): void {
    this.title = 'Editar plano';
    
    this.$drawer
      .create({
        nzTitle: this.titleDrawer,
        nzContent: PlanCreateComponent,
        nzContentParams: { data: this.data },
        nzBodyStyle: { position: 'absolute', top: 0, right: 0, height: '100vh', width: '100%' },
        nzWidth: '500px',
        nzFooter: null,
        nzClosable: false,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.updatePlan(res);
        }
      });
  }

  public updatePlan(res: PlansModel): void {
    this.loading = true;

    this.$plans.updatePlan(res).subscribe({
      next: (result) => {
        if (result.status === 200) {
          if (result.body.id && res.file) {
            this.$plans.updatePlanImageUrl(result.body, res, this.param.userId);
          }

          this.$message.success(Message.UPDATE_PLAN);
        } else {
          this.$message.error(Message.ERROR_UPDATE_PLAN);
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error(Message.ERROR_UPDATE_PLAN);
        throw new Error(error);
      },
    });
  }
}
