import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

import { SharedMethodsService } from '../../../services/internal/shared-methods.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { ItemsModel } from '../../../models/items/items.model';
import { SelectModel } from '../../../models/select/select.model';
import { TypeModel } from '../../../models/option/option.model';
import { ItemImagesModel } from '../../../models/items/item-images.model';
import { CategoryService } from '../../../services/item/category/category.service';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { ModalAddVariationProductComponent } from '../../modals/modal-add-variation-product/modal-add-variation-product.component';
import { UploadImageComponent } from '../../modals/upload-image/upload-image.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-drawer-create-product',
  templateUrl: './drawer-create-product.component.html',
  styleUrls: ['./drawer-create-product.component.scss'],
})
export class DrawerCreateProductComponent implements OnInit {
  @Input() public data: ItemsModel = new ItemsModel();

  public productForm!: FormGroup;
  public images: ItemImagesModel[] = [];
  public optionsCategory: Array<SelectModel> = [];
  public imagesToSend: File[] = [];
  public imageCount: number = 0;
  public needUpdateOptions: boolean = true;
  public sellerId: string = '';
  public title: string = 'Criar item';

  constructor(
    private $shared: SharedMethodsService,
    private readonly fb: FormBuilder,
    private $category: CategoryService,
    public readonly $drawerRef: NzDrawerRef,
    private readonly $drawer: NzDrawerService,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService
  ) {
    this.createForm();
  }

  public ngOnInit(): void {
    this.getNotification();

    if (this.data?.id) {
      setTimeout(() => {
        this.updateForm();
      }, 0);
    }
  }

  public getNotification(): void {
    this.$notification.users.subscribe(async (res) => {
      if (res?.sellerId) {
        this.sellerId = res.sellerId;

        this.setCategories();
        this.setImagesArray();
      }
    });
  }

  private updateForm(): void {
    this.title = 'Editar item';

    this.productForm.patchValue({
      id: this.data?.id,
      type: this.data?.type,
      name: this.data?.name,
      description: this.data?.description,
      active: this.data?.active ?? true,
      amountCents: this.data?.amountCents / 100 || 0,
      itemPrices: this.data?.itemPrices || [],
      itemOptions: this.data?.itemOptions || [],
      category: this.data?.itemCategory || {},
    });
  }

  private createForm(): void {
    this.productForm = this.fb.group({
      id: new FormControl(''),
      type: new FormControl('PRODUCT'),
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      category: new FormControl({}),
      active: new FormControl(true),
      amountCents: new FormControl(0, [Validators.required, this.minValueValidator(10)]),
      itemPrices: new FormControl([]),
      itemOptions: new FormControl([]),
    });
  }

  public setCategories(): void {
    this.$category.getCategoryList(this.sellerId)?.subscribe({
      next: (res) => {
        if (res?.data?.listItemCategories) {
          const data = [...res.data.listItemCategories];

          const categories = this.$shared.sortDataByName(data);

          this.optionsCategory = categories.map((category) => {
            return { value: category, label: category.name };
          });
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public setImagesArray(): void {
    for (let index = 0; index < 6; index++) {
      const imagesObj = {
        label: '',
        file: undefined,
        url: this.data?.imagesUrls?.[index] || '',
      };

      this.images.push(imagesObj);
    }
  }

  public openNewVariation(): void {
    const payload = {
      ...this.productForm.value,
      amountCents: this.productForm.value?.amountCents * 100,
      itemCategoryId: this.productForm.value?.category?.id,
    };

    this.$drawer
      .create({
        nzBodyStyle: { display: 'flex', margin: 'auto', width: '100%' },
        nzWidth: '700px',
        nzContentParams: {
          data: this.data || payload,
        },
        nzContent: ModalAddVariationProductComponent,
        nzClosable: false,
      })
      .afterClose.subscribe((res) => {
        if (res && res.options[0].name !== '') {
          this.needUpdateOptions = false;

          this.productForm.patchValue({
            itemPrices: res.itemPrices,
            itemOptions: res.options,
          });
        }
      });
  }

  public setHandleSubmit(): void {
    const form = this.productForm.value;

    const payload = {
      id: form.id,
      name: form.name,
      type: form.type,
      sellerId: this.sellerId,
      description: form.description,
      active: form.active,
      amountCents: form.amountCents * 100,
      itemCategoryId: form.category?.id || '',
      itemPrices: form.itemPrices,
      itemOptionsIds: form.itemOptions,
    };

    if (this.needUpdateOptions) {
      payload.itemPrices = form.itemPrices?.map((price: any) => ({
        amountCents: price.amountCents,
        quantity: price.quantity || 0,
        itemTypesIds: price.itemTypes.map((type: TypeModel) => type.id),
      }));
      payload.itemOptionsIds = form.itemOptions?.map((option: any) => option.id);
    }

    this.images.forEach((image) => {
      if (image.thumbUrl) {
        this.imagesToSend.push(image.cropImage);
      }
    });

    this.$drawerRef.close({ images: this.imagesToSend, data: payload });
  }

  public newUpdate(): void {
    this.$modal
      .create({
        nzClosable: false,
        nzBodyStyle: { margin: 'auto' },
        nzComponentParams: {
          images: this.images,
        },
        nzTitle: 'Enviar Imagem',
        nzContent: UploadImageComponent,
        nzCentered: true,
        nzWidth: 'auto',
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.images = res;
          this.imageCount = this.images.filter((image) => image.cropImage).length;
        }
      });
  }

  public deleteImg(img: ItemImagesModel): void {
    const index = this.images.indexOf(img);

    this.images[index] = {
      label: img.label,
      file: undefined,
    };
  }

  private minValueValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value < minAmount;
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }

  public compareFn(o1: any, o2: any): boolean {
    if (o1 && o2) {
      if (o1.id === o2.id) {
        return true;
      }

      if (o1.name === o2.name) {
        return true;
      }

      return false;
    }
    return false;
  }
}
