import moment from 'moment-timezone';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { differenceInCalendarDays } from 'date-fns';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { ItemsModel } from 'libs/shared/src/lib/models/items/items.model';
import { SellerModel } from 'libs/shared/src/lib/models/sellers/sellers.model';
import { ContactModel } from 'libs/shared/src/lib/models/contact/contact.model';
import { ItemsService } from 'libs/shared/src/lib/services/products/items/items.service';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { ContactsService } from 'libs/shared/src/lib/services/contacts/contacts.service';
import { SharedMethodsService } from 'libs/shared/src/lib/services/internal/shared-methods.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';

@Component({
  selector: 'app-drawer-create-simple',
  templateUrl: './drawer-create-simple.component.html',
  styleUrls: ['./drawer-create-simple.component.scss'],
})
export class DrawerCreateSimpleComponent implements OnInit {
  @Input() data: any;

  public orderForm!: FormGroup;
  public listOfOption: Array<{ value: ItemsModel; label: string }> = [];
  public itemsInput: { items: Array<ItemModel>; newItem: boolean };
  public listOfInstallments: Array<{ value: any; label: string }> = [];
  public listOfContacts: Array<{ value: ContactModel; label: string }> = [];
  public methodsList: Array<{ value: string; label: string }> = [];
  public scheduleList: Array<{ value: string; label: string }> = [];
  public showInstallments: boolean = false;
  public addItemEnable: boolean = false;
  public newItem: boolean = false;
  public sellerId: string = '';
  public isOpenSelect: boolean = false;
  public title: string = 'Nova fatura';
  public loading: boolean = true;

  constructor(
    private $items: ItemsService,
    private readonly fb: FormBuilder,
    private $methods: InternalService,
    private $contacts: ContactsService,
    private $shared: SharedMethodsService,
    public readonly $drawerRef: NzDrawerRef,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getUser();
    this.setValueChanges();
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.sellerId = res.sellerId;
        this.getItems(res.sellerId);
        this.getContacts(res.sellerId);
      }
    });

    this.$notification.sellers.subscribe((seller) => {
      if (seller) {
        this.createForm(seller);
      }
    });
  }

  private setValueChanges(): void {
    this.orderForm.get('items').valueChanges.subscribe((res) => {
      if (res) {
        this.itemsInput = { items: res, newItem: this.newItem };
        this.setInstallments();
      }
    });

    this.orderForm.get('frequency').valueChanges.subscribe((res) => {
      if (res) {
        if (res === 'subscription') {
          this.methodsList = [{ label: 'Cartão', value: 'CREDIT_CARD' }];
        } else {
          this.methodsList = this.$methods.methodsList;
        }
      }
    });

    this.orderForm.get('methods').valueChanges.subscribe((res) => {
      if (res) {
        this.showInstallments = !!res.some((payment) => payment === 'CREDIT_CARD');
        this.setInstallments();
      }
    });
  }

  public createForm(seller: SellerModel): void {
    this.methodsList = this.$methods.getMethodsBySeller(seller);
    this.scheduleList = this.$methods.scheduleList;

    if (this.data?.id) {
      this.title = 'Editar fatura';
    }

    this.setInstallments();

    if (this.data?.frequency === 'subscription') {
      this.methodsList = [{ label: 'Cartão', value: 'CREDIT_CARD' }];
    }

    this.orderForm = this.fb.group({
      expirationDate: new FormControl(this.data?.expirationDate || ''),
      methods: new FormControl(this.data?.methods || null, [Validators.required]),
      frequency: new FormControl(this.data?.frequency || 'order', [Validators.required]),
      items: new FormControl(this.data?.items || [], [Validators.required]),
      startDate: new FormControl(this.data?.startDate || ''),
      endSubscription: new FormControl(this.data?.endDate ? true : false),
      endDate: new FormControl(this.data?.endDate || ''),
      schedule: new FormControl(this.data?.schedulePlans),
      contact: new FormControl(this.data?.customerData || null),
      installments: new FormControl(
        this.data?.maxInstallments === 1 ? 'Pagamento à vista' : this.data?.maxInstallments || 'Pagamento à vista'
      ),
    });

    if (this.data?.schedulePlans) {
      this.orderForm.get('frequency').setValue('subscription');
    }

    this.loading = false;
  }

  public setInstallments(): void {
    const maxInstallments: Array<any> = ['Pagamento à vista', 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    this.listOfInstallments = maxInstallments.map((installment, index: number) => {
      return {
        value: installment,
        label: index === 0 ? installment : 'Até ' + (index + 1) + 'x',
        disabled: index === 0 ? false : this.getMinInstallment(index + 1),
      };
    });
  }

  public getMinInstallment(index: number): boolean {
    let disabled = false;
    const totalCents = this.orderForm
      ?.get('items')
      ?.value?.reduce(
        (acc: number, item: any) => (acc += item.unitPriceCents || item.amountCents * item.quantity || 1),
        0
      );

    if (totalCents / index < 1000) {
      disabled = true;
    }

    if (this.orderForm?.get('methods')?.value?.filter((payment) => payment !== 'CREDIT_CARD').length > 0) {
      disabled = true;
    }

    return disabled;
  }

  public getItems(sellerId: string): void {
    this.$items.getItemList(sellerId)?.subscribe({
      next: (res) => {
        if (res?.data?.listItems) {
          const data = [...res.data.listItems];
          const items = this.$shared.sortDataByName(
            data
              .map((item) => ({ ...item, quantity: 1 }))
              .filter((item) => item.ableSmartpos || item.ableSmartpos === null)
          );

          this.listOfOption = items.map((item) => ({ value: item, label: item.name }));

          if (this.data?.items) {
            this.itemsInput = { items: this.orderForm.get('items').value, newItem: false };
          }
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public getContacts(sellerId: string): void {
    this.$contacts.getContactList(sellerId).subscribe((contacts) => {
      contacts = this.$shared.sortDataByName(contacts);

      this.listOfContacts = contacts.map((contact) => {
        return { value: contact, label: contact.name };
      });
    });
  }

  public addItem(): void {
    this.isOpenSelect = false;
    this.addItemEnable = true;
    this.newItem = true;
    this.itemsInput = { items: this.orderForm.get('items').value, newItem: true };
  }

  public disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;

  public listItems(data: Array<ItemModel>): void {
    this.orderForm.get('items').setValue(data);
  }

  public cancel(): void {
    this.addItemEnable = false;
    this.newItem = false;
    this.itemsInput = { items: this.orderForm.get('items').value, newItem: false };
  }

  public AddnewItem(data: ItemsModel): void {
    this.newItem = false;
    this.addItemEnable = false;
    this.listOfOption.push({ label: data.name, value: data });
    this.orderForm.get('items').value.push(data);
  }

  public setHandleSubmit(): void {
    if (this.orderForm.valid) {
      const items = this.orderForm.get('items').value.map((i: any) => {
        const item = new ItemModel();
        (item.id = i.id), (item.description = i.name || i.description);
        item.itemId = i.itemId;
        item.quantity = i.quantity;
        item.unitPriceCents = i.amountCents || i.unitPriceCents;
        return item;
      });

      this.orderForm.get('items').setValue(items);

      let payload: any = {
        id: this.data?.id ?? undefined,
        methods: this.orderForm.get('methods').value,
        items: this.orderForm.get('items').value,
        frequency: this.orderForm.get('frequency')?.value,
        sessionId: moment().format(),
      };

      payload.contactId = this.orderForm.get('contact')?.value?.id;

      if (this.orderForm.get('contact')?.value) {
        if (this.orderForm.get('contact')?.value.customerId) {
          payload.customerId = this.orderForm.get('contact')?.value.customerId;
        } else {
          const address = this.orderForm.get('contact')?.value.value?.address?.postalCode
            ? {
                line1: this.orderForm.get('contact')?.value?.address?.line1,
                line2: this.orderForm.get('contact')?.value?.address?.line2 || '0',
                line3: this.orderForm.get('contact')?.value?.address?.line3 || '',
                postalCode: this.orderForm.get('contact')?.value?.address?.postalCode.replace('-', '').replace('.', ''),
                neighborhood: this.orderForm.get('contact')?.value?.address?.neighborhood,
                city: this.orderForm.get('contact')?.value?.address?.city,
                state: this.orderForm.get('contact')?.value?.state,
                countryCode: 'BR',
              }
            : null;

          payload.customer = {
            name: this.orderForm.get('contact')?.value?.name,
            email: this.orderForm.get('contact')?.value?.email,
            cpf: this.orderForm.get('contact')?.value?.cpf || null,
            cnpj: this.orderForm.get('contact')?.value?.cnpj || null,
            phone: this.orderForm.get('contact')?.value?.phone || null,
            address,
          };
        }
      }

      if (this.orderForm.get('frequency')?.value === 'order') {
        payload = Object.assign(payload, {
          maxInstallments:
            this.orderForm.get('installments')?.value === 'Pagamento à vista'
              ? 1
              : this.orderForm.get('installments')?.value,
          expirationDate: this.orderForm.get('expirationDate').value
            ? moment(this.orderForm.get('expirationDate')?.value).format('YYYY-MM-DD')
            : '',
          type: 'INVOICE',
          channel: 'IN_STORE',
        });
      } else {
        payload = Object.assign(payload, {
          schedulePlans: this.orderForm?.get('schedule').value,
          startDate: this.orderForm.get('startDate')?.value
            ? moment(this.orderForm.get('startDate')?.value).format('YYYY-MM-DD')
            : '',
          endDate: this.orderForm.get('endDate')?.value
            ? moment(this.orderForm.get('endDate')?.value).format('YYYY-MM-DD')
            : '',
        });
      }

      this.$drawerRef.close(payload);
    } else {
      this.orderForm.markAsDirty();
    }
  }

  public compareFn(o1: any, o2: any): boolean {
    if (o1 && o2) {
      if (o1.id === o2.id) {
        return true;
      }

      if (o1.description === o2.name) {
        return true;
      }

      if (o1.name === o2.name) {
        return true;
      }

      return false;
    }
    return false;
  }
}
