import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { CategoryModel } from '../../../models/categories/categories.model';
import { NzMarks } from 'ng-zorro-antd/slider';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.scss'],
})
export class FilterMenuComponent implements OnInit {
  @Input() public categories: Array<CategoryModel>;

  public form: FormGroup;

  public orderByOptions: Array<{ label: string; value: string }> = [
    { label: 'Mais relevantes', value: 'relevantes' },
    { label: 'Distância', value: 'distancia' },
    { label: 'Melhor avaliação', value: 'avaliacao' },
    { label: 'Menor preço', value: 'menorPreco' },
    { label: 'Maior preço', value: 'maiorPreco' },
  ];

  public marks: NzMarks = {
    1: {
      style: {
        color: '#666666',
        'font-family': 'Poppins',
        'font-weight': '400',
        'font-size': '12px',
        'line-height': '18px',
      },
      label: 'R$ 1',
    },
    50000: {
      style: {
        color: '#666666',
        'font-family': 'Poppins',
        'font-weight': '400',
        'font-size': '12px',
        'line-height': '18px',
      },
      label: 'R$ 50.000',
    },
  };

  constructor(private fb: FormBuilder, public $modal: NzDrawerRef) {}

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.fb.group({
      orderBy: new FormControl(''),
      discounts: new FormControl(''),
      category: new FormControl(''),
      freeFreight: new FormControl(''),
      color: new FormControl(''),
      size: new FormControl(''),
      rangeValue: new FormControl([0, 500000]),
    });
  }

  public applyFilters(): void {}
}
