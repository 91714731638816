import { StateManagementService } from '../../../state-management/state-management.service';
import { Component, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expanded-sub-header',
  templateUrl: './expanded-sub-header.component.html',
  styleUrls: ['./expanded-sub-header.component.scss'],
})
export class ExpandedSubHeaderComponent implements OnInit {
  public form: FormGroup;

  public clickedFavorite: boolean = false;
  public selectedFilter: string = 'relevantes';
  public pointId: string = '';

  public imagesUrl: Array<string> = [
    '../../assets/images/shopping-cart.svg',
    '../../assets/images/clicked-heart.svg',
    '../../assets/images/unclicked-heart.svg',
  ];

  constructor(
    private fb: FormBuilder,
     public $modal: NzDrawerRef,
     private router: Router,
      private $notification: StateManagementService) {}

  ngOnInit(): void {
    this.getNotifications();
    this.createForm();
    this.getValuesChange();
  }


  public getNotifications(): void  {
    this.$notification.routeParams.subscribe((ids => {
      if (ids) {
        this.pointId = ids.pointId;
      }
    }));
  }

  private createForm(): void {
    this.form = this.fb.group({
      search: new FormControl(''),
    });
  }

  public getValuesChange(): void {
    this.form.get('search').valueChanges.subscribe((res) => {
      this.$notification.setSearchValue(res);
    });
  }

  public goToCart(): void {
    this.$modal.close();
    this.router.navigate(['/loggedCart/' + this.pointId + '/cart']);
  }
}
