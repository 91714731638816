import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, OnInit, Input } from '@angular/core';

import { OrdersService } from 'libs/shared/src/lib/services/orders/orders.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { ContactsService } from 'libs/shared/src/lib/services/contacts/contacts.service';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { environment } from '@gen/environments';
import { OrderModel } from 'libs/shared/src/lib/models/orders/order.model';
import { ContactModel } from 'libs/shared/src/lib/models/contact/contact.model';
import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { TableHeaderModel } from 'libs/shared/src/lib/models/table/table-header.model';
import { TableFooterModel } from 'libs/shared/src/lib/models/table/table-footer.model';
import { FooterType } from 'libs/shared/src/lib/types/footer-type';
import { TableSettingsModel } from 'libs/shared/src/lib/models/table/table-settings.model';
import { DrawerCreateSimpleComponent } from '../drawer-create-simple/drawer-create-simple.component';
import { ContactDetailsComponent } from '../../../contact-details/contact-details.component';

@Component({
  selector: 'app-drawer-simple-details',
  templateUrl: './drawer-simple-details.component.html',
  styleUrls: ['./drawer-simple-details.component.scss'],
})
export class DrawerSimpleDetailsComponent implements OnInit {
  @Input() id: string;

  public data: OrderModel = new OrderModel();
  public contact: ContactModel = new ContactModel();
  public listOfData: Array<ItemModel> = [];
  public methods: string = '';
  public dateStatus: string = '';
  public orderRoute: string = '';
  public method: string = '';
  public loading: boolean = false;

  public footerData: TableFooterModel;
  public footerType: FooterType = 'editableTableFooter';
  public listOfHeadersColumn: TableHeaderModel[] = [
    { name: 'Descrição' },
    { name: 'QTD' },
    { name: 'Preço Unit' },
    { name: 'Valor' },
  ];
  public settingValue: TableSettingsModel = {
    pagination: false,
    footer: true,
    expandable: false,
    checkbox: false,
    footerName: this.footerType,
  };

  constructor(
    private $orders: OrdersService,
    private $contacts: ContactsService,
    private $internal: InternalService,
    public readonly $drawerRef: NzDrawerRef,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.$orders.getOrderDetail(res.sellerId, this.id).subscribe((order) => {
          if (order) {
            this.$orders.getPayments(res.sellerId, this.id).subscribe((payments) => {
              this.dateStatus = this.$internal.getString(order, payments);
              this.data = order;
              this.orderRoute = `orders/${this.id}`;
              this.listOfData = order.items || [];
              this.methods = this.$internal.getMethods(this.data?.methods);
              this.method = this.$internal.getMethod(payments[0]?.method);
              this.footerData = new TableFooterModel();
              this.footerData.totalAmount = this.data.totalCents;

              this.$contacts
                .getContactDetailByCustomerId(
                  res.marketplaceId,
                  res.sellerId,
                  this.data?.customerData?.id || this.data?.customerId
                )
                ?.subscribe((contact) => {
                  this.contact = contact[0];
                });
            });
          }
        });
      }
    });
  }

  public delete(): void {
    this.loading = true;

    this.$orders.deleteOrder(this.data?.id).subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.$message.success('Fatura excluída com sucesso!');
        } else {
          this.$message.error('Erro ao excluir a fatura, tente novamente!');
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao excluir a fatura, tente novamente!');
        throw new Error(error);
      },
    });
  }

  public copyContent(): void {
    /* const url = getTemporaryRoute(); */
    const url = environment.appDomain;

    this.$internal.copyContent(`${url}/${this.orderRoute}`);
  }

  public editOrCopySimple(copy: boolean): void {
    const invoice = this.data;

    if (copy) {
      delete invoice.id;
    }

    this.$drawer
      .create({
        nzTitle: 'Editar fatura',
        nzContent: DrawerCreateSimpleComponent,
        nzContentParams: { data: invoice },
        nzBodyStyle: { position: 'absolute', top: 0, right: 0, height: '100vh', width: '100%' },
        nzWidth: '500px',
        nzFooter: null,
        nzClosable: false,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.loading = true;

          if (!res.id) {
            this.$orders.createOrder(res).subscribe({
              next: (result) => {
                if (result.status === 201) {
                  this.$message.success('Fatura copiada com sucesso!');
                } else {
                  this.$message.error('Erro ao copiar a fatura, tente novamente!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao copiar a fatura, tente novamente!');
                throw new Error(error);
              },
            });
          } else {
            this.loading = true;

            this.$orders.updateOrder(res).subscribe({
              next: (result) => {
                if (result.status === 200) {
                  this.$message.success('Fatura atualizada com sucesso!');
                } else {
                  this.$message.error('Erro ao atualizar a fatura, tente novamente!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao atualizar a fatura, tente novamente!');
                throw new Error(error);
              },
            });
          }
        }
      });
  }

  public openPayment(): void {
    /* const url = getTemporaryRoute(); */
    const url = environment.appDomain;

    window.open(`${url}/${this.orderRoute}`, '_blank');
  }

  public showContactDetails(): void {
    this.$drawer.create({
      nzTitle: 'Detalhes do Contato',
      nzWidth: '800px',
      nzContent: ContactDetailsComponent,
      nzContentParams: {
        value: this.contact,
      },
    });
  }
}
