import moment from 'moment';
import { Observable } from 'rxjs';
import { isEmpty } from 'lodash';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '@gen/environments';
import { LinksModel } from '../../models/links/links.model';
import { PaymentInfo } from '../../models/payments/payment-info.model';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';

@Injectable()
export class PaymentLinksService {
  constructor(private $fireStore: AngularFirestore) {}

  public getPublicLink(sellerId: string, objId: string, type: string): Observable<LinksModel[]> {
    return this.$fireStore
      .collection(`marketplaces/${environment.marketplaceId}/sellers/${sellerId}/links`, (ref) =>
        ref.where(type, '==', objId)
      )
      .valueChanges() as Observable<LinksModel[]>;
  }

  public updateLink(paymentInfo: PaymentInfo): Promise<void> {
    let data = paymentInfo.paymentLink;

    data.worthlessLink = false;

    return this.$fireStore
      .collection(`marketplaces/${environment.marketplaceId}/sellers/${paymentInfo.order.sellerId}/links`)
      ?.doc(data.id)
      .update(data);
  }

  public getLinkList(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): Observable<Array<LinksModel>> {
    return this._collection(marketplaceId, sellerId, filter).valueChanges();
  }

  public createNewLink(marketplaceId: string, sellerId: string, data: LinksModel): Promise<void> {
    const linkId = uuidv4();

    data.id = linkId;

    return this._collection(marketplaceId, sellerId)?.doc(linkId).set(data);
  }

  public deleteLink(marketplaceId: string, sellerId: string, id: string): Promise<void> {
    return this._collection(marketplaceId, sellerId)?.doc(id).delete();
  }

  public getLinkDetail(marketplaceId: string, sellerId: string, id: string): Observable<LinksModel> {
    return this._collection(marketplaceId, sellerId).doc(id).valueChanges();
  }

  public getLinksWithFilters(
    marketplaceId: string,
    sellerId: string,
    dateFilter: ResponseFilterModel
  ): Observable<LinksModel[]> {
    return this._collection(marketplaceId, sellerId, dateFilter).valueChanges();
  }

  private _collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): AngularFirestoreCollection<LinksModel> {
    if (filter && !isEmpty(filter)) {
      return this.$fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/links`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate: string = moment(filter.creationDateFilter.date)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate: string = moment(filter.creationDateFilter.date)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate: string = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate: string = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date: string = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }

          return query.orderBy('insertedAt', 'desc');
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    return this.$fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/links`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }
}
