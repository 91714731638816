import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormArray,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { ItemsModel } from 'libs/shared/src/lib/models/items/items.model';
import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { PriceModel } from 'libs/shared/src/lib/models/plans/price.model';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-plan-create',
  templateUrl: './plan-create.component.html',
  styleUrls: ['./plan-create.component.scss'],
})
export class PlanCreateComponent implements OnInit {
  @Input() data: any;

  public planForm: FormGroup;
  public prices: FormArray;

  public listOfOption: Array<{ value: ItemsModel; label: string }> = [];
  public itemsInput: { items: Array<ItemModel>; newItem: boolean };
  public methodsList: Array<{ value: string; label: string }> = [];

  public addItemEnable: boolean = false;
  public newItem: boolean = false;
  public title: string = 'Novo plano';
  public loading: boolean = false;
  public schedules: Array<{ value: string; label: string }> = [];
  public url: string;

  constructor(
    private $methods: InternalService,
    public $drawerRef: NzDrawerRef,
    private fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.methodsList = this.$methods.methodsList;
    this.schedules = this.$methods.scheduleList;

    if (this.data?.id) {
      this.title = 'Editar plano';
    }

    this.planForm = this.fb.group({
      id: new FormControl(this.data.id || null),
      description: new FormControl(this.data?.description || '', [Validators.required]),
      file: new FormControl(null),
      prices: this.fb.array([], [Validators.required]),
      name: new FormControl(this.data?.name || '', [Validators.required]),
    });

    this.prices = this.planForm.controls['prices'] as FormArray;

    if (this.data?.imageUrl) {
      this.url = this.data?.imageUrl;
    }

    if (this.data?.prices) {
      this.data?.prices.forEach((price: PriceModel) => {
        this.setPrice(price.id, price.amountCents / 100, price.schedulePlans);
      });
    } else {
      this.setPrice(null, null, '');
    }
  }

  public setPrice(id: string, amountCents: number, schedulePlans: string): void {
    const form = this.fb.group({
      id: new FormControl(id),
      schedulePlans: new FormControl(schedulePlans, [Validators.required]),
      amountCents: new FormControl(amountCents, [Validators.required, this.minValueValidator(10)]),
    });

    this.prices.push(form);
  }

  private minValueValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value < minAmount;
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }

  public onFileChange(event: any): void {
    if (event) {
      const file = event.target?.files[0] || event;
      let reader = new FileReader();
      this.planForm.get('file').setValue(file);
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
      reader.readAsDataURL(event.target?.files[0] || event);
    }
  }

  public removePrice(index: number): void {
    this.prices.removeAt(index);
  }

  public setHandleSubmit(): void {
    if (this.planForm.valid) {
      this.prices.controls.forEach((element, index: number) => {
        let price: PriceModel = element.value;
        const value = price.amountCents * 100;
        this.prices.at(index).get('amountCents').setValue(value);
      });
      this.$drawerRef.close(this.planForm.value);
    } else {
      this.planForm.markAsDirty();
      this.planForm.markAllAsTouched();
    }
  }
}
