import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { PaymentModel } from '../../../models/payment/payment.model';
import { PaymentsService } from '../../../services/payments/payments.service';
import { TableHeaderModel } from '../../../models/table/table-header.model';
import { TableSettingsModel } from '../../../models/table/table-settings.model';

@Component({
  selector: 'app-drawer-payments-details',
  templateUrl: './drawer-payments-details.component.html',
  styleUrls: ['./drawer-payments-details.component.scss'],
})
export class DrawerPaymentsDetailsComponent implements OnInit {
  @Input() payment: PaymentModel;

  public listOfHeadersColumn: TableHeaderModel[];
  public listOfData: any[] = [];
  public settingValue: TableSettingsModel;
  public listOfTotalHeadersColumn: TableHeaderModel[];
  public listOfTotalData: any[] = [];
  public settingTotalValue: TableSettingsModel;
  public loadingBtn: boolean;

  constructor(private $payments: PaymentsService, private $message: NzMessageService) {}

  public ngOnInit(): void {
    this.getTotalTableData();
    this.getTableData();
    this.getListsOfData();
  }

  private getListsOfData(): void {
    this.listOfData = this.payment?.order?.items || [];

    this.listOfTotalData = [
      {
        subtotal: this.payment.subtotalCents,
        discount: this.payment.discountCents,
        delivery: this.payment.shippingCents,
        addition: this.payment.additionCents,
        total: this.payment.totalCents,
      },
    ];
  }

  private getTotalTableData(): void {
    this.settingTotalValue = {
      checkbox: false,
      pagination: false,
      footer: false,
      expandable: false,
    };

    this.listOfTotalHeadersColumn = [
      { name: 'Subtotal' },
      { name: 'Desconto' },
      { name: 'Frete' },
      { name: 'Adições' },
      { name: 'Total' },
    ];
  }

  private getTableData(): void {
    this.settingValue = {
      checkbox: false,
      pagination: true,
      footer: false,
      expandable: false,
    };

    this.listOfHeadersColumn = [{ name: 'Descrição' }, { name: 'Quantidade' }, { name: 'Valor' }];
  }

  public cancelPayment(): void {
    this.loadingBtn = true;

    this.$payments.cancelPaymentApollo(this.payment?.id).subscribe({
      next: (res) => {
        if (res) {
          this.$message.success('Solicitação de reembolso realizada.');
        }

        this.loadingBtn = false;
      },
      error: (error) => {
        this.loadingBtn = false;
        this.$message.error('Solicitação em andamento.');
        throw new Error(error);
      },
    });
  }
}
