import { gql } from 'apollo-angular';

const LIST_SELLERS_QUERY = gql`
  query ListSellers($filter: FilterSellersParams) {
    listSellers(filters: $filter, pagination: { sortingOrder: DESC, orderBy: "inserted_at" }) {
      id
      cpf
      cnpj
      status
      displayName
      insertedAt
      company {
        businessName
      }
    }
  }
`;

const GET_SELLER = gql`
  query getSeller($id: UUID4!) {
    getSeller(id: $id) {
      company {
        businessName
        tradeName
        cnpj
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      id
      cpf
      cnpj
      phone
      status
      insertedAt
      occupation
      email
      emailVerified
      phoneVerified
      isStore
      receivingLimit
      observation
      person {
        name
        birthDate
        nameMother
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      limits {
        action {
          name
        }
        functionality {
          name
        }
        category {
          name
        }
        perPeriod {
          name
        }
        amount
      }
      externalConsults {
        cpfCnpj
        datasets
      }
      bankAccounts {
        bankNumber
        agencyNumber
        agencyCheckNumber
        type
        accountNumber
        accountCheckNumber
      }
      documents {
        bucket
        id
        name
        url
        path
        reason
        insertedAt
        updatedAt
        status
      }
      versions {
        event
        insertedAt
      }
    }
  }
`;

const GET_SELLER_BUSINESS = gql`
  query getSeller($id: UUID4!) {
    getSeller(id: $id) {
      company {
        businessName
        tradeName
        cnpj
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      id
      cpf
      cnpj
      phone
      status
      insertedAt
      occupation
      email
      isStore
      receivingLimit
      observation
      person {
        name
        birthDate
        nameMother
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      bankAccounts {
        bankNumber
        agencyNumber
        agencyCheckNumber
        type
        accountNumber
        accountCheckNumber
      }
    }
  }
`;

export { LIST_SELLERS_QUERY, GET_SELLER, GET_SELLER_BUSINESS };
