import { SellerModel } from '../sellers/sellers.model';
import { OptionModel } from '../option/option.model';
import { CategoryModel } from '../categories/categories.model';
import { ItemPriceModel } from './item-price.model';

export class ItemsModel {
  public id?: string;
  public originalId?: string;
  public name: string;
  public description?: string;
  public amountCents: number;
  public type: Types;
  public itemCategoryId?: string;
  public itemCategory?: CategoryModel = new CategoryModel();
  public itemOptionsIds?: string[];
  public itemOptions?: Array<OptionModel>;
  public itemUnitId?: string;
  public imageUrl?: string;
  public imagesUrls?: string[];
  public itemPrices?: ItemPriceModel[];
  public sellerId?: string;
  public files?: any[];
  public isOnOrder?: boolean;
  public notes?: string;
  public status?: string;
  public pointDescription?: string;
  public pointId?: string;
  public ableSmartpos?: boolean;
  public useForConsumption?: boolean;
  public ablePaymentLink?: boolean;
  public quantity?: number;
  public selected?: boolean;
  public categoryName?: string;
  public insertedAt?: string;
  public updatedAt?: string;
  public commandId?: string;
  public customerName?: string;
  public active?: boolean;
  public seller?: SellerModel;
}

export type Types = 'PRODUCT' | 'SERVICE';
