import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionModel, TypeModel } from '../../../models/option/option.model';

@Component({
  selector: 'app-modal-create-option',
  templateUrl: './modal-create-option.component.html',
  styleUrls: ['./modal-create-option.component.scss'],
})
export class ModalCreateOptionComponent implements OnInit {
  public optionForm!: FormGroup;
  public title: string = 'Criar variação';

  @Input() public data: OptionModel = new OptionModel();

  constructor(private readonly fb: FormBuilder, public readonly $modalRef: NzModalRef) {
    this.createForm();
  }

  public ngOnInit(): void {
    if (this.data?.id) {
      setTimeout(() => {
        this.updateForm();
      }, 0);
    }
  }

  private updateForm(): void {
    const types = this.data.types.map((type) => type.name);

    this.title = 'Editar variação';
    this.optionForm.patchValue({
      id: this.data?.id,
      name: this.data?.name || '',
      displayName: this.data?.displayName || '',
      types: types,
    });
  }

  private createForm(): void {
    this.optionForm = this.fb.group({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      displayName: new FormControl('', [Validators.required]),
      types: new FormControl([], [Validators.required]),
    });
  }

  public setHandleSubmit(): void {
    const types: Array<TypeModel> = [];

    this.optionForm.get('types').value.forEach((element: string) => {
      const name = element;
      types.push({ name });
    });

    this.optionForm.get('types').setValue(types);

    this.$modalRef.close(this.optionForm.value);
  }
}
