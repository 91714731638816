import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { MallModel } from '../../models/mall/mall.model';
import { PixModel } from '../../models/pix/pix-object.model';
import { TicketModel } from './../../models/ticket/ticket.model';
import { ResponseModel } from '../../models/response/response.model';
import { PaidTicketModel } from './../../models/ticket/paidTicket.model';
import { RouteParamsModel } from './../../models/route-params/route-params.model';
import { PaymentTicketParamModel } from '../../models/ticket/paymentTicketParam.model';
import { SaveReceiptParamsModel } from '../../models/receipt/save-receipt-params.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { PaymentTicketResponseModel } from '../../models/ticket/paymentTicketResponse.model';

@Injectable()
export class TicketService {
  constructor(
    private readonly afStore: AngularFirestore,
    private $notification: StateManagementService,
    private readonly $functions: AngularFireFunctions
  ) { }

  public getTicket(data: { mallId: string; ticketId: string }): Observable<ResponseModel<TicketModel>> | undefined {
    if (!data.ticketId) {
      return of(undefined);
    }

    return this.$functions.httpsCallable('getVirtualWindowTicket')(data);
  }

  public getTicketMock(data: { mallId: string; ticketId: string }): Observable<ResponseModel<TicketModel>> {
    return this.$functions.httpsCallable('getVirtualWindowTicketMock')(data);
  }

  public payTicket(data: PaymentTicketParamModel): Observable<ResponseModel<PaymentTicketResponseModel>> {
    return this.$functions.httpsCallable('payVirtualWindowTicket')(data);
  }

  public payPixTicket(data: { transaction: string, customer: string }): Observable<ResponseModel<PixModel>> {
    return this.$functions.httpsCallable('payVirtualWindowTicket')(data);
  }

  public getMalls(): Observable<ResponseModel<Array<MallModel>>> {
    return this.$functions.httpsCallable('getMallList')(null);
  }

  public getMallById(data: { mallId: string }): Observable<ResponseModel<Array<MallModel>>> {
    return this.$functions.httpsCallable('getMallById')(data);
  }

  public getMall(mallId: string): void {
    this.getMallById({ mallId }).subscribe((res) => {
      if (res.status === 200) {
        this.$notification.setMall(res.body.rows[0]);
      }
    });
  }

  public getAndSetTicket(ids: RouteParamsModel): void {
    this.getTicket({ mallId: ids.mallId, ticketId: ids.ticketId }).subscribe((res) => {
      if (res?.status === 200) {
        this.$notification.setTicket(res.body);
      }
    });
  }

  public getStayedTime(ticket: TicketModel): { stayedTime: number; valuePerHour: number } {
    const horarioEntrada = moment(ticket.entradaDatahora);

    const stayedTime = moment().diff(horarioEntrada, 'minutes') || 0;
    const valuePerHour = Number(ticket.valorTotal) / (stayedTime / 60);

    return { stayedTime, valuePerHour };
  }

  public saveReceipt(params: SaveReceiptParamsModel): void {
    const id = uuidv4();

    const payload = {
      id,
      customer: {
        name: params.card?.consumidor?.nome || params.user?.name || 'Usuário anônimo',
        email: params.user?.email || '',
        cpf: params.user?.cpf || '',
      },
      mall: params.mall,
      ticket: params.ticket,
      paymentNumber: params.res?.transacao || params.ticket?.transacao || '',
      stayedTime: this.getStayedTime(params.ticket).stayedTime,
      paymentAt: moment().format(),
      paymentValue: params.res?.valorPago || params.ticket?.valorPago || params.ticket?.analise?.valorDevido,
      card: {
        brand: params.card?.brand || params.pix?.ticket?.method || '',
        last4: params.card?.last4 || '',
      },
    };

    this.afStore.doc(`parkingUsers/${params.user.id}/payments/${id}`).set(payload, { merge: true });
  }

  public getPaidTicket(id: string): Observable<PaidTicketModel[]> | undefined {
    const userId = localStorage.getItem('parkingUser') || id;

    if (userId) {
      return this.afStore.collection(`parkingUsers/${userId}/payments`).valueChanges() as Observable<PaidTicketModel[]>;
    }

    return null;
  }
}
