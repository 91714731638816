import { isEmpty } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { mergeMap, of, Subject, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ModalFindAddressComponent } from '../../modals/modal-find-address/modal-find-address.component';
import { SelectOptionModel } from '../../../models/utils/select-option.model';
import { UserClaimsModel } from '../../../models/user-claims/user-claims.model';
import { DashboardModel } from '../../../models/onboarding/dashboard.model';
import { STATES } from '../../../utils/constants';
import { StateManagementService } from '../../../state-management/state-management.service';
import { ignoreSpecialCharacters, trimData } from '../../../utils/utils';
import { FullNameValidator } from '../../../validators/fullNameValidator';
import { CpfValidator } from '../../../validators/cpfValidator';
import { AddressNumberValidator } from '../../../validators/addressNumberValidator';
import { Message } from '../../../utils/message';
import { OnboardingService } from '../../../services/onboarding/onboarding.service';
import { AddressService } from '../../../services/address/address.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent implements OnInit, OnDestroy {
  @Input() onSubmit: Subject<boolean>;

  public loading: boolean = false;
  public showAddress: boolean = false;
  public states: SelectOptionModel[] = STATES;
  public gutter: any = { xs: 8, sm: 16, md: 24, lg: 32 };
  public formGroup!: FormGroup;
  private user!: UserClaimsModel;
  private collection!: DashboardModel;
  private subscription!: Subscription;

  constructor(
    private readonly fb: FormBuilder,
    private $message: NzMessageService,
    private $onboarding: OnboardingService,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService,
    private $address: AddressService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getUser();
    this.getDashboardActivationCollection();

    this.formGroup.valueChanges.subscribe((data) => {
      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });

    this.subscription = this.onSubmit.subscribe((v) => {
      if (v) {
        if (this.formGroup.valid) {
          this.saveData();
        } else {
          Object.values(this.formGroup.controls).forEach((control) => {
            if (control.invalid) {
              control.markAsDirty();
              control.updateValueAndValidity({ onlySelf: true });
            }
          });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public openShowAddress(): void {
    this.showAddress = true;

    this.$modal
      .create({
        nzTitle: 'Procurar por endereço',
        nzContent: ModalFindAddressComponent,
        nzWidth: '30%',
        nzCentered: true,
        nzZIndex: 1000,
      })
      .afterClose.pipe(
        mergeMap((resp: any) => {
          if (resp) {
            return this.$address.getFullAddress(resp.state, resp.city, resp.street);
          }

          return of(undefined);
        })
      )
      .subscribe((address?: any[]) => {
        if (address && address?.length > 0) {
          this.formGroup.patchValue({
            postalCode: address[0].cep,
            city: address[0].localidade,
            state: address[0].uf,
            neighborhood: address[0].bairro,
            street: address[0].logradouro,
            complement: address[0].complemento,
          });
        } else {
          this.$message.error('Endereço não encontrado.');
        }
      });
  }

  public searchPostalCode(): void {
    const cep = this.formGroup.get('postalCode').value;

    if (cep && cep.length === 8) {
      this.loading = true;

      this.$address.getCep(cep).subscribe({
        next: (address) => {
          if (!isEmpty(address)) {
            this.formGroup.patchValue({
              city: address.localidade,
              state: address.uf,
              neighborhood: address.bairro,
              street: address.logradouro,
              complement: address.complemento,
            });
            this.showAddress = true;
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$message.create('error', Message.CEP_NOT_FOUND);
          throw new Error(error);
        },
      });
    }
  }

  private async saveData(): Promise<void> {
    this.$notification.setOnboardingBtnLoading(true);
    await this.$onboarding.saveDashboardPersonalData(this.user, {
      done: true,
      data: trimData(this.formGroup.value),
    });
    this.$notification.setOnboardingBtnLoading(false);
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      name: new FormControl('', [Validators.required, FullNameValidator.isValid()]),
      cpf: new FormControl('', [Validators.required, CpfValidator.isValid()]),
      neighborhood: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required, AddressNumberValidator.isValid()]),
      complement: new FormControl(''),
    });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection?.personalData?.data) {
        this.collection = collection;
        const personalData = this.collection.personalData.data;
        this.formGroup.patchValue({
          name: personalData.name,
          cpf: personalData.cpf,
          phoneNumber: personalData.phoneNumber,
          postalCode: personalData.postalCode,
          city: personalData.city,
          state: personalData.state,
          neighborhood: personalData.neighborhood,
          street: personalData.street,
          number: personalData.number,
          complement: personalData.complement,
        });
      }

      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });
  }

  public noSpecialCharacter(event: any): void {
    return ignoreSpecialCharacters(event);
  }
}
