import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService, NzDrawerRef } from 'ng-zorro-antd/drawer';

import { RoleEnum } from '../../../../enums/roles';
import { DocumentModel } from '../../../../models/document/document.model';
import { CustomerModel } from '../../../../models/customer/customer.model';
import { TableHeaderModel } from '../../../../models/table/table-header.model';
import { CustomerService } from '../../../../services/customer/customer.service';
import { TableSettingsModel } from '../../../../models/table/table-settings.model';
import { SharedMethodsService } from '../../../../services/internal/shared-methods.service';
import { DrawerCustomersEditComponent } from '../drawer-customers-edit/drawer-customers-edit.component';
import { ConfirmationDeleteComponent } from '../../../confirmation-delete/confirmation-delete.component';

@Component({
  selector: 'app-drawer-customer-details',
  templateUrl: './drawer-customer-details.component.html',
  styleUrls: ['./drawer-customer-details.component.scss'],
})
export class DrawerCustomerDetailsComponent implements OnInit {
  @Input() id: string;

  public customer: CustomerModel = new CustomerModel();
  public loading: boolean = false;
  public listOfHeadersColumn: TableHeaderModel[] = [
    { name: 'Data' },
    { name: 'Estabelecimento' },
    { name: 'Valor' },
    { name: 'Status' },
  ];
  public listOfHeadersColumnDocuments: TableHeaderModel[] = [
    { name: 'Nome' },
    { name: 'Status' },
    { name: 'Data de envio' },
    { name: 'Ver documento' },
    { name: 'Ações' },
  ];
  public listOfHeadersColumnLimits: TableHeaderModel[] = [
    { name: 'Acao' },
    { name: 'Funcionalidade' },
    { name: 'Categoria' },
    { name: 'Valor' },
    { name: 'Período' },
  ];
  public listOfData: any[] = [];
  public settingValue: TableSettingsModel = {
    checkbox: false,
    pagination: true,
    footer: false,
    expandable: false,
  };
  private customerName: string = ';';

  constructor(
    public $customer: CustomerService,
    public readonly drawerRef: NzDrawerRef,
    private $methods: SharedMethodsService,
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.getCustomer();
  }

  public getCustomer(): void {
    this.loading = true;

    this.$customer.getCustomerById(this.id).subscribe({
      next: (res) => {
        if (res?.data?.getCustomer) {
          this.customer = JSON.parse(JSON.stringify(res.data.getCustomer));
          this.customerName = this.customer.name;
          this.customer.limits.map((limit) => {
            limit = this.$methods.translateLimits(limit);
          });
          this.customer.documents.map((document) => (document.url = this.$methods.getDocumentUrl(document)));
          if (this.customer?.user?.role) {
            this.customer.user.role = RoleEnum[this.customer?.user?.role];
          }
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao buscar o customer.');
        throw new Error(error);
      },
    });
  }

  public handleAction(action: { action: string; data: DocumentModel }): void {
    if (action.action === 'APPROVE') {
      this.$methods.approve(action.data);
    }

    if (action.action === 'REJECT') {
      this.$methods.reject(action.data);
    }
  }

  public showEditDrawer(): void {
    this.$drawer.create({
      nzContent: DrawerCustomersEditComponent,
      nzContentParams: {
        customer: this.customer,
      },
      nzWidth: '80%',
      nzClosable: false,
    });
  }

  public banCustomer(): void {
    this.$modal
      .create({
        nzContent: ConfirmationDeleteComponent,
        nzComponentParams: {
          message: `Tem certeza que deseja banir o usuário B2C <strong>${this.customerName}</strong>?`,
          isImportant: true,
          buttonName: 'Banir',
        },
        nzFooter: null,
        nzClosable: true,
      })
      .afterClose.subscribe((resp: boolean) => {
        if (resp) {
          this.$customer.banCustomer(this.customer.id).subscribe({
            next: (res) => {
              if (res) {
                this.$message.success('Usuário B2C banido com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error(error?.message || 'Erro ao banir o usuário B2C.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public declineCustomer(): void {
    this.$modal
      .create({
        nzContent: ConfirmationDeleteComponent,
        nzComponentParams: {
          message: `Tem certeza que deseja reprovar o usuário B2C <strong>${this.customerName}</strong>?`,
          isImportant: false,
          buttonName: 'Reprovar',
        },
        nzFooter: null,
        nzClosable: true,
      })
      .afterClose.subscribe((resp: boolean) => {
        if (resp) {
          this.$customer.declineCustomer(this.customer.id).subscribe({
            next: (res) => {
              if (res) {
                this.$message.success('Usuário B2C reprovado com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error(error?.message || 'Erro ao reprovar o usuário B2C.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public approveCustomer(): void {
    this.$modal
      .create({
        nzContent: ConfirmationDeleteComponent,
        nzComponentParams: {
          message: `Tem certeza que deseja aprovar o usuário B2C <strong>${this.customerName}</strong>?`,
          isImportant: false,
          buttonName: 'Aprovar',
        },
        nzFooter: null,
        nzClosable: true,
      })
      .afterClose.subscribe((resp: boolean) => {
        if (resp) {
          this.$customer.approveCustomer(this.customer.id).subscribe({
            next: (res) => {
              if (res) {
                this.$message.success('Usuário B2C aprovado com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error(error?.message || 'Erro ao aprovar o usuário B2C.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public reprocessCustomer(): void {
    this.$modal
      .create({
        nzContent: ConfirmationDeleteComponent,
        nzComponentParams: {
          message: `Tem certeza que deseja reprocessar o usuário B2C <strong>${this.customerName}</strong>?`,
          isImportant: false,
          buttonName: 'Reprocessar',
        },
        nzFooter: null,
        nzClosable: true,
      })
      .afterClose.subscribe((resp: boolean) => {
        if (resp) {
          this.$customer.reprocessCustomer(this.customer.id).subscribe({
            next: (res) => {
              if (res) {
                this.$message.success('Usuário B2C reprocessado com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error(error?.message || 'Erro ao reprocessar o usuário B2C.');
              throw new Error(error);
            },
          });
        }
      });
  }
}
