import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mergeMap, of } from 'rxjs';
import { AuthService } from '../../services/auth/auth-business/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { OnboardingService } from '../../services/onboarding/onboarding.service';

import { FullNameValidator } from 'libs/shared/src/lib/validators/fullNameValidator';
import { CpfOrCnpjValidator } from 'libs/shared/src/lib/validators/cpfAndCnpj';
import { EmailValidator } from 'libs/shared/src/lib/validators/emailValidator';
import { LoginModel } from '../../models/login/login.model';
import { Message } from '../../utils/message';
import { AddressService } from '../../services/address/address.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AddressModel } from '../../models/address/address.model';
import { CreateAccountModel } from '../../models/auth/create-account.model';
import { InitialDataModel } from '../../models/onboarding/external/initial-data.model';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
})
export class LoginRegisterComponent implements OnInit {
  public disabled: boolean = true;
  public userPhoneSet: boolean = false;
  public newPhoneSet: boolean = false;
  public login: LoginModel;
  public formCreateAccount: FormGroup;
  public formPhoneLogin: FormGroup;
  public newAccount: CreateAccountModel = new CreateAccountModel();
  public newAccountAddress: AddressModel = new AddressModel();
  private isAuth: boolean;
  public loadingSignIn: boolean = false;
  @Output() public authenticated = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private $auth: AuthService,
    private $message: NzMessageService,
    private $notification: StateManagementService,
    private $address: AddressService,
    private $onboarding: OnboardingService,
    private modalRef: NzModalRef
  ) { }

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.formCreateAccount = this.fb.group({
      name: new FormControl('', [Validators.required, FullNameValidator.isValid()]),
      cpf: new FormControl('', [Validators.required, CpfOrCnpjValidator.isCpfOrCnpjValid()]),
      phoneNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, EmailValidator.isValid()]),
      postalCode: new FormControl('', [Validators.required]),
      streetNumber: new FormControl('', [Validators.required]),
      complement: new FormControl(),
      saveInfo: new FormControl(false),
    });

    this.formPhoneLogin = this.fb.group({
      userPhone: new FormControl('', [Validators.required]),
    });
  }

  public getPostalCode(value: string): void {
    if (value && value.length === 8) {
      this.$address.getCep(value).subscribe({
        next: (res: any) => {
          if (res) {
            this.newAccountAddress.city = res.localidade;
            this.newAccountAddress.state = res.uf;
            this.newAccountAddress.neighborhood = res.bairro;
            this.newAccountAddress.line1 = res.logradouro;
            this.newAccountAddress.postalCode = value;
          }
        },
        error: (error) => {
          this.$message.create('error', Message.CEP_NOT_FOUND);
          this.formCreateAccount.get('postalCode')?.setErrors({ pattern: true });
          throw new Error(error);
        },
      });
    }
  }

  public changeNumber(value: boolean) {
    this.userPhoneSet = value;
    this.newPhoneSet = value;
  }

  public createAccount(): void {
    this.loadingSignIn = true;
    this.newAccountAddress.line2 = this.formCreateAccount.get('streetNumber').value;
    this.newAccountAddress.line3 = this.formCreateAccount.get('complement').value;

    this.newAccount = {
      username: this.formCreateAccount.get('name').value,
      email: this.formCreateAccount.get('email').value,
      phoneNumber: this.formCreateAccount.get('phoneNumber').value,
      cpf: this.formCreateAccount.get('cpf').value,
    };

    const initialData: InitialDataModel = {
      name: this.newAccount.username,
      cpf: this.newAccount.cpf, phoneNumber:
        this.newAccount.phoneNumber
    }

    this.$auth.userWithPhoneNumberExists(this.newAccount.phoneNumber).subscribe((res) => {
      if (res.body == false) {
        this.$onboarding.startCustomerOnboarding(initialData).subscribe((res) => {
          if (res) {
            this.newPhoneSet = true;
            this.loadingSignIn = false;
            return this.$auth.sendSmsCode({ phoneNumber: this.login.phoneNumber });
          }
          this.loadingSignIn = false;
          this.$message.create('error', Message.ERROR_REGISTER_ACCOUNT);
          return of(undefined);
        });
      } else {
        this.loadingSignIn = false;
        this.$message.create('error', Message.PHONE_ALREADY_EXISTS);
        return of(undefined);
      }
      return of(undefined);
    });
  }

  public isAuthenticated(event): void {
    this.isAuth = event;
    if (this.newPhoneSet) {
      this.$onboarding.createCustomer(this.newAccount, this.newAccountAddress).subscribe({
        error:
          (err) => {
            this.$message.create('error', err);
          }
      });
    }
    this.authenticated.emit(this.isAuth);
  }

  public onSignIn(): void {
    this.loadingSignIn = true;
    this.login = new LoginModel();
    this.login.phoneNumber = this.formPhoneLogin.get('userPhone').value;

    this.$auth
      .userWithPhoneNumberExists(this.login.phoneNumber)
      .pipe(
        mergeMap((res) => {
          if (res.body) {
            return this.$auth.sendSmsCode({ phoneNumber: this.login.phoneNumber });
          }
          this.$message.create('error', Message.PHONE_NOT_FOUND);
          return of(undefined);
        })
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.sendVerificationCode) {
            this.$notification.setPhoneNumber(this.login.phoneNumber);
            this.newAccount.phoneNumber = this.login.phoneNumber;
            this.userPhoneSet = true;
            this.loadingSignIn = false;
          }
        },
        error: (error) => {
          this.$message.create('error', error);
          this.loadingSignIn = false;
        },
      });
  }
  public closed(value: boolean): void {
    this.modalRef.destroy(value);
  }
}
