import { gql } from 'apollo-angular';

const PAYMENT_FIELDS = `
  acquirerPayment
  additionCents
  antifraud
  bankSlip {
    acquirerNumber
    bank
    bankSlipId
    barcode
    expirationDate
    instructions
    issueDate
    printLinkPdf
    printLinkHtml
    typefulLine
  }
  credit {
    authorizedAt
    acquirerTransactionId
    authorizationCode
    creditCardId
    dynamicMcc
    saveCard
    terminalNsu
    transactionId
    transactionType
    creditCard {
      holderName
      last4
      brand
    }
    delayCapture
    installments
    reasonCode
    reasonMessage
  }
  customer {
    name
    cpf
  }
  device
  discountCents
  fees {
    id
    name
    insertedAt
    feePercent
    feeAmountCents
  }
  history
  id
  insertedAt
  location {
    latitude
    longitude
  }
  method
  order {
    items {
      description
      quantity
      unitPriceCents
    }
  }
  pix {
    acquirerTransactionId
    brcode
    expiration
  }
  seller {
    person {
      name
    }
    company {
      businessName
    }
  }
  shippingCents
  status
  subtotalCents
  totalCents
`

const LIST_PAYMENTS_QUERY = gql`
  query ListPayments($filter: FilterPaymentsParams) {
    listPayments(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC }) {
      ${PAYMENT_FIELDS}
    }
  }
`;

const GET_PAYMENT_QUERY = gql`
  query GetPayment($id: UUID4!) {
    getPayment(id: $id) {
      ${PAYMENT_FIELDS}
    }
  }
`;

export { LIST_PAYMENTS_QUERY, GET_PAYMENT_QUERY };
