import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import moment from 'moment-timezone';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnInit } from '@angular/core';

import { ItemsService } from 'libs/shared/src/lib/services/products/items/items.service';
import { OrdersService } from 'libs/shared/src/lib/services/orders/orders.service';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { SellerModel } from 'libs/shared/src/lib/models/sellers/sellers.model';
import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { LinksModel } from 'libs/shared/src/lib/models/links/links.model';
import { ItemsModel } from 'libs/shared/src/lib/models/items/items.model';
import { OrderModel } from 'libs/shared/src/lib/models/orders/order.model';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-link-creates',
  templateUrl: './link-creates.component.html',
  styleUrls: ['./link-creates.component.scss'],
})
export class LinkCreatesComponent implements OnInit {
  @Input() data: any;

  public linkForm!: FormGroup;
  public listOfOption: Array<{ value: ItemsModel; label: string }> = [];
  public itemsInput: { items: Array<ItemModel>; newItem: boolean } = { items: [], newItem: false };
  public methodsList: Array<{ value: string; label: string }> = [];
  public addItemEnable: boolean = false;
  public newItem: boolean = false;
  public title: string = 'Novo link';
  public pageLoading: boolean = true;
  public loading: boolean = false;
  public isOpenSelect: boolean = true;
  public sellerId: string = '';
  public today = new Date();
  public disabledDate = (current: Date): boolean => current < new Date();

  constructor(
    private $items: ItemsService,
    private $order: OrdersService,
    private readonly fb: FormBuilder,
    private $methods: InternalService,
    public readonly $drawerRef: NzDrawerRef,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getNotification();
  }

  public listenChanges(): void {
    this.linkForm.get('item').valueChanges.subscribe((value) => {
      if (value) {
        this.itemsInput = { items: [value], newItem: this.newItem };
      } else {
        this.itemsInput = { items: null, newItem: false };
      }
    });

    this.linkForm.get('worthlessLink').valueChanges.subscribe((value) => {
      if (value !== null) {
        if (value === true) {
          this.linkForm.get('totalCents').setValue('');
          this.linkForm.get('totalCents').clearValidators();
        } else {
          this.linkForm.get('totalCents').setValidators(Validators.required);
        }

        this.linkForm.get('totalCents').updateValueAndValidity();
      }
    });

    this.linkForm.get('type').valueChanges.subscribe((value) => {
      if (value === 'VALUE') {
        this.linkForm.get('totalCents').setValidators(Validators.required);
        this.linkForm.get('item').clearValidators();
      } else {
        this.linkForm.get('item').setValidators(Validators.required);
        this.linkForm.get('totalCents').clearValidators();
      }

      this.linkForm.get('totalCents').updateValueAndValidity();
      this.linkForm.get('item').updateValueAndValidity();
    });
  }

  public getNotification(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.sellerId) {
        this.getItems(res.sellerId);
        this.sellerId = res.sellerId;
      } else {
        this.getItems(this.data?.sellerId);
      }
    });

    this.$notification.sellers.subscribe((seller) => {
      if (seller) {
        this.createForm(seller);
      }
    });
  }

  public createForm(seller: SellerModel): void {
    this.methodsList = this.$methods.getMethodsBySeller(seller);

    if (this.data?.id) {
      this.title = 'Editar Link';
    }

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.linkForm = this.fb.group({
      allowPromotionCodes: new FormControl(this.data?.allowPromotionCodes || false || false, [Validators.required]),
      description: new FormControl(this.data?.description || '', [Validators.required]),
      expirationDate: new FormControl(this.data?.expirationDate || '', [Validators.required]),
      id: new FormControl(this.data?.id || ''),
      item: new FormControl(this.data?.item || ''),
      itemId: new FormControl(this.data?.itemId || null),
      orderId: new FormControl(this.data?.orderId || null),
      paymentOptions: new FormControl(this.data?.paymentOptions || [], [Validators.required]),
      status: new FormControl(this.data?.status || 'ACTIVE', [Validators.required]),
      totalCents: new FormControl(this.data?.totalCents / 100 || null, [
        Validators.required,
        this.minValueValidator(10),
      ]),
      type: new FormControl(this.data?.type || 'VALUE', [Validators.required]),
      redirectUrl: new FormControl(this.data?.redirectUrl || '', [Validators.pattern(reg)]),
      redirectAfterConfirmation: new FormControl(this.data?.redirectAfterConfirmation || false),
      showConfirmationPage: new FormControl(this.data?.showConfirmationPage || false),
      allowChangeQuantity: new FormControl(this.data?.allowChangeQuantity || false),
      worthlessLink: new FormControl(this.data?.worthlessLink || false),
      showRedirectUrl: new FormControl(this.data?.showRedirectUrl || false),
      insertedAt: new FormControl(this.data?.insertedAt || ''),
      updatedAt: new FormControl(this.data?.updatedAt || ''),
    });

    this.listenChanges();
    this.pageLoading = false;
  }

  private minValueValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value < minAmount;
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }

  public sortDataByName(data: Array<any>): Array<any> {
    return data.sort((x, y) => {
      const a = x.name?.toUpperCase();
      const b = y.name?.toUpperCase();

      return a === b ? 0 : a > b ? 1 : -1;
    });
  }

  public getItems(sellerId: string): void {
    this.$items.getItemList(sellerId)?.subscribe({
      next: (res) => {
        if (res?.data?.listItems) {
          const data = [...res.data.listItems];
          const items = this.sortDataByName(
            data
              .map((item) => ({ ...item, quantity: 1 }))
              .filter((item) => item.ableSmartpos || item.ableSmartpos === null)
          );

          this.listOfOption = items.map((item) => {
            return { value: item, label: item.name };
          });

          if (this.data?.item) {
            this.itemsInput = { items: this.linkForm.get('item').value, newItem: false };
          }
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public addItem(): void {
    this.isOpenSelect = false;
    this.addItemEnable = true;
    this.newItem = true;
    this.itemsInput = { items: this.linkForm.get('item').value, newItem: true };
  }

  public listItems(data: Array<ItemModel>): void {
    this.linkForm.get('item').setValue(data[0]);
  }

  public cancel(): void {
    this.addItemEnable = false;
    this.newItem = false;
    this.itemsInput = { items: this.linkForm.get('item').value, newItem: false };
  }

  public AddnewItem(data: ItemsModel): void {
    this.newItem = false;
    this.addItemEnable = false;
    this.listOfOption.push({ label: data.name, value: data });
    this.linkForm.get('item').setValue(data);
  }

  public compareFn(o1: any, o2: any): boolean {
    if (o1 && o2) {
      if (o1.id === o2.id) {
        return true;
      }

      if (o1.description === o2.name) {
        return true;
      }

      if (o1.name === o2.name) {
        return true;
      }

      return false;
    }
    return false;
  }

  public setHandleSubmit(): void {
    if (this.linkForm.valid) {
      const payload: LinksModel = Object.assign({}, this.linkForm.value);

      const expirationDate = this.linkForm.value.expirationDate
        ? moment(this.linkForm.value.expirationDate).format('YYYY-MM-DDTHH:mm:ss')
        : moment().format('YYYY-MM-DDTHH:mm:ss');

      payload.expirationDate = expirationDate;

      if (this.data?.id) {
        payload.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss');
      } else {
        payload.insertedAt = moment().format('YYYY-MM-DDTHH:mm:ss');
      }

      let item: ItemModel = {} as ItemModel;

      if (this.linkForm.get('type')?.value === 'ITEM') {
        const i = this.linkForm.get('item').value;

        item = {
          description: i.name || i.description,
          itemId: i.id || i.itemId,
          quantity: i.quantity,
          unitPriceCents: i.amountCents || i.unitPriceCents,
        };

        payload.item = item;
        payload.itemId = item.id || item.itemId;
        payload.worthlessLink = false;
        payload.totalCents = item.quantity * item.unitPriceCents;
      } else {
        payload.item = null;
        payload.itemId = null;
        payload.worthlessLink = payload.totalCents === null ? false : payload.worthlessLink;
        payload.totalCents = payload.worthlessLink === false ? payload.totalCents : 1000;

        item = {
          description: 'Pagamento avulso',
          quantity: 1,
          unitPriceCents: payload.totalCents * 100,
        };
      }

      this.loading = true;

      let data: Partial<OrderModel> = {
        id: payload.orderId,
        methods: payload.paymentOptions,
        items: [item],
        sellerId: this.sellerId,
        expirationDate: expirationDate,
        type: 'ORDER',
        channel: 'IN_STORE',
      };

      if (!this.data.id) {
        this.createLinkOrder(data, payload);
      } else {
        this.updateLinkOrder(data, payload);
      }
    } else {
      this.linkForm.markAsDirty();
      this.linkForm.markAllAsTouched();
    }
  }

  public createLinkOrder(data: Partial<OrderModel>, payload: LinksModel): void {
    this.$order.createOrder(data).subscribe({
      next: (result) => {
        if (result.status === 201) {
          payload.orderId = result.body.id;
          this.loading = false;
          payload.totalCents = result.body.totalCents;

          this.$drawerRef.close(payload);
        } else {
          this.$message.error('Erro na criação do link, tente novamente');
          this.loading = false;
        }
      },
      error: (error) => {
        this.$message.error('Erro na criação do link, tente novamente');
        this.linkForm.markAsDirty();
        this.linkForm.markAllAsTouched();
        this.linkForm.setErrors({ invalid: true });
      },
    });
  }

  public updateLinkOrder(data: Partial<OrderModel>, payload: LinksModel): void {
    this.$order.updateOrder(data).subscribe({
      next: (result) => {
        if (result.status === 200) {
          payload.totalCents = result.body.totalCents;

          this.loading = false;
          this.$drawerRef.close(payload);
        } else {
          this.$message.error('Erro na edição do link, tente novamente');
          this.loading = false;
        }
      },
      error: (error) => {
        this.$message.error('Erro na atualização do link, tente novamente');
        this.linkForm.markAsDirty();
        this.linkForm.markAllAsTouched();
        this.linkForm.setErrors({ invalid: true });
      },
    });
  }
}
