import { gql } from 'apollo-angular';

const LIST_PIX_KEYS = gql`
  query ListPixKeys($sellerId: UUID4!) {
    listPixKeys(sellerId: $sellerId) {
      key
      keyType
    }
  }
`;

const LIST_PIX_PAYMENTS = gql`
  query ListSellerPayments($sellerId: UUID4!) {
    listSellerPayments(sellerId: $sellerId, filters: { method: [PIX] }) {
      id
      status
      totalCents
      updatedAt
      acquirerPayment
      pix {
        acquirerTransactionId
        brcode
        expiration
      }
      bankSlip {
        bank
      }
      publicCustomer {
        name
        cpf
      }
      order {
        id
      }
    }
  }
`;

const GET_PIX_PAYMENT = gql`
  query GetPayment($paymentId: UUID4!) {
    getPayment(id: $paymentId) {
      id
      totalCents
      method
      updatedAt
      acquirerPayment
      pix {
        acquirerTransactionId
        brcode
        expiration
      }
      publicCustomer {
        name
        cpf
      }
      bankSlip {
        bank
      }
    }
  }
`;

export { LIST_PIX_KEYS, LIST_PIX_PAYMENTS, GET_PIX_PAYMENT };
