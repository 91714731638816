import { PointOfSalesService } from './../point-of-sales/point-of-sales.service';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '@gen/environments';
import { ContactModel } from '../../models/contact/contact.model';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  public marketplaceId: string = environment.marketplaceId;

  constructor(private readonly fireStore: AngularFirestore, public $point: PointOfSalesService) {}

  public getContactList(sellerId: string): Observable<ContactModel[]> | undefined {
    return this._collection(this.marketplaceId, sellerId)?.valueChanges();
  }

  public getContactDetailById(sellerId: string, id: string): Observable<ContactModel> {
    return this._collection(this.marketplaceId, sellerId).doc(id).valueChanges();
  }

  public getContactDetail(sellerId: string, id: string): Observable<ContactModel> | undefined {
    return this._collection(this.marketplaceId, sellerId)?.doc(id).valueChanges();
  }

  public getContactDetailByCustomerId(
    marketplaceId: string,
    sellerId: string,
    customerId: string
  ): Observable<ContactModel[]> | undefined {
    return this.fireStore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/contacts`, (ref) =>
        ref.where('customerId', '==', customerId)
      )
      .valueChanges() as Observable<ContactModel[]>;
  }

  public async addNewContact(sellerId: string, pointId: string, data: ContactModel): Promise<any> {
    const contactId = uuidv4();
    data.id = contactId;

    this.$point.updatePoint(pointId, { contactId: contactId, customerPhone: data.phone });

    return this._collection(this.marketplaceId, sellerId)?.doc(data.id).set(data);
  }

  public async updateContact(sellerId: string, data: ContactModel): Promise<any> {
    return this._collection(this.marketplaceId, sellerId)?.doc(data.id).update(data);
  }

  public deleteContact(sellerId: string, contact: ContactModel): Promise<void> {
    return this._collection(this.marketplaceId, sellerId)?.doc(contact.id).delete();
  }

  public getContactByCPF(sellerId: string, cpf: string): Observable<ContactModel[]> | undefined {
    return this.fireStore
      .collection(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/contacts`, (ref) =>
        ref.where('cpf', '==', cpf)
      )
      .valueChanges() as Observable<ContactModel[]>;
  }

  public getContactByCNPJ(
    marketplaceId: string,
    sellerId: string,
    cnpj: string
  ): Observable<ContactModel[]> | undefined {
    return this.fireStore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/contacts`, (ref) => ref.where('cnpj', '==', cnpj))
      .valueChanges() as Observable<ContactModel[]>;
  }

  public getContactsWithFilters(sellerId: string, filter: ResponseFilterModel): Observable<ContactModel[]> {
    return this._collection(this.marketplaceId, sellerId, filter).valueChanges();
  }

  private _collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): AngularFirestoreCollection<ContactModel> {
    if (filter && !isEmpty(filter)) {
      return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/contacts`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate = moment(filter.creationDateFilter.date).set({ hour: 0, minute: 0, second: 0 }).format();
            const endDate = moment(filter.creationDateFilter.date).set({ hour: 23, minute: 59, second: 59 }).format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }
        }

        if (filter.documentFilter) {
          let document = filter.documentFilter;
          if (document.length === 11) {
            query = query.where('cpf', '==', filter.documentFilter);
          } else {
            query = query.where('cnpj', '==', filter.documentFilter);
          }
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/contacts`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }
}
