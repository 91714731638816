import { gql } from 'apollo-angular';

const LIST_USERS_QUERY = gql`
  query ListUsers($email: String, $name: String, $insertedAt: PeriodFilterParams) {
    listUsers(
      pagination: { sortingOrder: DESC, orderBy: "inserted_at" }
      filters: {
        email: $email
        name: $name
        insertedAt: $insertedAt
        role: [ADMIN, COMPLIANCE, CUSTOMER_EXPERIENCE, FINANCE, MARKETING]
      }
    ) {
      id
      cpf
      role
      name
      email
      phone
      active
      externalId
      insertedAt
    }
  }
`;

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      cpf
      name
      role
      email
      phone
      externalId
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: UUID4!) {
    getUser(id: $id) {
      id
      cpf
      role
      name
      email
      phone
      active
      externalId
      insertedAt
    }
  }
`;

export { LIST_USERS_QUERY, CURRENT_USER_QUERY, GET_USER };
