import { gql } from 'apollo-angular';

const LIST_ORDERS_QUERY = gql`
  query ListOrders($filter: FilterOrdersParams) {
    listOrders(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC }) {
      id
      status
      type
      customer {
        name
        cpf
        cnpj
      }
      items {
        description
      }
      insertedAt
      customerData
      totalCents
    }
  }
`;

const GET_ORDER_QUERY = gql`
  query getOrder($id: UUID4!) {
    getOrder(id: $id) {
      id
      status
      type
      customer {
        name
        cpf
        cnpj
      }
      items {
        description
      }
      seller {
        company {
          businessName
        }
      }
      method
      insertedAt
      totalCents
      subtotalCents
      shippingCents
      discountCents
      additionCents
      feeAmountCents
      payments {
        method
        confirmedAt
        totalCents
      }
      items {
        id
        quantity
        unitPriceCents
        description
      }
    }
  }
`;

export { LIST_ORDERS_QUERY, GET_ORDER_QUERY };
