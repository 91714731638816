import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { environment } from '@gen/environments';
import { SystemService } from '../system/system.service';
import { MallModel } from '../../models/mall/mall.model';
import { TicketService } from '../ticket/ticket.service';
import { TicketModel } from '../../models/ticket/ticket.model';
import { ResponseModel } from '../../models/response/response.model';
import { ParkingUserModel } from '../../models/parking-user/parking-user';
import { StripeCustomerModel } from '../../models/stripe/stripe-customer.model';
import { SaveReceiptParamsModel } from '../../models/receipt/save-receipt-params.model';
import { PaymentTicketResponseModel } from '../../models/ticket/paymentTicketResponse.model';

declare const Stripe: any;

@Injectable()
export class StripeService {
  public paymentRequest!: any;
  private stripe!: any;

  constructor(
    private $system: SystemService,
    private $ticket: TicketService,
    private readonly router: Router,
    private readonly $message: NzMessageService,
    private readonly $functions: AngularFireFunctions
  ) {}

  public setStripeConfig(ticket: TicketModel, mall: MallModel): void {
    this.stripe = Stripe(environment.stripePublicableToken, {
      apiVersion: '2022-11-15',
      stripeAccount: mall.stripeConnectId,
    });

    this.paymentRequest = this.stripe.paymentRequest({
      country: 'BR',
      currency: 'brl',
      total: {
        label: `Pagamento do tíquete ${ticket.ticket} no shopping ${mall.name}`,
        amount: Number(ticket.analise?.valorDevido) * 100,
      },
      requestPayerName: true,
      disableWallets: ['link'],
    });
  }

  public listenPaymentRequest(user: ParkingUserModel, ticket: TicketModel, mall: MallModel): void {
    this.paymentRequest.on('paymentmethod', (ev: any) => {
      this.$ticket
        .payTicket({
          transaction: ticket.transacao,
          paymentMethod: ev.paymentMethod.id,
          customer: user.customerId,
          metadata: { seller_id: mall.externalId },
        })
        .subscribe({
          next: (res) => {
            if (res.status === 200) {
              ev.complete('success');
              this.successPaymentFlow(res.body, user, ticket, mall);
            } else {
              ev.complete('fail');
              this.$message.error('Erro ao efetuar o pagamento.');

              if (res.status === 422) {
                if (
                  res.body?.error?.retorno_mensagem === 'server_error' ||
                  res.body?.error?.retorno_mensagem === 'nao autorizada [ecom - 51]'
                ) {
                  this.router.navigate([`external/${mall.id}/serverError/${ticket.ticket}`]);
                } else {
                  this.$system.openGenericModal(
                    '../../../../assets/svg/estacionamento_12_1_1.svg',
                    'Pagamento não aprovado',
                    'Escolha outro método de pagamento e tente novamente.',
                    'Escolher método de pagamento'
                  );
                }

                throw new Error(JSON.stringify(res.body));
              }

              if (res.status === 500 || res.status === 502) {
                this.router.navigate([`external/${mall.id}/serverError/${ticket.ticket}`]);
                throw new Error(JSON.stringify(res.body));
              }
            }
          },
          error: (error) => {
            ev.complete('fail');
            this.$system.openGenericModal(
              '../../../../assets/svg/estacionamento_12_1_1.svg',
              'Erro ao efetuar o pagamento.',
              'Tente novamente mais tarde.',
              'Tentar novamente'
            );
            this.$message.error('Erro ao criar um pagamento. Por favor, tente novamente mais tarde.');
            throw new Error(error);
          },
        });
    });
  }

  public createOrUpdateStripeCustomer(customer: StripeCustomerModel): Observable<ResponseModel<StripeCustomerModel>> {
    if (customer.customerId) {
      return this.$functions.httpsCallable('updateStripeCustomer')(customer);
    }

    delete customer.customerId;
    return this.$functions.httpsCallable('createStripeCustomer')(customer);
  }

  private successPaymentFlow(
    body: PaymentTicketResponseModel,
    user: ParkingUserModel,
    ticket: TicketModel,
    mall: MallModel
  ): void {
    this.$message.success('Sucesso ao realizar o pagamento.');

    const params: SaveReceiptParamsModel = {
      user,
      res: body,
      ticket,
      mall,
    };
    this.$ticket.saveReceipt(params);

    setTimeout(() => {
      this.router.navigate([`external/${mall.id}/approved/${ticket.ticket}`]);
    }, 500);
  }
}
