import { gql } from 'apollo-angular';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $active: Boolean
    $cpf: String
    $email: String
    $name: String
    $password: String
    $role: RoleEnum
    $phone: String
  ) {
    createUser(
      params: {
        active: $active
        cpf: $cpf
        email: $email
        name: $name
        password: $password
        role: $role
        phone: $phone
      }
    ) {
      id
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: UUID4!
    $active: Boolean
    $cpf: String
    $email: String
    $name: String
    $password: String
    $role: RoleEnum
    $phone: String
  ) {
    updateUser(
      id: $id
      params: {
        active: $active
        cpf: $cpf
        email: $email
        name: $name
        password: $password
        role: $role
        phone: $phone
      }
    ) {
      id
    }
  }
`;

const CHANGE_USER_ROLE_MUTATION = gql`
  mutation ChangeUserRole($id: UUID4!, $role: RoleEnum!) {
    updateUserRole(id: $id, role: $role) {
      id
    }
  }
`;

export { CREATE_USER_MUTATION, UPDATE_USER_MUTATION, CHANGE_USER_ROLE_MUTATION };
