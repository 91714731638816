import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@gen/environments';
import { ParkingUserModel } from '../../models/parking-user/parking-user';
import { UserParkingModel } from '../../models/parking/user-parking-model';
import { OperacionalParkingModel } from '../../models/parking/operacional-parking.model';

@Injectable()
export class ParkingService {
  constructor(private http: HttpClient, private afStore: AngularFirestore) { }

  public getUser(id: string): Observable<ParkingUserModel> | undefined {
    return this.afStore.doc(`parkingUsers/${id}`).valueChanges() as Observable<ParkingUserModel>;
  }

  public setUser(data: ParkingUserModel): void {
    this.afStore.doc(`parkingUsers/${data.id}`).set(data, { merge: true });
  }

  public getUserByCpf(cpf: string): Observable<ParkingUserModel[]> | undefined {
    return this.afStore.collection(`parkingUsers`, (ref) => ref.where('cpf', '==', cpf)).valueChanges() as Observable<
      ParkingUserModel[]
    >;
  }

  public getTicketOfTransactions(data: {
    initDate: string;
    endDate: string;
  }): Observable<Array<OperacionalParkingModel>> {
    const parkingToken = sessionStorage.getItem('parkingToken');

    const params = new HttpParams().append('startDate', data.initDate).append('page', 1).append('perPage', 100);
    const headers = new HttpHeaders().append('Authorization', `Bearer ${parkingToken}`);

    return this.http
      .request<Array<OperacionalParkingModel>>('GET', `${environment.parkingApi}/ticket/transactions`, {
        params,
        headers,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public createUserParking(body: UserParkingModel): Observable<Partial<UserParkingModel>> {
    return this.http.request<Partial<UserParkingModel>>('POST', `${environment.parkingApi}/user`, { body }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public createSessionParking(body: { email: string; password: string }): Observable<any> {
    return this.http.request<any>('POST', `${environment.parkingApi}/sessions`, { body }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getUserLatestReceipt(id: string): Observable<any> | undefined {
    const receiptCollectionRef = this.afStore.collection(`parkingUsers/${id}/receipts`, ref => ref.orderBy('createdAt', 'desc').limit(1));
    return receiptCollectionRef.valueChanges();
  }
}
