import { SellerModel } from '../sellers/sellers.model';
import { ChannelMethods } from '../../types/channel-methods.type';
import { PaymentMethods } from '../../types/payment-methods.type';
import { CustomerModel } from '../customer/customer.model';
import { DeliveryMethods } from '../../types/delivery-methods.type';
import { ContactModel } from '../contact/contact.model';
import { ItemModel } from '../items/item.model';
import { DeliveryStatus } from '../../types/delivery-status.type';

export class OrderModel {
  public id?: string;
  public marketplaceId?: string;
  public customerId?: string;
  public method?: string;
  public methods?: PaymentMethods[];
  public observation?: string;
  public contact?: ContactModel;
  public status: string;
  public customerData?: CustomerModel;
  public externalUserId?: string;
  public sellerId: string;
  public deliveryStatus?: DeliveryStatus;
  public store?: string;
  public items?: Array<ItemModel>;
  public subtotalCents: number;
  public totalCents: number;
  public discountCents: number;
  public additionCents: number;
  public maxInstallments?: number;
  public type?: string;
  public insertedAt: string;
  public updatedAt: string;
  public subscriptionId?: string;
  public expirationDate?: string;
  public userExternalId?: string;
  public haveLink?: boolean;
  public deliveryMethod?: DeliveryMethods;
  public channel?: ChannelMethods;
  public seller?: SellerModel;
  public loading?: boolean;
  public customer?: CustomerModel;
  public shippingCents?: number;
  public payments?: any;
}
