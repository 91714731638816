import { Component, Input } from '@angular/core';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-error-payment',
  templateUrl: './error-payment.component.html',
  styleUrls: ['./error-payment.component.scss'],
})
export class ErrorPaymentComponent {
  @Input() reason: string = '';
  @Input() typePage: string;

  constructor(private readonly $notification: StateManagementService) {}

  public back(): void {
    this.$notification.setTypeResult('ACTIVE');
  }
}
