export class MallModel {
  public id: string;
  public name: string;
  public latitude: string;
  public longitude: string;
  public qrCode: string;
  public logo: { path: string; url: string };
  public email: string;
  public address: string = '';
  public File: {
    url: string;
  };
  public Address: {
    City: {
      Uf: {
        name: string;
        shortName: string;
      };
      name: string;
    };
    neighborhood: string;
    street: string;
    number: string | number;
    zip?: string;
  };
  public mallPhoto: {
    url: string;
  };
  public stripeConnectId: string = '';
  public externalId: string = '';
}
