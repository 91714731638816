import { ItemsModel } from '../../models/items/items.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ItemsService } from '../../services/products/items/items.service';

@Component({
  selector: 'app-product-card-basket',
  templateUrl: './product-card-basket.component.html',
  styleUrls: ['./product-card-basket.component.scss'],
})
export class ProductCardBasketComponent {
  @Input() product: ItemsModel;
  @Input() loading: boolean;

  @Output() public updateQuantity = new EventEmitter();

  public standardImg: string = 'https://www.surfdome.ie/images/shared/pdp-placeholder.png';

  constructor(private $items: ItemsService) {}

  public hexFromColorName(colorName: string): string {
    return this.$items.hexFromColorName(colorName);
  }

  public emitQuantity(quantity: any): void {
    this.updateQuantity.emit(quantity);
  }

  public removeProduct(): void {
    this.updateQuantity.emit(0);
  }
}
