import { Observable } from 'rxjs';
import { Apollo, Mutation } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { BankModel } from '../../models/bank/bank.model';
import { SellerModel } from '../../models/sellers/sellers.model';
import { BankDataModel } from '../../models/bank/bank-data.model';
import { BankAccountModel } from '../../models/bank-account/bank-account.model';
import { CREATE_BANK_ACCOUNT_MUTATION } from '../../graphql/mutations/bank-account.mutations';


@Injectable()
export class BankService {
  constructor(private readonly apollo: Apollo, private readonly firestore: AngularFirestore) {}

  public createBankAccount(variables: BankDataModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BANK_ACCOUNT_MUTATION,
      variables,
    });
  }

  private _accountCollection(marketplaceId: string, sellerId: string): AngularFirestoreCollection<BankAccountModel> {
    return this.firestore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/bankAccounts`);
  }

  public getAccounts(marketplaceId: string, sellerId: string): Observable<BankAccountModel[]> {
    return this._accountCollection(marketplaceId, sellerId).valueChanges();
  }

  public getSeller(marketplaceId: string, sellerId: string): Observable<SellerModel> | undefined {
    return this.firestore
      .doc(`marketplaces/${marketplaceId}/sellers/${sellerId}`)
      .valueChanges() as Observable<SellerModel>;
  }

  public getBanks(): Observable<BankModel[]> {
    return this.firestore.collection('banks').valueChanges() as Observable<BankModel[]>;
  }
}
