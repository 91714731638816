import { Component, Input } from '@angular/core';
import { PaymentInfo } from '../../../models/payments/payment-info.model';

@Component({
  selector: 'app-bank-slip',
  templateUrl: './bank-slip.component.html',
  styleUrls: ['./bank-slip.component.scss'],
})
export class BankSlipComponent {
  @Input() paymentInfo: PaymentInfo;

  public goToRedirectTo(): void {
    window.open(this.paymentInfo.redirectTo, '_self');
  }

  public downloadBankSLip(): void {
    window.open(this.paymentInfo.payment.bankSlip?.printLinkPdf, '_blank');
  }
}
