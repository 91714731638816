import { DateFilterModel } from './date-filter.model';
import { PaymentMethods } from '../../types/payment-methods.type';

export class FilterDataModel {
  public selectedCreationDate?: DateFilterModel = new DateFilterModel();
  public selectedFromAmount?: number = null;
  public selectedToAmount?: number = null;
  public selectedStatus?: string[];
  public selectedPaymentMethod?: PaymentMethods[] = [];
  public selectedName?: string = '';
  public selectedCategory?: string[] = [];
  public selectedEmail?: string = '';
  public selectedDocument?: string = '';
  public selectedSchedule?: string[] = [];
  public selectedExpirationDate?: DateFilterModel = new DateFilterModel();
  public selectedBrand?: string = '';
}
