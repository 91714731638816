import { gql } from 'apollo-angular';

const CREATE_ITEM_OPTION_MUTATION = gql`
  mutation CreateItemOption(
    $name: String!
    $sellerId: UUID4!
    $maxQuantity: Int
    $required: Boolean
    $displayName: String!
    $types: [CreateNestedItemTypeParams!]
  ) {
    createItemOption(
      params: {
        name: $name
        types: $types
        sellerId: $sellerId
        required: $required
        maxQuantity: $maxQuantity
        displayName: $displayName
      }
    ) {
      id
    }
  }
`;

const UPDATE_ITEM_OPTION_MUTATION = gql`
  mutation UpdateItemOption(
    $id: UUID4!
    $name: String
    $maxQuantity: Int
    $required: Boolean
    $displayName: String
    $types: [CreateNestedItemTypeParams!]
  ) {
    updateItemOption(
      id: $id
      params: { name: $name, displayName: $displayName, maxQuantity: $maxQuantity, required: $required, types: $types }
    ) {
      id
    }
  }
`;

const DELETE_ITEM_OPTION_MUTATION = gql`
  mutation DeleteItemOption($id: UUID4!) {
    deleteItemOption(id: $id) {
      deletedAt
    }
  }
`;

export { CREATE_ITEM_OPTION_MUTATION, UPDATE_ITEM_OPTION_MUTATION, DELETE_ITEM_OPTION_MUTATION };
