import { Component } from '@angular/core';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss'],
})
export class SuccessPaymentComponent {
  constructor(private readonly $notification: StateManagementService) {}

  public goToReceipt(): void {
    this.$notification.setTypeResult('RECEIPT');
  }
}
