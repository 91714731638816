import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnInit, Output } from '@angular/core';

import { ModalCreateContactComponent } from '../modal-create-contact/modal-create-contact.component';
import { SubscriptionDetailsComponent } from '../invoices/subscriptions/subscription-details/subscription-details.component';
import { OrdersService } from '../../../services/orders/orders.service';
import { PaymentsService } from '../../../services/payments/payments.service';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { SubscriptionsService } from '../../../services/subscriptions/subscriptions.service';
import { ContactModel } from '../../../models/contact/contact.model';
import { TableHeaderModel } from '../../../models/table/table-header.model';
import { OrderModel } from '../../../models/orders/order.model';
import { SubscriptionModel } from '../../../models/subscriptions/subscriptions.model';
import { PaymentsModel } from '../../../models/payments/payments.model';
import { TableSettingsModel } from '../../../models/table/table-settings.model';
import { AddressModel } from '../../../models/address/address.model';
import { Message } from '../../../utils/message';
import { DrawerSimpleDetailsComponent } from '../invoices/simple/drawer-simple-details/drawer-simple-details.component';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
  @Input() value: ContactModel;
  @Output() modalDestroyer: any;

  public listOfHeadersColumnInvoices: TableHeaderModel[] = [
    { name: 'ID da fatura' },
    { name: 'Vencimento' },
    { name: 'Valor' },
    { name: 'Status' },
  ];
  public listOfHeadersColumnSubscription: TableHeaderModel[] = [
    { name: 'ID da fatura' },
    { name: 'Início' },
    { name: 'Fim da recorrência' },
    { name: 'Valor' },
    {
      name: 'Status',
    },
  ];
  public listOfHeadersColumnPayments: TableHeaderModel[] = [
    { name: 'Nº do pagamento' },
    { name: 'Criado' },
    { name: 'Valor' },
    { name: 'Status' },
  ];
  public listOfDataInvoices: OrderModel[] = [];
  public listOfDataSubscription: SubscriptionModel[] = [];
  public listOfDataPayment: PaymentsModel[] = [];
  public settingValue: TableSettingsModel = {
    checkbox: false,
    pagination: true,
    footer: false,
    expandable: false,
  };

  public totalPayments: number = 0;
  public totalSubscription: number = 0;
  public sellerId: string = '';
  public marketplaceId: string = '';
  public contact: ContactModel = new ContactModel();
  public contactResult: ContactModel = new ContactModel();

  constructor(
    private $ordersService: OrdersService,
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private $paymentsService: PaymentsService,
    private readonly $contacts: ContactsService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService,
    private $subscriptionService: SubscriptionsService
  ) {}

  public ngOnInit(): void {
    this.getUser();
    this.getInvoices();
    this.getSubscriptions();
    this.getPayments();
  }

  public getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.sellerId = res.sellerId;
        this.marketplaceId = res.marketplaceId;
      }
    });
  }

  public getInvoices(): void {
    if (this.value.customerId) {
      this.$ordersService
        .getOrderListWithCustomer(this.value.customerId, this.marketplaceId, this.sellerId)
        ?.subscribe((orders) => {
          this.listOfDataInvoices = orders;
        });
    }
  }

  public getSubscriptions(): void {
    if (this.value.customerId) {
      this.$subscriptionService
        .getSubscriptionListWithCustomer(this.value.customerId, this.marketplaceId, this.sellerId)
        ?.subscribe((subscriptions) => {
          this.listOfDataSubscription = subscriptions;
          this.totalSubscription = this.getTotalSubscriptions(subscriptions);
        });
    }
  }

  public getPayments(): void {
    if (this.value.customerId) {
      this.$paymentsService.getPaymentListWithCustomer(this.value.customerId, this.sellerId)?.subscribe((payments) => {
        if (payments) {
          this.listOfDataPayment = payments;
          this.totalPayments = payments.reduce((acc, payment) => (acc += Number(payment.totalCents / 100)), 0);
        }
      });
    }
  }

  private getTotalSubscriptions(subscriptions: SubscriptionModel[]): number {
    return subscriptions.reduce((acc, subscription) => {
      if (subscription.status === 'active' && subscription.totalCents) {
        acc += Number(subscription.totalCents / 100);
      }

      return acc;
    }, 0);
  }

  public editContact(value: any): void {
    if (value.kindOfPerson === 'PERSONAL') {
      const address: AddressModel = {
        postalCode: value.zipCodePersonal,
        city: value.cityPersonal,
        state: value.statePersonal,
        neighborhood: value.neighborhoodPersonal,
        line1: value.streetPersonal,
        line2: value.numberPersonal,
        line3: value.complementPersonal,
      };

      const contact: ContactModel = {
        id: this.value.id,
        cpf: value.personalDocument,
        name: value.name,
        email: value.personalEmail,
        phone: value.personalPhone,
        address: address,
      };

      this.contactResult = contact;
    } else {
      const addressBusiness: AddressModel = {
        postalCode: value.zipCodeBusiness,
        city: value.cityBusiness,
        state: value.stateBusiness,
        neighborhood: value.neighborhoodBusiness,
        line1: value.streetBusiness,
        line2: value.numberBusiness,
        line3: value.complementBusiness,
      };

      const addressPersonalBusiness: AddressModel = {
        postalCode: value.zipCodePersonalBusiness,
        city: value.cityPersonalBusiness,
        state: value.statePersonalBusiness,
        neighborhood: value.neighborhoodPersonalBusiness,
        line1: value.streetPersonalBusiness,
        line2: value.numberPersonalBusiness,
        line3: value.complementPersonalBusiness,
      };

      const contact: ContactModel = {
        id: this.value.id,
        cnpj: value.businessDocument,
        name: value.corporateName,
        phone: value.businessPhone,
        email: value.businessEmail,
        address: addressBusiness,
        representative: {
          name: value.name,
          cpf: value.personalDocument,
          address: addressPersonalBusiness,
        },
      };

      this.contactResult = contact;
    }

    this.$contacts.updateContact(this.sellerId, this.contactResult).then(
      () => {
        this.$message.success(Message.CONTACT_UPDATED);
      },
      (error) => {
        this.$message.error(Message.ERROR_UPDATED_CONTACT);
      }
    );
  }

  public deleteContact(): void {
    this.$modal.confirm({
      nzTitle: 'Excluir Contato',
      nzContent: `Tem certeza que deseja excluir o contato <strong>${this.value.name}</strong>?`,
      nzWidth: '500px',
      nzStyle: { minHeight: 'auto' },
      nzOkText: 'Sim',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.contact = this.value;
        this.modalDestroyer = this.$contacts.deleteContact(this.sellerId, this.contact).then(
          () => {
            this.$message.success(Message.CONTACT_DELETED);
          },
          (error) => {
            this.$message.error(Message.ERROR_DELETED_CONTACT);
          }
        );
      },
      nzCancelText: 'Não',
      nzOnCancel: () => '',
    });
  }

  public showEditContactModal(value: ContactModel): void {
    this.$drawer
      .create({
        nzContentParams: { value },
        nzTitle: 'Editar Contato',
        nzWidth: '500px',
        nzContent: ModalCreateContactComponent,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          if (res.personalForm.kindOfPerson === 'PERSONAL') {
            this.editContact(res.personalForm);
          } else {
            this.editContact(res.businessForm);
          }
        }
      });
  }

  public redirectSimpleDetailsModal(event: Event): void {
    const param: any = { id: event };
    this.$modal.create({
      nzContent: DrawerSimpleDetailsComponent,
      nzComponentParams: param.id,
      nzWidth: '700px',
      nzStyle: { position: 'absolute', top: 0, right: 0, height: '100vh' },
      nzClassName: 'defaultModal', // TO use min-height: 100vh
      nzFooter: null,
      nzClosable: false,
    });
  }

  public redirectSubscriptionDetailsModal(event: any): void {
    const param: any = { id: event };
    this.$modal.create({
      nzContent: SubscriptionDetailsComponent,
      nzComponentParams: param.id,
      nzWidth: '700px',
      nzStyle: { position: 'absolute', top: 0, right: 0, height: '100vh' },
      nzClassName: 'defaultModal', // TO use min-height: 100vh
      nzFooter: null,
      nzClosable: false,
    });
  }
}
