import { gql } from 'apollo-angular';

export const CUSTOMER_FIELDS = `
  id
  phone
  email
  name
  cpf
  cnpj
  status
  limits {
    action {
      name
    }
    functionality {
      name
    }
    category {
      name
    }
    amount
    perPeriod {
      name
    }
  }
  birthDate
  nameMother
  address {
    line1
    line2
    line3
    postalCode
    neighborhood
    city
    state
    countryCode
  }
  documents {
    bucket
    id
    name
    url
    path
    reason
    insertedAt
    updatedAt
    status
  }
  user {
    role
    insertedAt
  }
  insertedAt
`;

const LIST_CUSTOMERS_QUERY = gql`
  query ListCustomers($filter: FilterCustomersParams) {
    listCustomers(
      filters: $filter,
      pagination: {
      orderBy: "inserted_at",
      sortingOrder: DESC
    }) {
      ${CUSTOMER_FIELDS}
    }
  }
`;

const GET_CUSTOMER_QUERY = gql`
  query getCustomerDetails($id: UUID4!) {
    getCustomer(id: $id) {
      ${CUSTOMER_FIELDS}
    }
  }
`;

export { LIST_CUSTOMERS_QUERY, GET_CUSTOMER_QUERY };
