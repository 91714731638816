import { ItemModel } from '../items/item.model';
import { PlansModel } from '../plans/plans.model';
import { SellerModel } from './../sellers/sellers.model';
import { ContactModel } from './../contact/contact.model';
import { CustomerModel } from '../customer/customer.model';
import { PaymentsModel } from './../payments/payments.model';

export class SubscriptionModel {
  public id?: string;
  public price?: string;
  public method: string;
  public sellerId?: string;
  public planId?: string;
  public contactId?: string;
  public customerId?: string;
  public sessionId?: string;
  public priceId?: string;
  public lastCharge?: string;
  public schedulePlans: string;
  public startDate: string;
  public endDate?: string;
  public observation?: string;
  public status?: string;
  public payments?: Array<PaymentsModel>;
  public contact?: ContactModel;
  public methods?: string[];
  public items: Array<ItemModel>;
  public lastPayment?: PaymentsModel;
  public totalCents: number;
  public insertedAt?: string;
  public updatedAt?: string;
  public seller?: SellerModel;
  public customer?: CustomerModel;
  public plan?: PlansModel;
  public translateSchedulePlans?: string;
}
