import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { LIST_DIGITAL_STATEMENT_QUERY, LIST_EXTERNAL_STATEMENT_QUERY } from '../../graphql/queries/balance.queries';

@Injectable()
export class BalanceService {
  constructor(
    private readonly apollo: Apollo,
    private readonly fireStore: AngularFirestore,
    private readonly fireFunctions: AngularFireFunctions
  ) {}

  public getDigitalBalance(data: { initDate: string; endDate: string; sellerId: string }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_DIGITAL_STATEMENT_QUERY,
      variables: { sellerId: data.sellerId, initDate: data.initDate, endDate: data.endDate },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getExternalBalance(data: { initDate: string; endDate: string; sellerId: string }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_EXTERNAL_STATEMENT_QUERY,
      variables: { sellerId: data.sellerId, initDate: data.initDate, endDate: data.endDate },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public setWithdraw(data: any): Observable<any> {
    return this.fireFunctions.httpsCallable('withdrawBalance')(data);
  }

  public getWithdrawCollection(marketplaceId: string, sellerId: string): Observable<any> {
    return this.fireStore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/withdrawals`, (ref) =>
        ref.orderBy('insertedAt', 'desc')
      )
      .valueChanges();
  }
}
