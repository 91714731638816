import { Observable, Subject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Injectable, TemplateRef } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Apollo, Mutation, MutationResult } from 'apollo-angular';

import { environment } from '@gen/environments';
import { Message } from './../../utils/message';
import { PlansService } from '../plans/plans.service';
import { LinksModel } from './../../models/links/links.model';
import { DocumentModel } from '../../models/document/document.model';
import { PaymentLinksService } from '../payment-links/payment-links.service';
import { RequestFilterModel } from '../../models/filters/request-filter.model';
import { UPDATE_DOCUMENT_STATUS } from '../../graphql/mutations/shared.mutations';
import { StateManagementService } from '../../state-management/state-management.service';
import { ResponseFilterModalModel } from '../../models/filters/response-filter-modal.model';
import { ModalFilterComponent } from '../../components/modals/modal-filter/modal-filter.component';
import { PlanCreateComponent } from '../../components/drawers/plans/plan-create/plan-create.component';
import { LinkCreatesComponent } from '../../components/drawers/links/link-creates/link-creates.component';
import { LinkDetailsComponent } from '../../components/drawers/links/link-details/link-details.component';
import { PlanDetailsComponent } from './../../components/drawers/plans/plan-details/plan-details.component';
import { LimitsActionEnum, LimitsCategoryEnum, LimitsFunctionalityEnum, LimitsPeriodEnum } from '../../enums/limits';
import { ModalRejectMessageComponent } from '../../components/modals/modal-reject-message/modal-reject-message.component';
import { ModalFilterSynapsesComponent } from '../../components/modals/modal-filter-synapses/modal-filter-synapses.component';

@Injectable()
export class SharedMethodsService {
  constructor(
    private $plans: PlansService,
    private readonly apollo: Apollo,
    private $links: PaymentLinksService,
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public getLink(data: LinksModel): string {
    const url = environment.appDomain;

    if (data.orderId) {
      return `${url}/orders/${data.orderId}`;
    } else if (data.itemId) {
      return `${url}/items/${data.itemId}`;
    } else if (data.planId) {
      return `${url}/plans/${data.planId}`;
    } else {
      return `${url}/subscriptions/${data.subscriptionId}`;
    }
  }

  public showFilterModal(params: RequestFilterModel, isSynapses?: boolean): Subject<any> {
    const content: any = isSynapses ? ModalFilterSynapsesComponent : ModalFilterComponent;

    return this.$modal.create({
      nzTitle: 'Filtro',
      nzContent: content,
      nzComponentParams: params,
      nzWidth: '40%',
    }).afterClose;
  }

  public showDetailsDrawer(event: any, marketplaceId: string, sellerId: string, titleDrawer: TemplateRef<any>): void {
    this.$drawer.create({
      nzTitle: titleDrawer,
      nzContent: LinkDetailsComponent,
      nzContentParams: { param: { id: event.id, marketplaceId, sellerId } },
      nzBodyStyle: { position: 'absolute', top: 0, right: 0, height: '100vh', width: '100%' },
      nzWidth: '500px',
      nzFooter: null,
      nzClosable: false,
    });
  }

  public showPlansDetailsDrawer(event: any, marketplaceId: string, sellerId: string, titleDrawer: TemplateRef<any>): void {
    const param: any = { param: { id: event.id, marketplaceId, sellerId } };
    this.$drawer.create({
      nzTitle: titleDrawer,
      nzContent: PlanDetailsComponent,
      nzContentParams: param,
      nzBodyStyle: { position: 'absolute', top: 0, right: 0, height: '100vh', width: '100%' },
      nzWidth: '500px',
      nzFooter: null,
      nzClosable: false,
    });
  }

  public showCreateModal(marketplaceId: string, sellerId: string, titleDrawer: TemplateRef<any>): void {
    this.$drawer
      .create({
        nzTitle: titleDrawer,
        nzContent: LinkCreatesComponent,
        nzBodyStyle: { position: 'absolute', top: 0, right: 0, height: '100vh', width: '100%' },
        nzWidth: '500px',
        nzFooter: null,
        nzContentParams: { data: { marketplaceId, sellerId } },
        nzClosable: false,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          try {
            this.$notification.setLoading(true);

            try {
              this.$links.createNewLink(marketplaceId, sellerId, res).then(
                (result) => {
                  this.$notification.setLoading(false);
                  this.$message.success(Message.PAYMENT_LINK_CREATED);
                },
                (error) => {
                  this.$notification.setLoading(false);
                  this.$message.error(Message.ERROR_CREATED_PAYMENT_LINK);
                }
              );
            } catch (error) {
              this.$message.error(Message.ERROR_CREATED_PAYMENT_LINK);
              this.$notification.setLoading(false);
            }
          } catch (error) {
            this.$message.error(Message.ERROR_CREATED_PAYMENT_LINK);
            this.$notification.setLoading(false);
          }
        }
      });
  }

  public showPlansCreateModal(marketplaceId: string, sellerId: string, userId: string, titleDrawer: TemplateRef<any>): void {
    this.$drawer
      .create({
        nzTitle: titleDrawer,
        nzContent: PlanCreateComponent,
        nzBodyStyle: { position: 'absolute', top: 0, right: 0, height: '100vh', width: '100%' },
        nzWidth: '500px',
        nzFooter: null,
        nzContentParams: { data: { marketplaceId, sellerId } },
        nzClosable: false,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.$notification.setLoading(true);

          this.$plans.createPlan(res).subscribe({
            next: (result) => {
              if (result.status === 201) {
                if (result.body.id && res.file) {
                  this.$plans.updatePlanImageUrl(result.body, res, userId);
                }

                this.$message.success(Message.PLAN_CREATED);
              } else {
                this.$message.error(Message.ERROR_CREATED_PLAN);
              }

              this.$notification.setLoading(false);
            },
            error: (error) => {
              this.$notification.setLoading(false);
              this.$message.error(Message.ERROR_CREATED_PLAN);
              throw new Error(error);
            },
          });
        }
      });
  }

  public sortDataByName(data: Array<any>): Array<any> {
    return data.sort((x, y) => {
      const a = x.name?.toUpperCase();
      const b = y.name?.toUpperCase();

      return a === b ? 0 : a > b ? 1 : -1;
    });
  }

  public isFilterFormEmpty(filterForm: ResponseFilterModalModel): boolean {
    return (
      !filterForm.creationDate &&
      !filterForm.document &&
      !filterForm.email &&
      !filterForm.name &&
      !filterForm.storeName &&
      !filterForm.title &&
      !filterForm.sellerId &&
      !filterForm.customerId &&
      !filterForm.method &&
      !filterForm.installments &&
      !filterForm.id &&
      !filterForm.status
    );
  }

  public convertToNaiveDateTime(date: Date): string {
    if (date) {
      return date.toISOString().split('T')[0] + 'T' + date.toLocaleTimeString();
    }

    return '';
  }

  public getDataArrayFromIndexArray(idxArr: number[], arr: string[]): string[] {
    let result: string[] = [];
    idxArr?.forEach((idx: number) => {
      result.push(arr[idx]);
    });
    return result;
  }

  public getDataArrayFromEnum(idxArr: string[], arr: Object): string[] {
    let result: string[] = [];
    idxArr?.forEach((idx) => {
      result.push(arr[idx]);
    });
    return result;
  }

  public translateLimits(limit: any): any {
    if (limit.action?.name) {
      limit.action.name = LimitsActionEnum[limit.action.name];
    }

    if (limit.functionality?.name) {
      limit.functionality.name = LimitsFunctionalityEnum[limit.functionality.name];
    }

    if (limit.perPeriod?.name) {
      limit.perPeriod.name = LimitsPeriodEnum[limit.perPeriod.name];
    }

    if (limit.category?.name) {
      limit.category.name = LimitsCategoryEnum[limit.category.name];
    }

    return limit;
  }

  private updateDocument(id: string, reason: string, status: string): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_DOCUMENT_STATUS,
      variables: {
        id,
        reason,
        status,
      },
    });
  }

  public approve(document: DocumentModel): void {
    this.updateDocument(document.id, '', 'approved').subscribe({
      next: (res) => {
        if (res) {
          this.$message.success('Documento aprovado com sucesso.');
        }
      },
      error: (error) => {
        this.$message.error('Falha ao aprovar documento');
        throw new Error(error);
      },
    });
  }

  public reject(document: DocumentModel): void {
    this.$modal
      .create({
        nzContent: ModalRejectMessageComponent,
        nzTitle: 'Razão para rejeitar documento',
      })
      .afterClose.subscribe((res: any) => {
        if (res?.reason) {
          this.updateDocument(document.id, res.reason, 'rejected').subscribe({
            next: (result) => {
              if (result) {
                this.$message.success('Documento rejeitado com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error('Falha ao rejeitar documento.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public getDocumentUrl(document: DocumentModel): string {
    return `https://firebasestorage.googleapis.com/v0/b/${document?.bucket}/o/${encodeURIComponent(
      document?.path
    )}?alt=media&key=aGwsPOaYbsR63268ZMga3uUkHJ08tCKtFJVFV65IKOIAJQJRR5s9uAPQUULQ`;
  }

  public formatPhone(phone: string): string {
    if (phone?.startsWith('+55')) {
      return phone.substring(3);
    }
    return phone || null;
  }
}
