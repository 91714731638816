import moment from 'moment';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { RoleEnum } from '../../enums/roles';
import { UserModel } from '../../models/users/users.model';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';
import { PeriodFilterParams } from '../../models/users/filter-users-params.model';
import { CURRENT_USER_QUERY, GET_USER, LIST_USERS_QUERY } from '../../graphql/queries/user.queries';
import { CHANGE_USER_ROLE_MUTATION, CREATE_USER_MUTATION, UPDATE_USER_MUTATION } from '../../graphql/mutations/user.mutations';

@Injectable()
export class UsersService {
  constructor(
    private readonly apollo: Apollo,
    private readonly fireStore: AngularFirestore,
    private readonly fireFunctions: AngularFireFunctions
  ) {}

  public getUser(marketplaceId: string, sellerId: string, externalUserId: string): Observable<UserModel> {
    return this.fireStore
      ?.doc(`marketplaces/${marketplaceId}/sellers/${sellerId}/users/${externalUserId}`)
      .valueChanges() as Observable<UserModel>;
  }

  public getUserList(marketplaceId: string, sellerId: string): Observable<UserModel[]> {
    return this.fireStore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/users`)
      .valueChanges() as Observable<UserModel[]>;
  }

  public addSellerUser(data: UserModel): Observable<void> {
    return this.fireFunctions.httpsCallable('addSellerUser')(data);
  }

  public updateSellerUser(marketplaceId: string, sellerId: string, data: UserModel): Promise<void> {
    return this.fireStore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/users`)
      ?.doc(data.uid)
      .set(data, { merge: true });
  }

  public revokeSellerUser(uid: string): Observable<void> {
    return this.fireFunctions.httpsCallable('revokeSellerUser')(uid);
  }

  public getUsersWithFilters(
    marketplaceId: string,
    sellerId: string,
    filter: ResponseFilterModel
  ): Observable<UserModel[]> {
    return this._collection(marketplaceId, sellerId, filter).valueChanges();
  }

  private _collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): AngularFirestoreCollection<UserModel> {
    if (filter && !isEmpty(filter)) {
      return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/users`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate = moment(filter.creationDateFilter.date).set({ hour: 0, minute: 0, second: 0 }).format();
            const endDate = moment(filter.creationDateFilter.date).set({ hour: 23, minute: 59, second: 59 }).format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/users`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }

  public getUserListApollo(
    email: string,
    name: string,
    insertedAt: PeriodFilterParams
  ): Observable<ApolloQueryResult<UserModel>> {
    return this.apollo.watchQuery<UserModel>({
      query: LIST_USERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { email, name, insertedAt },
    }).valueChanges;
  }

  public getCurrentUser(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getUserApollo(id: string): Observable<ApolloQueryResult<UserModel>> {
    return this.apollo.watchQuery<UserModel>({
      query: GET_USER,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public createUser(
    active: boolean,
    cpf: string,
    name: string,
    email: string,
    password: string,
    role: string,
    phone: string
  ): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_USER_MUTATION,
      variables: {
        active,
        cpf,
        name,
        email,
        password,
        role,
        phone,
      },
    });
  }

  public updateUser(
    id: string,
    active: boolean,
    cpf: string,
    name: string,
    email: string,
    password: string,
    role: string,
    phone: string
  ): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_USER_MUTATION,
      variables: {
        id,
        active,
        cpf,
        name,
        email,
        password,
        role,
        phone,
      },
    });
  }

  public changeUserRole(id: string, role: RoleEnum): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CHANGE_USER_ROLE_MUTATION,
      variables: {
        id,
        role,
      },
    });
  }
}
