import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { ContactDetailsComponent } from '../../../contact-details/contact-details.component';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { ContactsService } from 'libs/shared/src/lib/services/contacts/contacts.service';
import { SubscriptionsService } from 'libs/shared/src/lib/services/subscriptions/subscriptions.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { environment } from '@gen/environments';
import { SubscriptionModel } from 'libs/shared/src/lib/models/subscriptions/subscriptions.model';
import { ContactModel } from 'libs/shared/src/lib/models/contact/contact.model';
import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { PaymentsModel } from 'libs/shared/src/lib/models/payments/payments.model';
import { TableFooterModel } from 'libs/shared/src/lib/models/table/table-footer.model';
import { FooterType } from 'libs/shared/src/lib/types/footer-type';
import { TableHeaderModel } from 'libs/shared/src/lib/models/table/table-header.model';
import { TableSettingsModel } from 'libs/shared/src/lib/models/table/table-settings.model';
import { DrawerCreateSimpleComponent } from '../../simple/drawer-create-simple/drawer-create-simple.component';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss'],
})
export class SubscriptionDetailsComponent implements OnInit {
  @Input() public id: string = '';

  public data: SubscriptionModel = new SubscriptionModel();
  public contact: ContactModel = new ContactModel();
  public listOfData: Array<ItemModel> = [];
  public listOfData2: Array<PaymentsModel> = [];
  public subscriptionRoute: string = '';
  public methods: string = '';
  public schedule: string = '';
  public loading: boolean = false;

  public footerData: TableFooterModel;
  public footerType: FooterType = 'editableTableFooter';
  public listOfHeadersColumn: TableHeaderModel[] = [
    { name: 'Descrição' },
    { name: 'QTD' },
    { name: 'Preço Unit' },
    { name: 'Valor' },
  ];
  public settingValue: TableSettingsModel = {
    pagination: false,
    footer: true,
    expandable: false,
    checkbox: false,
    footerName: this.footerType,
  };
  public listOfHeadersColumn2: TableHeaderModel[] = [
    { name: 'Valor' },
    { name: 'Forma de pagamento' },
    { name: 'Numero da fatura' },
    { name: 'Data de confirmação' },
  ];
  public settingValue2: TableSettingsModel = {
    pagination: false,
    footer: false,
    expandable: false,
    checkbox: false,
  };

  constructor(
    private $internal: InternalService,
    private $contacts: ContactsService,
    public readonly $drawerRef: NzDrawerRef,
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService,
    private $subscriptions: SubscriptionsService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.$subscriptions
          .getDetailSubscription(res.marketplaceId, res.sellerId, this.id)
          .subscribe((subscription) => {
            if (subscription) {
              this.data = subscription;
              this.subscriptionRoute = `subscriptions/${this.id}`;
              this.listOfData = subscription.items || [];
              this.listOfData2 = subscription.payments?.map((payment) => {
                payment.method = this.getMethod(payment.method);
                return payment;
              });
              this.methods = this.getMethods();
              this.schedule = this.getSchedule(this.data?.schedulePlans);
              this.footerData = new TableFooterModel();
              this.footerData.totalAmount = this.data.totalCents;

              this.$contacts
                .getContactDetailByCustomerId(res.marketplaceId, res.sellerId, this.data?.customerId)
                ?.subscribe((contact) => {
                  this.contact = contact[0];
                });
            }
          });
      }
    });
  }

  public getSchedule(schedule: string): string {
    const schedules: any = {
      daily: 'Diária',
      weekly: 'Semanal',
      biweekly: 'Quinzenal',
      monthly: 'Mensal',
      annually: 'Anual',
    };

    return schedules[schedule];
  }

  public getMethods(): string {
    const methods: any = {
      PIX: 'PIX',
      CREDIT_CARD: 'Cartão de crédito',
      BANK_SLIP: 'Boleto',
      MONEY_EXTERNAL: 'Dinheiro',
      DEBIT_EXTERNAL: 'Outros',
    };

    const result = this.data?.methods?.map((method) => methods[method]).filter((item) => item) || ['-'];

    return result.join('  •  ');
  }

  public getMethod(method: string): string {
    const methods: any = {
      PIX: 'PIX',
      CREDIT_CARD: 'Cartão de crédito',
      BANK_SLIP: 'Boleto',
      MONEY_EXTERNAL: 'Dinheiro',
      DEBIT_EXTERNAL: 'Outros',
    };

    return methods[method || ''];
  }

  public copyContent(): void {
    /* const url = getTemporaryRoute(); */
    const url = environment.appDomain;

    this.$internal.copyContent(`${url}/${this.subscriptionRoute}`);
  }

  public editSubscription(): void {
    this.$modal
      .create({
        nzContent: DrawerCreateSimpleComponent,
        nzComponentParams: { data: this.data },
        nzStyle: { position: 'absolute', top: 0, right: 0, height: '100vh' },
        nzClassName: 'defaultModal', // TO use min-height: 100vh
        nzFooter: null,
        nzClosable: false,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          if (res.id) {
            this.loading = true;

            this.$subscriptions.updateSubscription(res).subscribe({
              next: (result) => {
                if (result.status === 200) {
                  this.$message.success('Fatura atualizada com sucesso!');
                } else {
                  this.$message.error('Erro ao atualizada a fatura, tente novamente!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao atualizada a fatura, tente novamente!');
                throw new Error(error);
              },
            });
          }
        }
      });
  }

  public delete(): void {
    this.loading = true;

    this.$subscriptions.deleteSubscription(this.data?.id).subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.$message.success('Fatura inativada com sucesso!');
        } else {
          this.$message.error('Erro ao inativar a fatura, tente novamente!');
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao inativar a fatura, tente novamente!');
        throw new Error(error);
      },
    });
  }

  public openPayment(): void {
    /* const url = getTemporaryRoute(); */
    const url = environment.appDomain;

    window.open(`${url}/${this.subscriptionRoute}`, '_blank');
  }

  public showContactDetails(): void {
    this.$drawer.create({
      nzTitle: 'Detalhes do Contato',
      nzWidth: '800px',
      nzContent: ContactDetailsComponent,
      nzContentParams: {
        value: this.contact,
      },
    });
  }
}
