import { take } from 'rxjs';
import { isString } from 'lodash';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ItemModel } from '../../models/items/item.model';
import { PlansModel } from '../../models/plans/plans.model';
import { PriceModel } from '../../models/plans/price.model';
import { CouponModel } from '../../models/coupon/coupon.model';
import { CpfOrCnpjValidator } from '../../validators/cpfAndCnpj';
import { PlanDetailModel } from '../../models/plans/plan-detail';
import { PlansService } from '../../services/plans/plans.service';
import { OrdersService } from '../../services/orders/orders.service';
import { FullNameValidator } from '../../validators/fullNameValidator';
import { PhoneNumberValidator } from '../../validators/phoneValidator';
import { PaymentInfo } from '../../models/payments/payment-info.model';
import { minValueValidator } from '../../validators/minValueValidators';
import { AddressService } from '../../services/address/address.service';
import { InternalService } from '../../services/internal/internal.service';
import { CustomerService } from '../../services/customer/customer.service';
import { CreditCardModel } from '../../models/credit-card/credit-card.model';
import { CouponService } from '../../services/products/coupon/coupon.service';
import { expirationDateValidate } from '../../validators/expirationDateValidator';
import { CreditCardService } from '../../services/credit-card/credit-card.service';
import { StateManagementService } from '../../state-management/state-management.service';
import { DrawerDetailsComponent } from '../drawers/drawer-details/drawer-details.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnChanges {
  @Input() paymentInfo: Partial<PaymentInfo>;

  public validatedCoupon: boolean;
  public loading: boolean = false;
  public couponLoading: boolean = false;
  public success: boolean = false;
  public needUpdate: boolean = false;
  public formPayment: FormGroup;
  public formCostumer: FormGroup;
  public formCreditCard: FormGroup;
  public formAddress: FormGroup;
  public formBankSlip: FormGroup;
  public installments: { label: string; value: string }[];
  public planDetail: PlanDetailModel;
  public selectedPrice: PriceModel;
  public dateFormat: string = 'dd/MM/yyyy';
  public creditCardMask: string = '0000 0000 0000 0000';
  public typeView: string = 'ACTIVE';
  public errorMessage: string = '';

  constructor(
    private $plan: PlansService,
    private $order: OrdersService,
    private $coupon: CouponService,
    private $address: AddressService,
    private readonly fb: FormBuilder,
    private $methods: InternalService,
    private $customer: CustomerService,
    private $creditCard: CreditCardService,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getNotification();
  }

  public ngOnChanges(changes: any): void {
    if (this.paymentInfo && changes.itemsInput?.currentValue !== changes.itemsInput?.previusValue) {
      this.paymentInfo = changes.paymentInfo?.currentValue;
    }
  }

  public getNotification() {
    this.$notification.typeResults.subscribe((typeResult) => {
      if (typeResult) {
        this.typeView = typeResult;
      }
    });
    this.$notification.errorMesages.subscribe((errorMessage) => {
      if (errorMessage) {
        this.errorMessage = errorMessage;
      }
    });
    this.$notification.loadings.subscribe((loading) => {
      this.loading = loading;
      this.couponLoading = loading;
    });
    this.$notification.payments.subscribe((payment) => {
      if (payment?.id) {
        this.paymentInfo.payment = payment;
      }
    });
    this.$notification.orders.subscribe((order) => {
      if (order?.id) {
        this.paymentInfo.order = order;
      }
    });
    this.$notification?.locations.subscribe((location) => {
      if (location) {
        this.paymentInfo.location = location;
      }
    });
    this.$notification.activeCoupons.pipe(take(1)).subscribe((couponCode) => {
      if (couponCode) {
        this.validateCoupon(couponCode);
      }
    });
    this.$notification.selectedPrices.subscribe((price) => {
      if (price) {
        this.selectedPrice = price;
      }
    });

    this.$notification.subscriptions.subscribe((res) => {
      this.paymentInfo.prices = res;
    });

    this.$notification.planDetails.subscribe((res) => {
      this.planDetail = res;
    });
  }

  public createForm(): void {
    this.formPayment = this.fb.group({
      paymentMethod: new FormControl(this.paymentInfo?.paymentMethods[0], [Validators.required]),
      cupom: new FormControl(this.paymentInfo?.coupon?.code, [Validators.minLength(2)]),
      amountCents: new FormControl(0),
      priceId: new FormControl(''),
    });

    this.formCostumer = this.fb.group({
      id: new FormControl(this.paymentInfo?.customer?.id),
      name: new FormControl(this.paymentInfo?.customer?.name),
      email: new FormControl(this.paymentInfo?.customer?.email),
      document: new FormControl(this.paymentInfo?.customer?.cpf || this.paymentInfo?.customer?.cnpj || ''),
      phone: new FormControl(this.$customer.formatPhone(this.paymentInfo?.customer)),
    });

    this.formAddress = this.fb.group({
      postalCode: new FormControl(this.paymentInfo?.customer?.address?.postalCode),
      street: new FormControl(this.paymentInfo?.customer?.address?.line1),
      number: new FormControl(this.paymentInfo?.customer?.address?.line2),
      complement: new FormControl(this.paymentInfo?.customer?.address?.line3),
      neighborhood: new FormControl(this.paymentInfo?.customer?.address?.neighborhood),
      city: new FormControl(this.paymentInfo?.customer?.address?.city),
      state: new FormControl(this.paymentInfo?.customer?.address?.state),
    });

    this.formBankSlip = this.fb.group({
      limitDate: new FormControl(''),
    });

    this.formCreditCard = this.fb.group({
      id: new FormControl(this.paymentInfo?.creditCard?.id),
      creditCardNumber: new FormControl(''),
      installment: new FormControl('1'),
      expiration: new FormControl(''),
      securityCode: new FormControl(''),
    });

    this.getValuesChange();
    this.setInputValue();
  }

  public showDetailsDrawer(): void {
    this.$drawer.create({
      nzWidth: '400px',
      nzContent: DrawerDetailsComponent,
      nzContentParams: { paymentInfo: this.paymentInfo, priceId: this.formPayment.get('priceId').value },
      nzPlacement: 'left',
    });
  }

  public setInputValue(): void {
    if (this.paymentInfo?.order?.discountCents > 0) {
      this.formPayment.get('cupom')?.disable();
    }

    if (this.paymentInfo?.payment?.id) {
      this.formPayment.get('amountCents')?.disable();
    }

    if (this.paymentInfo?.coupon?.code?.length > 0) {
      const couponName = 'Cupom: ' + this.paymentInfo?.coupon?.code;
      const coupon: ItemModel = {
        itemId: null,
        description: couponName,
        unitPriceCents: -this.paymentInfo?.order?.discountCents,
        quantity: 1,
      };

      if (this.paymentInfo?.order?.items.filter((coupon) => coupon.description === couponName).length === 0) {
        this.paymentInfo?.order?.items.push(coupon);
      }
    }

    if (this.paymentInfo?.paymentLink?.worthlessLink) {
      this.formPayment.get('amountCents').setValidators([Validators.required, minValueValidator.isValid(10)]);
      this.formPayment.get('amountCents').updateValueAndValidity();
      this.needUpdate = true;
    }

    if (this.paymentInfo?.typePage === 'PLAN') {
      this.formPayment.get('priceId').setValidators([Validators.required]);
    }

    if (
      this.paymentInfo.typePage !== 'SUBSCRIPTION' ||
      (this.paymentInfo.typePage === 'SUBSCRIPTION' && this.paymentInfo?.customer?.id)
    ) {
      this.formCostumer.get('name').setValidators([Validators.required, FullNameValidator.isValid()]);
      this.formCostumer.get('email').setValidators([Validators.required, Validators.email]);
      this.formCostumer.get('document').setValidators([Validators.required, CpfOrCnpjValidator.isCpfOrCnpjValid()]);
      this.formCostumer.get('phone').setValidators([Validators.required, PhoneNumberValidator.isValid()]);

      this.formAddress.get('postalCode').setValidators([Validators.required]);
      this.formAddress.get('street').setValidators([Validators.required]);
      this.formAddress.get('number').setValidators([Validators.required]);
      this.formAddress.get('neighborhood').setValidators([Validators.required]);
      this.formAddress.get('city').setValidators([Validators.required]);
      this.formAddress.get('state').setValidators([Validators.required]);

      this.formCreditCard.get('installment').setValidators([Validators.required]);
    }

    if (this.paymentInfo?.typePage === 'PLAN') {
      this.selectedPriceAmount(this.paymentInfo?.plans);
    }

    this.installments = this.$methods.setMinInstallment(
      this.paymentInfo?.seller,
      this.paymentInfo?.paymentLink?.worthlessLink
        ? this.formPayment.get('amountCents').value
        : this.paymentInfo?.order?.totalCents || this.paymentInfo?.item?.amountCents * this.paymentInfo?.item?.quantity,
      this.paymentInfo?.order?.maxInstallments || this.paymentInfo?.seller?.settings?.defaultMaxInstallments || 12
    );
  }

  public getValuesChange(): void {
    this.formAddress.get('postalCode').valueChanges.subscribe((cep) => {
      if (cep.length === 8) {
        this.searchPostalCode(cep);
      }
    });

    this.formPayment.get('amountCents').valueChanges.subscribe((res) => {
      if (res && this.paymentInfo?.order?.items?.length > 0) {
        this.paymentInfo.order.items[0].unitPriceCents = res * 100;
        this.paymentInfo.paymentLink.totalCents = res * 100;
      }
    });

    if (this.paymentInfo.typePage === 'PLAN' || this.paymentInfo.typePage === 'SUBSCRIPTION') {
      if (this.formPayment.get('paymentMethod').value === 'CREDIT_CARD') {
        this.formCreditCard.get('creditCardNumber').setValidators([Validators.required]);
        this.formCreditCard.get('installment').setValidators([Validators.required]);
        this.formCreditCard.get('expiration').setValidators([Validators.required, expirationDateValidate()]);
        this.formCreditCard
          .get('securityCode')
          .setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(4)]);
      }
    }

    this.formPayment.get('paymentMethod').valueChanges.subscribe((res) => {
      if (res === 'CREDIT_CARD') {
        this.formCreditCard.get('creditCardNumber').setValidators([Validators.required]);
        this.formCreditCard.get('installment').setValidators([Validators.required]);
        this.formCreditCard.get('expiration').setValidators([Validators.required, expirationDateValidate()]);
        this.formCreditCard
          .get('securityCode')
          .setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(4)]);
      }
    });
  }

  public searchPostalCode(value: string): void {
    this.$address.getCep(value).subscribe({
      next: (res) => {
        if (res) {
          this.formAddress.patchValue({
            street: res.logradouro,
            neighborhood: res.bairro,
            city: res.localidade,
            state: res.uf,
          });
        }
      },
      error: (error) => {
        this.formAddress.get('postalCode').markAsDirty();
        this.formAddress.get('postalCode').setErrors({ invalid: true });
        this.formAddress.get('postalCode').markAsTouched();
      },
    });
  }

  public updateQuantity(event: any): void {
    const product = this.paymentInfo?.item || this.paymentInfo?.order?.items[0];

    if (product?.quantity > 0) {
      product.quantity = event;
      this.needUpdate = true;

      if (this.paymentInfo?.order) {
        this.paymentInfo.order.totalCents = this.$order.getTotalValues(this.paymentInfo?.order?.items);
      }
    }
  }

  public setPaymentPayloads(): void {
    this.loading = true;
    this.$notification.setTypeResult('LOADING');

    if (this.formPayment.get('paymentMethod').value === 'CREDIT_CARD' && !this.formCreditCard.get('id').value) {
      this.$creditCard.tokenizeFirebase(this.formCreditCard.get('creditCardNumber').value).subscribe({
        next: (res) => {
          if (res.body?.number_token) {
            this.onPayOrder(res.body?.number_token);
          } else {
            this.$message.error('Cartão inválido, revise os dados e tente novamente.');
          }
        },
        error: (error) => {
          this.$notification.setErrorMesage('Erro ao tokenizar o cartão!');
          this.$notification.setTypeResult('ERROR');
          this.$notification.setLoading(false);
          throw new Error(error);
        },
      });
    } else {
      this.onPayOrder(null);
    }
  }

  public getPaymentInfo(token: string): PaymentInfo {
    this.setCustomerPayload();

    const creditCard =
      this.formPayment.get('paymentMethod').value === 'CREDIT_CARD'
        ? this.$creditCard.setCreditCardPayload(token, this.formAddress, this.formCreditCard, this.formCostumer)
        : null;

    const paymentInfo: PaymentInfo = {
      customer: this.paymentInfo?.customer,
      order: this.paymentInfo?.order,
      plans: this.paymentInfo?.plans,
      item: this.paymentInfo?.item,
      coupon: { code: this.formPayment.get('cupom')?.value?.toUpperCase() } as CouponModel,
      expirationDate: this.formBankSlip.get('limitDate').value,
      externalId: this.paymentInfo?.externalId,
      creditCard: creditCard as CreditCardModel,
      installments: this.formCreditCard.get('installment').value,
      typePage: this.paymentInfo?.typePage,
      location: this.paymentInfo?.location,
      method: this.formPayment.get('paymentMethod').value,
      paymentLink: this.paymentInfo?.paymentLink,
      redirectTo: this.paymentInfo?.redirectTo,
      seller: this.paymentInfo?.seller,
      token: creditCard?.token,
    };

    return paymentInfo;
  }

  public setCustomerPayload(): void {
    this.paymentInfo.customer = this.$customer.getCustomerPayload(
      this.paymentInfo?.customer,
      this.formCostumer,
      this.formAddress
    );
  }

  public validateCoupon(couponCode?: string): void {
    if (this.paymentInfo?.typePage === 'ORDER') {
      this.couponLoading = true;

      if (this.needUpdate) {
        const paymentInfo = this.getPaymentInfo(null);
        this.$order.updateAndDoAction(paymentInfo, true);
      } else {
        this.applyCoupon(couponCode);
      }
    } else {
      this.couponLoading = true;

      const paymentInfo = this.getPaymentInfo(null);
      this.$order.createItemCheckoutOrder(paymentInfo, true);
    }
  }

  public applyCoupon(couponCode?: string): void {
    try {
      if (couponCode) {
        this.formPayment.get('cupom').setValue(couponCode);
        couponCode = null;
        this.$notification.setActiveCoupon(null);
      }

      const payload = {
        sellerId: this.paymentInfo?.seller?.id || this.paymentInfo?.order?.sellerId,
        orderId: this.paymentInfo?.order?.id,
        code: this.formPayment.get('cupom').value?.toUpperCase(),
      };

      this.$coupon
        .applyCoupon(payload)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            if (data.status === 201) {
              this.validatedCoupon = true;
              this.$message.success('Cupom aplicado!');
              this.formPayment.get('cupom').disable();
            } else {
              const reason =
                this.$coupon.getReason(
                  isString(data.body.errors) ? data.body.errors : JSON.stringify(data.body.errors)
                ) || 'Erro ao aplicar o cupom';

              this.$message.error(reason);
              this.formPayment.get('cupom').setErrors({ notFound: true });
              this.formPayment.get('cupom').markAsTouched();
            }

            this.couponLoading = false;
          },
          error: (error) => {
            this.formPayment.get('cupom').setErrors({ notFound: true });
            this.formPayment.get('cupom').markAsTouched();
            this.couponLoading = false;

            throw new Error(error);
          },
        });
    } catch (error: any) {
      this.$message.error('Erro ao aplicar o cupom. Por favor, tente novamente mais tarde.');
      this.couponLoading = false;
      throw new Error(error);
    }
  }

  public onPayOrder(token: string): any {
    const paymentInfo = this.getPaymentInfo(token);

    if (this.paymentInfo?.order && this.needUpdate) {
      return this.$order.updateAndDoAction(paymentInfo);
    }

    let payload = {};

    switch (paymentInfo?.typePage) {
      case 'ORDER':
        payload = this.$order.createPayload(paymentInfo);
        this.$order.onPayOrder(payload, paymentInfo);
        break;
      case 'ITEM':
        if (this.paymentInfo?.order) {
          payload = this.$order.createPayload(paymentInfo);
          this.$order.onPayOrder(payload, paymentInfo);
        } else {
          this.$order.createItemCheckoutOrder(paymentInfo, false);
        }
        break;
      case 'PLAN':
        payload = this.$plan.createPayload({ ...paymentInfo, prices: this.selectedPrice });
        this.$plan.onSignPlanOrSubscription(payload);
        break;

      case 'SUBSCRIPTION':
        payload = this.$plan.createPayload({ ...paymentInfo, ...this.paymentInfo });
        this.$plan.onSignPlanOrSubscription(payload);
        break;
    }
  }

  public selectedPriceAmount(plan: PlansModel): number {
    const selectedPrice = plan?.prices?.filter((plan) => plan.id === this.formPayment.get('priceId').value)[0];

    this.$notification.setSelectedPrice(selectedPrice);

    this.$methods.planDetails(selectedPrice);

    if (selectedPrice) {
      return selectedPrice.amountCents;
    } else {
      return 0;
    }
  }
}
