/* eslint-disable no-async-promise-executor */
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '@gen/environments';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, combineLatest, mergeMap, of, take } from 'rxjs';

import { CartModel } from '../../models/cart/cart.model';
import { StoreModel } from '../../models/store/store.model';
import { BasketModel } from '../../models/cart/basket.model';
import { ItemsModel } from './../../models/items/items.model';
import { CustomerService } from '../customer/customer.service';
import { ItemsService } from '../products/items/items.service';
import { PointModel } from '../../models/points/point-of-sales.model';
import { StateManagementService } from './../../state-management/state-management.service';
import { ConfirmCleanBottomSheetComponent } from '../../components/confirm-clean-bottom-sheet/confirm-clean-bottom-sheet.component';

@Injectable()
export class PointOfSalesService {
  public marketplaceId: string = environment.marketplaceId;
  public pointId: string;

  constructor(
    private $item: ItemsService,
    private readonly router: Router,
    private $customers: CustomerService,
    private readonly $drawer: NzDrawerService,
    private readonly afStore: AngularFirestore,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public addNewPoint(customerId?: string): string {
    const pointId = uuidv4();

    const pointName = `Catálogo ${Date.now()}`;
    this.getPointCollection()
      ?.doc(pointId)
      .set({
        id: pointId,
        customerId,
        description: pointName,
        insertedAt: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
      });

    this.pointId = pointId;

    return this.pointId;
  }

  public async addProduct(
    sellerId: string,
    pointId: string,
    product: ItemsModel,
    cartId: string,
    cart: CartModel,
    store: StoreModel,
  ): Promise<void> {
    product.originalId = product.id;
    const productId = uuidv4();

    let basket: BasketModel = await this.getBasketCollection(pointId, cartId)
      .doc(sellerId)
      .valueChanges()
      .pipe(take(1))
      .toPromise();

    if (!basket) {
      basket = {
        id: sellerId,
        store: store,
        cartId: cartId,
        pointId: pointId,
        sellerId: sellerId,
        items: [product],
        totalValue: product.amountCents,
      };
    } else {
      basket.items.push(product);
      basket.totalValue = this.$item.calcTotalPrice(basket.items);
    }

    this.getBasketCollection(pointId, cartId)
      .doc(sellerId)
      .set(basket)
      .then(() => {
        this.getBasketProductsCollection(pointId, cartId, sellerId)
          .doc(productId)
          .set(product)
          .then(() => {
            this.$message.success('Produto adicionado com sucesso!');
            this.updateCart(pointId, cartId, cart);
          });
      });
  }

  public updateProduct(pointId: string, product: ItemsModel, cartId: string, sellerId: string): void {
    if (pointId && product && cartId && sellerId) {
      this.getBasketProductsCollection(pointId, cartId, sellerId).doc(product.id).update(product);
    }
  }

  public updateProducts(pointId: string, products: Array<ItemsModel>, cartId: string, sellerId: string) {
    if (products) {
      products.map((product) => this.updateProduct(pointId, product, cartId, sellerId));
    }
  }

  public async clearBasket(pointId: string, cartId: string): Promise<void> {
    const baskets = await this.getBasketCollection(pointId, cartId)
      .valueChanges({ idField: 'id' })
      .pipe(take(1))
      .toPromise();

    if (baskets) {
      baskets.map((basket) => this.getBasketCollection(pointId, cartId).doc(basket.id).delete());
    }
  }

  public async clearPoints(): Promise<void> {
    const points = await this.getPointCollection().valueChanges({ idField: 'id' }).pipe(take(1)).toPromise();

    if (points) {
      points.map((point) => {
        if (point.id !== 'f74f41f9-ccd4-4117-b27f-d579aaf49df4') {
          this.getPointCollection().doc(point.id).delete();
        }
      });
    }
  }

  public removeProductFromBasket(pointId: string, productId: string, cartId: string, sellerId: string): void {
    this.getBasketProductsCollection(pointId, cartId, sellerId).doc(productId).delete().then(() => {
      this.$message.success('Produto removido com sucesso!');
    });
  }

  public removeBasket(pointId: string,cartId: string, sellerId: string): void {
    this.getBasketCollection(pointId, cartId).doc(sellerId).delete();
  }

  public getBasketProducts(pointId: string, cartId: string, sellerId: string): Observable<ItemsModel[]> | undefined {
    return this.getBasketProductsCollection(pointId, cartId, sellerId).valueChanges({ idField: 'id' });
  }

  public getBasketProduct(
    pointId: string,
    productId: string,
    cartId: string,
    sellerId: string
  ): Observable<ItemsModel> | undefined {
    return this.getBasketProductsCollection(pointId, cartId, sellerId).doc(productId).valueChanges({ idField: 'id' });
  }

  private getPointCollection(): AngularFirestoreCollection<PointModel> {
    return this.afStore.collection<PointModel>(`marketplaces/${this.marketplaceId}/pointOfSales`);
  }

  private getCartCollection(pointId: string): AngularFirestoreCollection<CartModel> {
    return this.afStore.collection<CartModel>(`marketplaces/${this.marketplaceId}/pointOfSales/${pointId}/carts`);
  }

  private getBasketCollection(pointId: string, cartId: string): AngularFirestoreCollection<BasketModel> {
    return this.afStore.collection<BasketModel>(
      `marketplaces/${this.marketplaceId}/pointOfSales/${pointId}/carts/${cartId}/basket`
    );
  }

  private getBasketProductsCollection(
    pointId: string,
    cartId: string,
    sellerId: string
  ): AngularFirestoreCollection<ItemsModel> {
    return this.afStore.collection<ItemsModel>(
      `marketplaces/${this.marketplaceId}/pointOfSales/${pointId}/carts/${cartId}/basket/${sellerId}/items`
    );
  }

  public openConfirmClean(sellerId: string, pointId: string, cartId: string): void {
    const drawer = this.$drawer.create({
      nzHeight: '300px',
      nzClosable: false,
      nzPlacement: 'bottom',
      nzContent: ConfirmCleanBottomSheetComponent,
    });
    drawer.afterClose.subscribe((res) => {
      if (res) {
        this.setClearBasket(sellerId, pointId, cartId);
      }
    });
  }

  public setClearBasket(sellerId: string, pointId: string, cartId: string): void {
    this.clearBasket(pointId, cartId);
    this.$notification.setCleanBasket(true);
    this.router.navigate(['/external/' + sellerId + '/' + pointId]);

    setTimeout(() => {
      this.$notification.setCleanBasket(false);
    }, 3000);
  }

  public updatePoint(pointId: string, data: any): void {
    if (pointId) {
      this.getPointCollection()?.doc(pointId).set(data, { merge: true });
    }
  }

  public updateCart(pointId: string, cartId: string, data: any): void {
    if (pointId && cartId && data) {
      this.getCartCollection(pointId)?.doc(cartId).set(data, { merge: true });
    }
  }

  public updateBasket(pointId: string, cartId: string, data: any): void {
    if (pointId && cartId && data) {
      this.getBasketCollection(pointId, cartId)?.doc(data.sellerId).set(data, { merge: true });
    }
  }

  public getPoint(pointId: string): Observable<PointModel> {
    return this.getPointCollection()?.doc(pointId).valueChanges();
  }

  public setCustomer(pointId: string, user: any): void {
    const uid = user.uid;

    this.$customers.getAuthCustomer(uid).subscribe((customer) => {
      if (customer && Object.keys(customer).length > 0) {
        this.updatePoint(pointId, { customerId: customer.id });
      }
    });
  }

  public async setPointInfo(pointId: string): Promise<void> {
    const point = await this.getPoint(pointId).pipe(take(1)).toPromise();

    this.$notification.setPoint(point);
  }

  public getAndSetActiveCart(pointId: string, customerId?: string): void {
    let activeCart = new CartModel();

    this.getActiveCart(pointId)
      .pipe(
        mergeMap((carts) => {
          if (carts.length > 0) {
            activeCart = carts[0];
            this.updatePoint(pointId, { cartId: activeCart.id });

            return combineLatest({
              baskets: this.getBasketCollection(pointId, activeCart.id).valueChanges(),
            });
          } else {
            activeCart = this.addNewCart(pointId, customerId);
            this.updatePoint(pointId, { cart: activeCart });
          }

          return of();
        })
      )
      .subscribe({
        next: (res) => {
          if (res) {
            if (res.baskets) {
              this.geBasketWithItens(res.baskets, activeCart);
            } else {
              this.$notification.setCart(activeCart);
              this.updatePoint(pointId, { cart: activeCart });
            }
          }
        },
        error: (error) => {
          throw new Error(error);
        },
      });
  }

  public async geBasketWithItens(baskets: BasketModel[], cart: CartModel) {
    await Promise.all(
      baskets.map(async (basket, index: number) => {
        await new Promise<void>(async (resolve, reject) => {
          basket.items = await this.getBasketProducts(cart.pointId, cart.id, basket.sellerId).pipe(take(1)).toPromise();
          basket.totalValue = this.$item.calcTotalPrice(basket.items);
          resolve();
        });
      })
    ).then(async () => {
      cart.baskets = baskets;
      cart.totalValue = this.calcTotalPriceBasket(baskets);
      this.$notification.setCart(cart);
      this.updatePoint(cart.pointId, { cart });
    });
  }

  public getActiveCart(pointId: string): Observable<CartModel[]> {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/pointOfSales/${pointId}/carts`, (ref) => {
        const startDate = moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0 }).format();
        const endDate = moment().set({ hour: 23, minute: 59, second: 59 }).format();

        let query: CollectionReference | Query = ref;

        query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);

        query = query.where('status', '==', 'ACTIVE');

        return query.orderBy('insertedAt', 'desc');
      })
      .valueChanges() as Observable<CartModel[]>;
  }

  public addNewCart(pointId: string, customerId?: string): CartModel {
    const cartId = uuidv4();
    const cart: CartModel = {
      id: cartId,
      status: 'ACTIVE',
      orders: [],
      payments: [],
      baskets: [],
      pointId,
      customerId,
      isPaid: false,
      totalValue: 0,
      insertedAt: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
    };

    this.getCartCollection(pointId)?.doc(cartId).set(cart);

    return cart;
  }

  public calcTotalPriceBasket(baskets: Array<BasketModel>): number {
    return baskets.reduce((acc, basket) => {
      acc += Number(basket.totalValue);
      return acc;
    }, 0);
  }
}
