import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { StoreService } from '../../../services/store/store.service';

@Component({
  selector: 'app-modal-whatsapp-validation',
  templateUrl: './modal-whatsapp-validation.component.html',
  styleUrls: ['./modal-whatsapp-validation.component.scss'],
})
export class ModalWhatsappValidationComponent {
  @Input() public sellerId: string;
  @Input() public whatsappNumber: string;

  public token: string;
  public sendingCode: boolean = false;
  public countDown: number = 5;
  public invalidCode: boolean = false;

  constructor(private $store: StoreService, public modalRef: NzModalRef, private message: NzMessageService) {}

  public updateWhatsappNumber(): void {
    this.$store.updateStoreWhatsappNumber({ whatsappNumber: this.whatsappNumber }, this.sellerId).subscribe({
      next: () => {
        this.message.success('Código enviado.');
        this.setSendingCode();
      },
      error: (err: any) => {
        throw new Error(err);
      },
    });
  }

  public updateWhatsappNumberConfirmation(): void {
    this.$store.updateStoreWhatsappNumberConfirmation({ token: this.token }, this.sellerId).subscribe({
      next: () => {
        this.message.success('Código validado.');
        this.modalRef.close('valid');
      },
      error: (err: any) => {
        this.invalidCode = true;
        throw new Error(err);
      },
    });
  }

  public getFullCode(event: string): void {
    if (event) {
      this.token = event;
    }
  }

  public setSendingCode(): void {
    this.sendingCode = true;
    setTimeout(() => {
      this.sendingCode = false;
    }, 5000);
    setInterval(() => {
      this.countDown -= 1;
    }, 1000);
  }
}
