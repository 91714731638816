import { Component, Input, OnInit } from '@angular/core';
import { WithdrawlsModel } from 'libs/shared/src/lib/models/withdrawls/withdrawl.model';
import { WithdrawlsService } from 'libs/shared/src/lib/services/withdrawals/withdrawls.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-drawer-withdraw-details',
  templateUrl: './drawer-withdraw-details.component.html',
  styleUrls: ['./drawer-withdraw-details.component.scss'],
})
export class DrawerWithdrawDetailsComponent implements OnInit {
  @Input() value: string;

  public withdrawal: WithdrawlsModel;

  public loading: boolean = true;

  constructor(public $withdrawal: WithdrawlsService, private readonly $message: NzMessageService) {}

  public ngOnInit(): void {
    this.getWithdrawl();
  }

  public getWithdrawl(): void {
    this.$withdrawal.getWithdrawById(this.value).subscribe({
      next: (res) => {
        if (res?.data?.getWithdraw) {
          this.withdrawal = res.data.getWithdraw;
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao buscar o saque');
        throw new Error(error);
      },
    });
  }

  public approveWithdraw(): void {
    this.loading = true;

    this.$withdrawal.approveWithdrawl(this.value).subscribe({
      next: (res) => {
        if (res?.data?.approveWithdraw) {
          this.withdrawal = res.data.approveWithdraw;
          this.$message.success('Saque aprovado com sucesso');
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao aprovar o saque');
        throw new Error(error);
      },
    });
  }

  public declineWithdrawl(): void {
    this.loading = true;

    this.$withdrawal.declineWithdrawl(this.value).subscribe({
      next: (res) => {
        if (res?.data?.declineWithdraw) {
          this.withdrawal = res.data.declineWithdraw;
          this.$message.success('Saque reprovado com sucesso');
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao reprovar o saque');
        throw new Error(error);
      },
    });
  }
}
