import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ticket-warning',
  templateUrl: './modal-ticket-warning.component.html',
  styleUrls: ['./modal-ticket-warning.component.scss'],
})
export class ModalTicketWarningComponent {
  @Input() public homeRoute: string;
  @Input() public status: string;
  @Input() public cod: number;
  @Input() public message: string;
  constructor(private router: Router, private $modal: NzModalRef) {}

  public navigateHome(): void {
    this.$modal.close();
    this.router.navigate([this.homeRoute]);
  }
}
