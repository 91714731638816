import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private firebaseAuth: AngularFireAuth, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.firebaseAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          const result = user.getIdTokenResult(false);
          return from(result);
        } else {
          return of(undefined);
        }
      }),
      map((result) => {
        if (result) {
          return result?.claims;
        } else {
          return undefined;
        }
      }),
      map((claims: any) => {
        if (claims?.user_id) {
          this.router.navigate(['/internal']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
