import {
  FormArray,
  AbstractControl,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TypeModel } from '../../models/option/option.model';
import { ItemPriceModel } from '../../models/items/item-price.model';

@Component({
  selector: 'app-item-prices-table',
  templateUrl: './item-prices-table.component.html',
  styleUrls: ['./item-prices-table.component.scss'],
})
export class ItemPricesTableComponent implements OnInit {
  @Input() public value: number = 0;
  @Input() public arrayControl: FormArray;
  @Input() public itemPrices: Array<ItemPriceModel> = [];
  @Input() public valueByVariation: boolean = false;
  @Input() public updateItemPrices: Observable<void>;
  @Input() public getItemPricesEvent: Observable<void>;

  @Output() public sendItemPricesEvent = new EventEmitter();

  public form!: FormGroup;
  public itemPricesForm!: FormArray;
  public height: number = 50;
  public heightArray: Array<number> = [];
  public rangeArray: Array<number> = [];
  public showTable: boolean = false;

  constructor(private fb: FormBuilder) {}

  public ngOnInit() {
    this.getEvents();
    this.createForm();
  }

  public getEvents(): void {
    this.updateItemPrices.subscribe(() => this.setItemPrices());
    this.getItemPricesEvent.subscribe(() => this.sendItemPrices());
  }

  public sendItemPrices(): void {
    if (this.itemPricesForm.valid) {
      this.itemPricesForm.controls.forEach((element) => {
        element.get('amountCents').setValue(element.get('amountCents').value * 100);
      });

      this.sendItemPricesEvent.emit(this.itemPricesForm.value);
    } else {
      this.itemPrices.forEach((element) => {
        element.amountCents = element.amountCents * 100;
      });

      this.sendItemPricesEvent.emit(this.itemPrices);
    }
  }

  public createForm(): void {
    this.form = this.fb.group({ itemPricesForm: this.fb.array([]) });
    this.itemPricesForm = this.form.controls['itemPricesForm'] as FormArray;

    if (this.value) {
      this.setItemPrices();
    }
  }

  public checkShowTable(): void {
    const option = this.arrayControl.at(0).value;

    if (option.name !== '') {
      this.showTable = true;
    }
  }

  public getControls(): Array<AbstractControl> {
    return this.arrayControl?.controls;
  }

  public cleanOption(): void {
    this.getControls().forEach((element, index: number) => {
      if (element.value?.name === '' && element.value?.types === '') {
        this.arrayControl.removeAt(index);
      }
    });
  }

  public createRange(index: number): Array<number> {
    const i = index - 1 > 0 ? index - 1 : 0;
    const firstLenght = this.arrayControl?.at(0)?.get('types')?.value.length;
    const lenght = this.arrayControl?.at(i)?.get('types')?.value.length;
    let number = index === 1 ? lenght : lenght * firstLenght;
    number = isNaN(number) ? 0 : number;

    return new Array(number);
  }

  public getControlsByIndex(index: number, value: string): any {
    const control = this.getControls().at(index);
    return control.get(value)?.value;
  }

  public setItemPrices(): void {
    this.cleanOption();
    this.itemPricesForm.clear();

    let response = [];

    // Length and last ID
    if (this.getControls().length === 1) {
      this.getControls().forEach((element) => {
        element.value?.types?.forEach((type: TypeModel) => {
          if (type?.id) {
            if (this.itemPrices.length) {
              const itemPrice = this.itemPrices.find((price) => price.itemTypes[0].id === type.id);

              if (!isEmpty(itemPrice)) {
                response.push({
                  ...itemPrice,
                  itemTypesIds: [type.id],
                });

                return;
              }
            }

            response.push({
              amountCents: this.value,
              itemTypesIds: [type.id],
            });
          }
        });
      });
    } else if (this.getControls().length > 1) {
      for (let i = 0; i < this.createRange(this.arrayControl.length - 1).length; i++) {
        this.getControlsByIndex(this.arrayControl.length - 1, 'types').forEach((type: TypeModel) => {
          if (type?.id) {
            if (this.itemPrices.length) {
              const itemPrice = this.itemPrices.find((price) => price.itemTypes[0].id === type.id);

              if (!isEmpty(itemPrice)) {
                response.push({
                  ...itemPrice,
                  itemTypesIds: [type.id],
                });

                return;
              }
            }

            response.push({
              amountCents: this.value,
              itemTypesIds: [type.id],
            });
          }
        });
      }

      // primeiro Id
      const firstLenght = this.arrayControl?.at(0)?.get('types')?.value.length;
      const groups: any = this.createGroups(response, firstLenght);

      this.arrayControl
        ?.at(0)
        ?.get('types')
        ?.value.forEach((type: TypeModel, i: number) => {
          groups[i].forEach((element: ItemPriceModel) => {
            element.itemTypesIds.unshift(type.id!);
          });
        });

      response = [...groups];

      // ids do meio
      this.getControls().forEach((element, index: number) => {
        if (index > 0 && index < this.getControls().length - 1) {
          const lenght = element.value?.types?.length * this.createRange(index).length;
          const groups: any = this.createGroups(response, lenght);
          let groupIndex = 0;

          for (let i = 0; i < this.createRange(index).length; i++) {
            element.value?.types?.forEach((type: TypeModel, j: number) => {
              groups[groupIndex].forEach((el: ItemPriceModel) => {
                el.itemTypesIds.splice(index, 0, type.id!);
              });
              groupIndex++;
            });

            if (i === this.createRange(index).length - 1) {
              this.itemPrices = [...groups];
            }
          }
        }
      });
    }

    response.forEach((element) => {
      this.itemPricesForm.push(
        new FormGroup({
          itemTypesIds: new FormControl(element.itemTypesIds),
          amountCents: new FormControl(element.amountCents / 100, [Validators.required, this.minAmount(10)]),
          quantity: new FormControl(0),
        })
      );
    });

    this.checkShowTable();
  }

  public createGroups(arr: ItemPriceModel[], numGroups: number): Array<any> {
    const perGroup = Math.ceil(arr.length / numGroups);
    return new Array(numGroups).fill('').map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
  }

  public getHeight(index: number): any {
    if (this.getControls().length > 1) {
      if (this.heightArray[0]) {
        this.heightArray[0] = 100 * this.getControls().length;
      } else {
        this.heightArray.push(100 * this.getControls().length);
      }

      if (index > 0) {
        const height = this.heightArray[index - 1];
        const lenght = this.arrayControl?.at(index)?.get('types')?.value.length;
        const number = height / lenght;

        if (this.heightArray[index]) {
          this.heightArray[index] = number;
        } else {
          this.heightArray.push(number);
        }

        return number + 'px';
      } else {
        return false;
      }
    } else {
      return '100px';
    }
  }

  private minAmount(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value < minAmount;
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }
}
