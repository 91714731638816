import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { ContactDetailsComponent } from '../../contact-details/contact-details.component';
import { TransactionsDetailsComponent } from '../../transactions/transactions-details/transactions-details.component';
import { OrdersService } from 'libs/shared/src/lib/services/orders/orders.service';
import { ContactsService } from 'libs/shared/src/lib/services/contacts/contacts.service';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { environment } from '@gen/environments';
import { OrderModel } from 'libs/shared/src/lib/models/orders/order.model';
import { ContactModel } from 'libs/shared/src/lib/models/contact/contact.model';
import { PaymentsModel } from 'libs/shared/src/lib/models/payments/payments.model';
import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { TableFooterModel } from 'libs/shared/src/lib/models/table/table-footer.model';
import { TableHeaderModel } from 'libs/shared/src/lib/models/table/table-header.model';
import { FooterType } from 'libs/shared/src/lib/types/footer-type';
import { TableSettingsModel } from 'libs/shared/src/lib/models/table/table-settings.model';
import { Message } from 'libs/shared/src/lib/utils/message';

@Component({
  selector: 'app-orders-details',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.scss'],
})
export class OrdersDetailsComponent implements OnInit {
  @Input() param: { id: string };

  public data: OrderModel = new OrderModel();
  public contact: ContactModel = new ContactModel();
  public payments: Array<PaymentsModel> = [];
  public methods: string = '';
  public listOfData: Array<ItemModel> = [];
  public dateStatus: string = '';
  public orderRoute: string = '';
  public method: string = '';
  public loading: boolean = false;

  public footerData: TableFooterModel = new TableFooterModel();
  public footerType: FooterType = 'editableTableFooter';
  public listOfHeadersColumn: TableHeaderModel[] = [
    { name: 'Descrição' },
    { name: 'QTD' },
    { name: 'Preço Unit' },
    { name: 'Valor' },
  ];
  public settingValue: TableSettingsModel = {
    pagination: false,
    footer: true,
    expandable: false,
    checkbox: false,
    footerName: this.footerType,
  };

  constructor(
    private $orders: OrdersService,
    private $contacts: ContactsService,
    private $internal: InternalService,
    public readonly $drawerRef: NzDrawerRef,
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.$orders.getOrderDetail(res.sellerId, this.param.id).subscribe((order) => {
          if (order) {
            this.$orders.getPayments(res.sellerId, this.param.id).subscribe((payments) => {
              if (payments) {
                this.payments = payments;
                this.dateStatus = this.$internal.getString(order, payments);
                this.data = order;
                this.orderRoute = `orders/${this.param.id}`;
                this.listOfData = order.items || [];
                this.methods = this.$internal.getMethods(this.data?.methods);
                this.method = this.$internal.getMethod(payments[0]?.method);
                this.footerData = new TableFooterModel();
                this.footerData.totalAmount = this.data.totalCents;

                this.$contacts
                  .getContactDetailByCustomerId(
                    res.marketplaceId,
                    res.sellerId,
                    this.data?.customerData?.id || this.data?.customerId
                  )
                  ?.subscribe((contact) => {
                    if (contact && contact.length > 0) {
                      this.contact = contact[0];
                    }
                  });
              }
            });
          }
        });
      }
    });
  }

  public delete(): void {
    this.loading = true;

    this.$orders.deleteOrder(this.data?.id).subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.$message.success('Pedido excluído com sucesso!');
        } else {
          this.$message.error('Erro ao excluir o pedido, tente novamente!');
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error(Message.ERROR_CONECTION);
        throw new Error(error);
      },
    });
  }

  public copyContent(): void {
    /* const url = getTemporaryRoute(); */
    const url = environment.appDomain;

    this.$internal.copyContent(`${url}/${this.orderRoute}`);
  }

  public openPayment(): void {
    /* const url = getTemporaryRoute(); */
    const url = environment.appDomain;

    window.open(`${url}/${this.orderRoute}`, '_blank');
  }

  public showContactDetails(): void {
    this.$drawer.create({
      nzTitle: 'Detalhes do Contato',
      nzWidth: '800px',
      nzContent: ContactDetailsComponent,
      nzContentParams: {
        value: this.contact,
      },
    });
  }

  public showPaymentDetails(payment: PaymentsModel): void {
    const param: any = { param: payment };

    this.$modal.create({
      nzContent: TransactionsDetailsComponent,
      nzComponentParams: param,
      nzStyle: { position: 'absolute', top: 0, right: 0, height: '100vh' },
      nzClassName: 'defaultModal', // TO use min-height: 100vh
      nzFooter: null,
      nzClosable: false,
    });
  }
}
