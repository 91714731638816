import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';
import { LIST_ITEM_OPTIONS_QUERY } from '../../../graphql/queries/option.queries';
import {
  CREATE_ITEM_OPTION_MUTATION,
  DELETE_ITEM_OPTION_MUTATION,
  UPDATE_ITEM_OPTION_MUTATION,
} from '../../../graphql/mutations/option.mutations';
import { OptionModel } from '../../../models/option/option.model';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  constructor(private readonly apollo: Apollo) {}

  public getOptionList(sellerId: string, filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEM_OPTIONS_QUERY,
      variables: { sellerId, filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createOption(data: OptionModel): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_OPTION_MUTATION,
      variables: data,
    });
  }

  public updateOption(data: OptionModel): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_OPTION_MUTATION,
      variables: data,
    });
  }

  public deleteOption(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_OPTION_MUTATION,
      variables: { id },
    });
  }
}
