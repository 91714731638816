import { gql } from 'apollo-angular';

const BAN_SELLER_MUTATION = gql`
  mutation BanSeller($id: UUID4!) {
    banSeller(id: $id) {
      id
    }
  }
`;

const BLOCK_SELLER_MUTATION = gql`
  mutation BlockSeller($id: UUID4!) {
    blockSeller(id: $id) {
      id
    }
  }
`;

const UNBLOCK_SELLER_MUTATION = gql`
  mutation UnblockSeller($id: UUID4!) {
    unblockSeller(id: $id) {
      id
    }
  }
`;

const REPROCESS_SELLER_MUTATION = gql`
  mutation ReprocessSeller($id: UUID4!) {
    reprocessSeller(id: $id) {
      id
    }
  }
`;

const EDIT_SELLER_MUTATION = gql`
  mutation UpdateSeller($id: UUID4!, $params: UpdateSellerParams!) {
    updateSeller(id: $id, params: $params) {
      id
    }
  }
`;

export {
  BAN_SELLER_MUTATION,
  EDIT_SELLER_MUTATION,
  BLOCK_SELLER_MUTATION,
  UNBLOCK_SELLER_MUTATION,
  REPROCESS_SELLER_MUTATION,
};
