import { gql } from 'apollo-angular';

const LIST_ITEM_OPTIONS_QUERY = gql`
  query ListItemOptions($sellerId: UUID4!) {
    listItemOptions(sellerId: $sellerId, pagination: { orderBy: "inserted_at", sortingOrder: DESC }) {
      id
      name
      required
      updatedAt
      insertedAt
      itemsCount
      displayName
      types {
        id
        name
        color
        imageUrl
        amountCents
      }
    }
  }
`;

export { LIST_ITEM_OPTIONS_QUERY };
