import { gql } from 'apollo-angular';

const CREATE_ITEM_MUTATION = gql`
  mutation CreateItem(
    $name: String!
    $active: Boolean
    $sellerId: UUID4!
    $amountCents: Int!
    $type: ItemTypeEnum!
    $description: String
    $imagesUrls: [String]
    $ableSmartpos: Boolean
    $itemCategoryId: UUID4
    $itemOptionsIds: [UUID4]
    $itemPrices: [CreateItemPriceNested]
  ) {
    createItem(
      params: {
        name: $name
        type: $type
        active: $active
        sellerId: $sellerId
        imagesUrls: $imagesUrls
        itemPrices: $itemPrices
        description: $description
        amountCents: $amountCents
        ableSmartpos: $ableSmartpos
        itemOptionsIds: $itemOptionsIds
        itemCategoryId: $itemCategoryId
      }
    ) {
      id
    }
  }
`;

const UPDATE_ITEM_MUTATION = gql`
  mutation UpdateItem(
    $id: UUID4!
    $name: String
    $sellerId: UUID4
    $active: Boolean
    $amountCents: Int
    $type: ItemTypeEnum
    $description: String
    $imagesUrls: [String]
    $ableSmartpos: Boolean
    $itemCategoryId: UUID4
    $itemOptionsIds: [UUID4]
    $itemPrices: [UpdateItemPriceNested]
  ) {
    updateItem(
      id: $id
      params: {
        name: $name
        type: $type
        active: $active
        sellerId: $sellerId
        imagesUrls: $imagesUrls
        itemPrices: $itemPrices
        amountCents: $amountCents
        description: $description
        ableSmartpos: $ableSmartpos
        itemOptionsIds: $itemOptionsIds
        itemCategoryId: $itemCategoryId
      }
    ) {
      id
    }
  }
`;

const DELETE_ITEM_MUTATION = gql`
  mutation DeleteItem($id: UUID4!) {
    deleteItem(id: $id) {
      deletedAt
    }
  }
`;

export { CREATE_ITEM_MUTATION, UPDATE_ITEM_MUTATION, DELETE_ITEM_MUTATION };
