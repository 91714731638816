import { gql } from 'apollo-angular';

export const FEE_FIELDS = `
  id
  acquirerPlanId
  active
  amountCents
  application {
    id
  }
  insertedAt
  installments
  method
  percent
  seller {
    id
    company {
      businessName
    }
    person {
      name
    }
    cpf
    cnpj
  }
  updatedAt
`;

const LIST_FEES_QUERY = gql`
  query ListFees($filter: FilterFeesParams) {
    listFees(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC }) {
      ${FEE_FIELDS}
    }
  }
`;

const GET_FEE_QUERY = gql`
  query GetFee($id: UUID4!) {
    getFee(id: $id) {
      ${FEE_FIELDS}
    }
  }
`;

export { GET_FEE_QUERY, LIST_FEES_QUERY };
