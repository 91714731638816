import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-confirmation-delete',
  templateUrl: './confirmation-delete.component.html',
  styleUrls: ['./confirmation-delete.component.scss'],
})
export class ConfirmationDeleteComponent {
  @Input() message: string;
  @Input() isImportant: boolean;
  @Input() buttonName: string;

  constructor(public $modalRef: NzModalRef) {}
}
