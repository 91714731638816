import { SidenMenuModel } from '../models/side-menu/side-menu.model';

export const MAIN_SIDENAV: SidenMenuModel[] = [
  {
    title: 'Início',
    icon: 'home',
    route: '/internal/home',
  },
  {
    title: 'Favoritos',
    icon: 'heart',
    route: '/internal/favorites',
  },
  {
    title: 'Pedidos',
    icon: 'shopping',
    route: '/internal/orders',
  },
];
