import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { UserClaimsModel } from 'libs/shared/src/lib/models/user-claims/user-claims.model';
import { DashboardModel } from 'libs/shared/src/lib/models/onboarding/dashboard.model';
import { OnboardingService } from 'libs/shared/src/lib/services/onboarding/onboarding.service';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss'],
})
export class ValidateEmailComponent implements OnInit {
  public email: string = '';
  public formGroup!: FormGroup;

  private user!: UserClaimsModel;
  private collection!: DashboardModel;

  constructor(
    private readonly fb: FormBuilder,
    private $message: NzMessageService,
    private $onboarding: OnboardingService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getUser();
    this.getDashboardActivationCollection();

    this.$notification.setOnboardingBtnDisabled(true);

    this.formGroup.valueChanges.subscribe((data) => {
      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });
  }

  public changeEmail(): void {
    const data: DashboardModel = Object.assign({}, this.collection, {
      emailValidation: { data: { email: '' } },
    } as DashboardModel);
    this.$notification.setDashboardActivationCollection(data);
  }

  public saveData(): void {
    this.$notification.setOnboardingBtnLoading(true);
    const code = this.formGroup.get('code').value;

    this.$onboarding
      .validateEmailCode({
        email: this.email,
        type: 'B2B_ONBOARDING_EMAIL',
        code,
      })
      .subscribe(async (res) => {
        if (res.status === 200) {
          await this.$onboarding.saveEmailValidation(this.user, {
            done: true,
            data: {
              email: this.email,
              checkCode: code,
            },
          });
        } else {
          this.$message.error('Código inválido.');
        }

        this.$notification.setOnboardingBtnLoading(false);
      });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      code: new FormControl('', [Validators.required]),
    });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection) {
        this.collection = collection;
        this.email = this.collection.emailValidation?.data?.email || '';
      }
    });
  }
}
