import { SidenavModel } from '../models/utils/sidenav.model';

const balanceRoutes: SidenavModel[] = [
  {
    title: 'Recebíveis',
    icon: 'reconciliation',
    route: '/internal/extract/receive',
  },
  {
    title: 'Carteira',
    icon: 'wallet',
    route: '/internal/extract/wallet',
  },
];

const itemRoutes: SidenavModel[] = [
  {
    title: 'Produtos e Serviços',
    route: '/internal/inventory/product',
    icon: 'book',
    disable: false,
  },
  {
    title: 'Categorias',
    route: '/internal/inventory/category',
    icon: 'bars',
    disable: false,
  },
  {
    title: 'Variações',
    route: '/internal/inventory/option',
    icon: 'tags',
    disable: false,
  },
  {
    title: 'Cupons',
    route: '/internal/inventory/coupons',
    icon: 'user',
    disable: false,
  },
];

const invoiceRoutes: SidenavModel[] = [
  {
    title: 'Fatura Simples',
    route: '/internal/invoices/simple',
    icon: 'container',
    disable: false,
  },
  {
    title: 'Fatura Recorrente',
    route: '/internal/invoices/subscriptions',
    icon: 'container',
    disable: false,
  },
];

const paymentRoutes: SidenavModel[] = [
  {
    title: 'Links ativo',
    route: '/internal/payments/active-payment-link',
    icon: 'reconciliation',
    disable: false,
  },
  {
    title: 'Links inativos',
    route: '/internal/payments/inactive-payment-link',
    icon: 'wallet',
    disable: false,
  },
  {
    title: 'Planos ativos',
    route: '/internal/payments/active-plans',
    icon: 'check-circle',
    disable: false,
  },
  {
    title: 'Planos inativos',
    route: '/internal/payments/inactive-plans',
    icon: 'close-circle',
    disable: false,
  },
];

const reportRoutes: SidenavModel[] = [
  {
    title: 'Resumo',
    route: '/internal/reports/summary',
    icon: 'book',
    disable: false,
  },
  {
    title: 'Itens',
    route: '/internal/reports/items',
    icon: 'bars',
    disable: false,
  },
  {
    title: 'Categorias',
    route: '/internal/reports/category',
    icon: 'tags',
    disable: false,
  },
  {
    title: 'Vendedores',
    route: '/internal/reports/sellers',
    icon: 'user',
    disable: false,
  },
  {
    title: 'Pagamentos',
    route: '/internal/reports/payments',
    icon: 'dollar',
    disable: false,
  },
];

export const MAIN_SIDENAV: SidenavModel[] = [
  {
    title: 'Dashboard',
    svg: '../../../assets/images/icons/chart-column.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: 'Pix',
    svg: '/assets/images/icons/pix_2.svg',
    route: '/internal/pix',
    disable: false,
  },
  {
    title: 'Saldos',
    svg: '../../../assets/images/icons/wallet.svg',
    route: '/internal/balances',
    disable: false,
  },
  {
    title: 'Extratos',
    icon: 'file-text',
    route: '/internal/extract',
    disable: false,
    childrens: balanceRoutes,
  },
  {
    title: 'Transações',
    svg: '../../../assets/images/icons/arrows-left-right.svg',
    route: '/internal/transactions',
    disable: false,
  },
  {
    title: 'Contatos',
    svg: '../../../assets/images/icons/users.svg',
    route: '/internal/contacts',
    disable: false,
  },
  {
    title: 'Itens',
    icon: 'gift',
    route: '/internal/contacts',
    disable: false,
    isOpen: false,
    childrens: itemRoutes,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'Pedidos',
    svg: '../../../assets/images/icons/files.svg',
    route: '/internal/orders',
    disable: false,
  },
  {
    title: 'Faturas',
    icon: 'container',
    route: '/internal/invoices',
    disable: false,
    isOpen: false,
    childrens: invoiceRoutes,
  },
  {
    title: 'Pagamentos',
    icon: 'dollar',
    route: '/internal/payments',
    disable: false,
    isOpen: false,
    childrens: paymentRoutes,
  },
  {
    title: 'Relatórios',
    icon: 'line-chart',
    route: '/internal/reports',
    disable: false,
    isOpen: false,
    childrens: reportRoutes,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'Configurações',
    icon: 'setting',
    route: '/internal/settings',
    disable: false,
  },
  {
    title: 'Sair',
    svg: '../../../assets/images/icons/arrow-right-from-bracket.svg',
    route: '/*',
    disable: false,
  },
];

export const CONFIG_SIDENAV: SidenavModel[] = [
  {
    title: 'Perfil',
    route: '/internal/settings/profile',
    disable: false,
  },
  {
    title: 'Conta e documentos',
    route: '/internal/settings/documents',
    disable: false,
  },
  {
    title: 'Preferências',
    route: '/internal/settings/preferences',
    disable: false,
    childrens: [
      {
        title: 'Pagamento',
        route: '/internal/settings/preferences/payment',
        disable: false,
      },
      {
        title: 'Personalização',
        route: '/internal/settings/preferences/personalization',
        disable: false,
      },
      {
        title: 'Loja',
        route: '/internal/settings/preferences/store',
        disable: false,
      },
    ],
  },
  {
    title: 'Notificações',
    route: '/internal/settings/notifications',
    disable: false,
  },
  {
    title: 'Sellers',
    route: '/internal/settings/accounts',
    disable: false,
  },
  {
    title: 'Desenvolvedor',
    route: '/internal/settings/developer',
    disable: false,
  },
  {
    title: 'Informações',
    route: '/internal/settings/info',
    disable: false,
  },
];
