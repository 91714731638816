import { Component, OnInit, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateAccountModel } from '../../models/auth/create-account.model';
import { AuthService } from '../../services/auth/auth-business/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Message } from '../../utils/message';
import { Subject, interval, takeUntil } from 'rxjs';
import { Observable } from '@apollo/client/utilities';

@Component({
  selector: 'app-confirm-phone-number',
  templateUrl: './confirm-phone-number.component.html',
  styleUrls: ['./confirm-phone-number.component.scss'],
})
export class ConfirmPhoneNumberComponent implements OnInit {
  @ViewChildren('input') inputsList!: any;
  @Input() account: CreateAccountModel;
  @Input() isNewUser: boolean;
  public formConfirmPhone: FormGroup;
  public formChangePhone: FormGroup;
  public countdown: number = 60;
  public ispause = new Subject();
  public timer: Observable<number>;
  public loadingCodeBtn: boolean = false;
  @Output() public isAuthenticated = new EventEmitter<boolean>();
  @Output() public changePhoneNumber = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private $auth: AuthService, private $message: NzMessageService) { }

  ngOnInit(): void {
    this.createForm();
    this.getTimer();
  }

  private createForm(): void {
    this.formConfirmPhone = this.fb.group({
      code1: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
      code2: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
      code3: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
      code4: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
      code5: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
      code6: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
    });
  }

  public validatePhoneNumberCode(): void {
    this.loadingCodeBtn = true;
    const code =
      this.formConfirmPhone.get('code1').value +
      this.formConfirmPhone.get('code2').value +
      this.formConfirmPhone.get('code3').value +
      this.formConfirmPhone.get('code4').value +
      this.formConfirmPhone.get('code5').value +
      this.formConfirmPhone.get('code6').value;

    this.account.code = code;
    this.account.type = 'SIGN_IN_PHONE_NUMBER';

    this.$auth.validatePhoneNumberCode(this.account).subscribe({
      next: (res) => {
        if (res?.data?.validateVerificationCodeFirebase) {
          this.$auth.customLogin(res.data.validateVerificationCodeFirebase);
          this.isAuthenticated.emit(true);
        }
        this.loadingCodeBtn = false;
      },
      error: (error) => {
        this.loadingCodeBtn = false;
        this.$message.create('error', error);
        throw new Error(error);
      },
    });
  }

  public onInput(event: any, index: number): void {
    if (event.data) {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public onPressDeleteKey(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      const input = this.inputsList._results[index];
      if (input) {
        if (index == 5) {
          input.nativeElement.value = null;
          input.nativeElement.focus();
        }
        const previousInput = this.inputsList._results[index - 1];
        if (previousInput) {
          previousInput.nativeElement.value = null;
          previousInput.nativeElement.focus();
        }
      }
    }
  }

  public changeNumber(): void {
    this.changePhoneNumber.emit(false);
  }

  public checkPhoneNumber(number: string): void {
    if (number && number.length === 11) {
      this.$auth.userWithPhoneNumberExists(number).subscribe((res) => {
        if (res.body === false) {
          this.$message.create('error', Message.PHONE_NOT_FOUND);
          this.formChangePhone.setValue({ phoneNumber: '' });
        }
      });
    }
  }

  public getTimer(): void {
    this.timer = interval(1000).pipe(takeUntil(this.ispause)) as any;
    this.timer.subscribe({
      next: (_: number) => {
        if (this.countdown === 1) {
          this.ispause.next(true);
        }
        this.countdown--;
      },
    });
  }

  public resendCode(): void {
    if (this.countdown < 1) {
      this.$auth.sendSmsCode(this.account).subscribe(() => {
        this.countdown = 60;
        this.getTimer();
      });
    }
  }
}
