import html2canvas from 'html2canvas';

export function leftPad(value: any, totalWidth: number, paddingChar?: any): string {
  const length = totalWidth - value.toString().length + 1;
  return Array(length).join(paddingChar || '0') + value;
}

export function shortenName(value: string): string {
  const spl = value ? value.split(' ') : '';

  return spl.length > 1 ? spl[0] + ' ' + spl[spl.length - 1] : value;
}

export function removeWhiteSpace(_value: string): string {
  if (!_value) {
    return '';
  }

  return _value.replace(/ /g, '');
}

export function removeSpecialCharacters(_value: string): string {
  if (!_value) {
    return '';
  }

  return _value.replace(/[^a-z0-9]/gi, '');
}

export function removeCharacters(_value: string): string {
  if (!_value) {
    return '';
  }

  return _value.replace(/[^\d]+/g, '');
}

export function ignoreNumbers(event: any): void {
  const keyCode = event.keyCode ? event.keyCode : event.wich;

  if (keyCode > 47 && keyCode < 58) {
    return event.preventDefault(true);
  }
}

export function ignoreSpecialCharacters(event: any): void {
  const reg = /[^a-z0-9]/gi;
  if (event.key.match(reg)) {
    return event.preventDefault(true);
  }
}

export function onlyNumbers(event: any): void {
  const keyCode = event.keyCode ? event.keyCode : event.wich;
  if (event.shiftKey) {
    return event.preventDefault(true);
  } else {
    if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
      return event.preventDefault(true);
    }
  }
}

export function ignoreHotkeys(event: any): void {
  if (event.shiftKey) {
    return event.preventDefault(true);
  }
}

export function getSmallModalWidth(): string {
  const width = window.innerWidth;

  if (width <= 1300) {
    return '50%';
  } else if (width <= 1500) {
    return '40%';
  } else {
    return '30%';
  }
}

export function getBigModalWidth(): string {
  const width = window.innerWidth;

  if (width <= 1300) {
    return '70%';
  } else if (width <= 1500) {
    return '60%';
  } else {
    return '50%';
  }
}

export function trimData(data: any): any {
  Object.keys(data).forEach((k) => (data[k] = typeof data[k] === 'string' ? data[k].trim() : data[k]));
  return data;
}

export function formatCNPJ(cnpj: string): string {
  return cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') || '';
}

export function formatCPF(cpf: string): string {
  return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') || '';
}

export function getDayOfWeek(date: Date | string): string | null {
  const daysOfWeek = [
    'Domingo',
    'Segunda - feira',
    'Terça - feira',
    'Quarta - feira',
    'Quinta - feira',
    'Sexta - feira',
    'Sábado',
  ];
  const fullDay = new Date(date);
  const dayOfWeek = fullDay.getDay();
  const today = new Date(Date.now());

  if (today === fullDay) {
    return 'Hoje';
  }

  if (isNaN(dayOfWeek)) {
    return null;
  }

  return daysOfWeek[dayOfWeek];
}

export function downloadImgFromHtml(element: HTMLElement, name?: string, type?: string): void {
  html2canvas(element).then((canvas) => {
    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let imgLink = document.createElement('a');
    imgLink.href = image;
    imgLink.download = `${name || 'image'}.${type || 'png'}`;
    imgLink.click();
  });
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      resolve(reader.result as string);
    };
    reader.onerror = (error): void => {
      reject(error);
    };
  });
}

export function isIOS(): boolean {
  const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform || '';
  if (platform) {
    return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(platform);
  }

  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return true;
  }

  return false;
}
