import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { OrderModel } from '../../../../models/orders/order.model';
import { OrdersService } from '../../../../services/orders/orders.service';
import { TableHeaderModel } from '../../../../models/table/table-header.model';
import { TableSettingsModel } from '../../../../models/table/table-settings.model';

@Component({
  selector: 'app-drawer-order-details',
  templateUrl: './drawer-order-details.component.html',
  styleUrls: ['./drawer-order-details.component.scss'],
})
export class DrawerOrderDetailsComponent implements OnInit {
  @Input() value: string;

  public form!: FormGroup;
  public order: OrderModel;
  public loading: boolean = true;
  public listOfHeadersColumnItens: TableHeaderModel[] = [
    { name: 'Descrição' },
    { name: 'Quantidade' },
    { name: 'Valor' },
  ];
  public listOfHeadersColumnPayment: TableHeaderModel[] = [{ name: 'Método' }, { name: 'Valor' }, { name: 'Data' }];
  public listOfHeadersColumnValue: TableHeaderModel[] = [
    { name: 'Subtotal' },
    { name: 'Descontos' },
    { name: 'Frete' },
    { name: 'Adições' },
    { name: 'Total' },
  ];
  public listOfData: any[] = [];
  public settingValue: TableSettingsModel = {
    checkbox: false,
    pagination: false,
    footer: false,
    expandable: false,
  };

  constructor(
    public $orders: OrdersService,
    private readonly fb: FormBuilder,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.getOrder();
  }

  public getOrder(): void {
    this.$orders.getOrderById(this.value).subscribe({
      next: (res) => {
        if (res?.data?.getOrder) {
          this.order = res.data.getOrder;
          this.createTable();
          this.createForm();
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao buscar a order');
        throw new Error(error);
      },
    });
  }

  public createTable(): void {
    const valuesObject = {
      subtotalCents: this.order.subtotalCents || 0,
      discountCents: this.order.discountCents || 0,
      shippingCents: this.order.shippingCents || 0,
      additionCents: this.order.additionCents || 0,
      totalCents: this.order.totalCents || 0,
    };

    this.listOfData.push(valuesObject);
  }

  public createForm(): void {
    this.form = this.fb.group({
      name: new FormControl(
        this.order?.seller?.company?.businessName ||
          this.order?.seller?.nickname ||
          this.order?.seller?.settings?.pointOfSale?.fantasyName ||
          this.order?.customer?.name
      ),
      id: new FormControl(this.order?.id),
      method: new FormControl(
        this.order?.method || (this.order?.payments?.lenght > 0 ? this.order?.payments[0].method : '-')
      ),
      type: new FormControl(this.order?.deliveryMethod || this.order?.type),
    });

    this.form.controls['name'].disable();
    this.form.controls['id'].disable();
    this.form.controls['method'].disable();
    this.form.controls['type'].disable();
  }
}
