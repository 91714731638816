import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidePartialCNPJ',
})
export class HidePartialCNPJPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }
    let cnpj = value.toString().replace(/[^\d]/g, '');
    cnpj = cnpj.padStart(11, '0');
    return '**.' + cnpj.substr(4, 3) + '.' + cnpj.substr(8, 3) + '/' + cnpj.substr(12, 4) + '-**';
  }
}
