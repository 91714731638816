import { RoleEnum } from '../../enums/roles';
import { AddressModel } from './../address/address.model';

export class UserModel {
  public uid?: string;
  public name: string;
  public cpf: string;
  public email: string;
  public phone: string;
  public address?: AddressModel;
  public insertedAt: string;
  public active?: boolean;
  public externalId?: string;
  public id?: string;
  public role?: RoleEnum;
  public updatedAt?: Date;
  public getUser?: UserModel;
  public listUsers?: UserModel[];
}

type Roles = 'SELLER' | 'MANAGER';
