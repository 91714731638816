import { gql } from 'apollo-angular';

const LIST_STORES_QUERY = gql`
  query ListStores {
    listStores {
      id
    }
  }
`;

const GET_STORE_QUERY = gql`
  query GetStore($storeId: UUID4!) {
    getStore(storeId: $storeId) {
      whatsappNumber
      callNumber
      displayName
      mainSegment
      description
      storeCategory {
        id
      }
      tags {
        name
      }
      openingHours
      pointOfSale {
        city
        countryCode
        line1
        line2
        line3
        neighborhood
        postalCode
        state
      }
    }
  }
`;

const GET_STORE_QUERY_BY_SELLER_ID = gql`
  query GetStoreBySellerId($sellerId: UUID4!) {
    getStoreBySellerId(sellerId: $sellerId) {
      isVisible
      logo
      whatsappNumber
      callNumber
      displayName
      mainSegment
      description
      storeCategory {
        id
        name
      }
      tags {
        name
      }
      openingHours
      pointOfSale {
        city
        countryCode
        line1
        line2
        line3
        neighborhood
        postalCode
        state
      }
    }
  }
`;

const LIST_STORE_CATEGORIES_QUERY = gql`
  query ListStoreCategories {
    listStoreCategories {
      id
      name
    }
  }
`;

export { LIST_STORES_QUERY, GET_STORE_QUERY, LIST_STORE_CATEGORIES_QUERY, GET_STORE_QUERY_BY_SELLER_ID };
