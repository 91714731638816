import { CartModel } from '../cart/cart.model';
import { AddressModel } from './../address/address.model';
import { CreditCardModel } from './../credit-card/credit-card.model';
export class PointModel {
  public id?: string;
  public sellerId?: string; // apagar
  public insertedAt: string;
  public method?: string;
  public cartId?: string;  // collection com seller e order e payment
  public cart?: CartModel;
  public priceToPay?: number;
  public orderId?: string; // orderId atual da navegacao //controle store
  public paymentAt?: string;  // ficar em cart
  public paymentId?: string; // cart
  public selectedType?: 'DELIVERY' | 'PICK_AND_GO' | 'LOCKER';
  public selectedCreditCard?: CreditCardModel;
  public installments?: number; // checkout
  public customerId?: string;
  public customerPhone?: string; // checkout
  public contactId?: string; // apagar
  public shippingAddress?: AddressModel; // manter
  public coupom?: string; // manter
  public needUpdate?: boolean; // apagar
  public description: string;
  public cvv?: string; // checkout
  public auth?: { token: string, refreshToken: string, customToken: string }
  public isAnonymous?: boolean;
}
