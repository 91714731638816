import {
  FormArray,
  FormGroup,
  Validators,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Message } from '../../utils/message';
import { ItemModel } from '../../models/items/item.model';
import { ItemsModel } from '../../models/items/items.model';
import { TableHeaderModel } from '../../models/table/table-header.model';
import { ItemsService } from '../../services/products/items/items.service';

@Component({
  selector: 'app-item-editable-table',
  templateUrl: './item-editable-table.component.html',
  styleUrls: ['./item-editable-table.component.scss'],
})
export class ItemEditableTableComponent implements OnInit, OnChanges {
  @Input() public itemsInput: { newItem: boolean; items: Array<ItemModel> };
  @Input() public sellerId: string;
  @Output() public listItems = new EventEmitter();
  @Output() public newItem = new EventEmitter();
  @Output() public cancelform = new EventEmitter();

  public form!: FormGroup;
  public itemForm!: FormArray;
  public resultTotalValue: number = 0;
  public edit: boolean = false;
  public editIndex: number = -1;
  public hasPassed: boolean = false;
  public detectChanges: boolean = false;
  public loading: boolean = false;

  public listOfHeadersColumn: TableHeaderModel[] = [
    { name: 'ITEM' },
    { name: 'QTD' },
    { name: 'UNIDADE' },
    { name: 'TOTAL' },
  ];

  constructor(
    private $items: ItemsService,
    private readonly fb: FormBuilder,
    private readonly $message: NzMessageService
  ) {}

  public ngOnChanges(changes: any): void {
    if (this.itemForm && changes.itemsInput?.currentValue !== changes.itemsInput?.previusValue) {
      const control = <FormArray>this.form.controls['itemForm'];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i);
      }

      if (changes.itemsInput?.currentValue?.newItem) {
        this.setItem('', 0, 1, undefined);
      }

      changes.itemsInput?.currentValue?.items?.map((val: any) => {
        if (val.id && val.amountCents) {
          this.setItem(val.name, val.amountCents, val.quantity || 1, val.id);
        } else {
          this.setItem(val.description, val.unitPriceCents, val.quantity || 1, val.id);
        }
      });

      this.resultTotalValue = this.getTotalAmount(this.itemForm?.value);
    }
  }

  public ngOnInit(): void {
    this.initForm();

    if (this.itemsInput) {
      const control = <FormArray>this.form.controls['itemForm'];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i);
      }

      if (this.itemsInput?.newItem) {
        this.setItem('', 0, 1, undefined);
      }

      if (Array.isArray(this.itemsInput.items)) {
        this.itemsInput.items?.map((val: any) => {
          if (val.id && val.amountCents) {
            this.setItem(val.name, val.amountCents, 1, val.id);
          } else {
            this.setItem(val.description, val.unitPriceCents, 1, val.id);
          }
        });

        this.resultTotalValue = this.getTotalAmount(this.itemForm?.value);
      }
    }

    this.itemForm.valueChanges.subscribe((res) => {
      if (res) {
        this.resultTotalValue = this.getTotalAmount(res);
      }
    });
  }

  public saveItem(): void {
    const id = this.itemForm.at(this.editIndex)?.get('id')?.value;

    if (id) {
      this.saveEdit(this.editIndex);
    } else {
      this.save(this.editIndex);
    }
  }

  public initForm(): void {
    this.form = this.fb.group({ itemForm: this.fb.array([]) });
    this.itemForm = this.form.controls['itemForm'] as FormArray;
  }

  public cancel(): void {
    this.edit = false;
    this.editIndex = -1;
    this.detectChanges = false;
    this.cancelform.emit();
  }

  public save(index: number): void {
    setTimeout(() => {
      if (this.detectChanges) {
        if (this.itemForm.at(index).valid) {
          if (!this.hasPassed) {
            this.hasPassed = true;
            this.itemForm.at(index).patchValue({
              unitPriceCents: this.itemForm.at(index).get('unitPriceCents').value, // Transform in integer to send correctly.
            });

            this.createItem(index);
          }
        }
      } else {
        this.cancel();
      }
    }, 3000);
  }

  public setItem(description: string, unitPriceCents: number, quantity: number, id: string): void {
    const form = this.fb.group({
      description: [description, Validators.required],
      unitPriceCents: [(unitPriceCents / 100).toFixed(2), [Validators.required, this.minValueValidator(10)]],
      quantity: [quantity, [Validators.required, this.minValueValidator(1)]],
      id: id,
    });

    this.itemForm.push(form);

    if (id === undefined) {
      const index: number = this.itemForm.length - 1;
      this.editQtd(index);
    }
  }

  private createItem(index: number): void {
    try {
      this.loading = true;

      const data: ItemsModel = {
        amountCents: this.itemForm.at(index).value.unitPriceCents,
        name: this.itemForm.at(index).value.description,
        type: 'PRODUCT',
      };

      this.$items.createItem(data).subscribe((response) => {
        if (response.status === 201) {
          this.itemForm.at(index).patchValue({
            id: response.body.id,
          });
          this.newItem.emit(response.body);
          this.edit = false;
          this.editIndex = -1;
          this.hasPassed = false;
          this.detectChanges = false;
        }

        this.loading = false;
      });
    } catch (error: any) {
      this.edit = false;
      this.editIndex = -1;
      this.hasPassed = false;
      this.detectChanges = false;
      this.loading = false;
      throw new Error(error);
    }
  }

  public numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private minValueValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value < minAmount;
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }

  public editQtd(i: number): void {
    this.edit = true;
    this.editIndex = i;
  }

  public detectChange(): void {
    this.detectChanges = true;
  }

  public saveEdit(index: number): void {
    const item = this.itemForm.at(index).value;

    if (this.detectChanges) {
      this.loading = true;

      const data: ItemsModel = {
        sellerId: this.sellerId,
        amountCents: item.unitPriceCents * 100,
        name: item.description,
        quantity: item.quantity,
        id: item.id,
        type: 'PRODUCT',
      };

      this.$items.updateItem(data).subscribe({
        next: (res) => {
          if (res?.data?.updateItem) {
            this.itemForm.at(index).patchValue({
              id: res.data.updateItem?.id,
              quantity: item.quantity,
              description: item.description,
              unitPriceCents: item.unitPriceCents * 100,
            });
            this.listItems.emit(this.itemForm.value);
          }

          this.loading = false;
        },
        error: (error) => {
          this.$message.error(Message.ERROR_UPDATE_ITEM);
          this.loading = false;
          this.edit = false;
          this.editIndex = -1;
          this.detectChanges = false;
          throw new Error(error);
        },
      });
    }
  }

  public getTotalAmount(array: Array<ItemModel>): number {
    return array?.reduce((acc, item) => (acc += item.unitPriceCents * item.quantity), 0);
  }
}
