import moment from 'moment-timezone';
import { differenceInCalendarDays } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { LinkCreatesComponent } from './../link-creates/link-creates.component';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { PaymentLinksService } from 'libs/shared/src/lib/services/payment-links/payment-links.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { LinksModel } from 'libs/shared/src/lib/models/links/links.model';
import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { TableFooterModel } from 'libs/shared/src/lib/models/table/table-footer.model';
import { FooterType } from 'libs/shared/src/lib/types/footer-type';
import { TableHeaderModel } from 'libs/shared/src/lib/models/table/table-header.model';
import { TableSettingsModel } from 'libs/shared/src/lib/models/table/table-settings.model';
import { Message } from 'libs/shared/src/lib/utils/message';
import { environment } from '@gen/environments';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-link-details',
  templateUrl: './link-details.component.html',
  styleUrls: ['./link-details.component.scss'],
})
export class LinkDetailsComponent implements OnInit {
  @ViewChild('activeLinkContainer') activeLinkContainer!: TemplateRef<any>;
  @ViewChild('titleDrawer') titleDrawer!: TemplateRef<any>;
  @Input() param: { id: string; sellerId: string; marketplaceId: string };

  public form!: FormGroup;
  public data: LinksModel = new LinksModel();
  public methods: string = '';
  public listOfData: Array<ItemModel> = [];
  public url: string = '';
  public title: string = '';
  public isBefore: boolean = false;
  public loading: boolean = false;

  public footerData: TableFooterModel = new TableFooterModel();
  public footerType: FooterType = 'editableTableFooter';
  public listOfHeadersColumn: TableHeaderModel[] = [
    { name: 'Descrição' },
    { name: 'QTD' },
    { name: 'Preço Unit' },
    { name: 'Valor' },
  ];
  public settingValue: TableSettingsModel = {
    pagination: false,
    footer: true,
    expandable: false,
    checkbox: false,
    footerName: this.footerType,
  };

  constructor(
    private readonly fb: FormBuilder,
    private $internal: InternalService,
    private $links: PaymentLinksService,
    public readonly $drawerRef: NzDrawerRef,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.setData();
    this.createForm();
  }

  public getLink(data: LinksModel): string {
    const url = environment.appDomain;

    if (data.orderId) {
      return `${url}/orders/${data.orderId}`;
    } else if (data.itemId) {
      return `${url}/items/${data.itemId}`;
    } else if (data.planId) {
      return `${url}/plans/${data.planId}`;
    } else {
      return `${url}/subscriptions/${data.subscriptionId}`;
    }
  }

  public createForm(): void {
    this.form = this.fb.group({
      newExpirationDate: new FormControl(this.data?.expirationDate || false),
    });
  }

  public setData(): void {
    this.$links.getLinkDetail(this.param?.marketplaceId, this.param?.sellerId, this.param.id).subscribe((link) => {
      if (link) {
        this.data = link;
        this.url = this.getLink(this.data);
        this.listOfData = [link.item] || [];
        this.methods = this.$internal.getMethods(this.data?.paymentOptions);
        this.footerData = new TableFooterModel();
        this.footerData.totalAmount = this.data.totalCents;
        this.isBefore = moment(this.data.expirationDate)?.isBefore(new Date());
      }
    });
  }

  public copyContent(): void {
    this.$internal.copyContent(this.url);
  }

  public openPayment(): void {
    window.open(this.url, '_blank');
  }

  public delete(): void {
    this.loading = true;

    try {
      this.$notification.setLoading(true);
      this.$links.deleteLink(this.param?.marketplaceId, this.param?.sellerId, this.data?.id).then(
        (result) => {
          this.loading = false;
          this.$notification.setLoading(false);
          this.$message.success(Message.PAYMENT_LINK_DELETED);
        },
        (error) => {
          this.loading = false;
          this.$notification.setLoading(false);
          this.$message.error(Message.ERROR_DELETED_PAYMENT_LINK);
        }
      );
    } catch (error: any) {
      this.$message.error(Message.ERROR_DELETED_PAYMENT_LINK);
      throw new Error(error);
    } finally {
      this.loading = false;
    }
  }

  async inactiveOrActive(): Promise<void> {
    if (this.data.status === 'INACTIVE' && this.isBefore) {
      try {
        this.loading = true;
        this.$notification.setLoading(true);

        this.data.status = 'ACTIVE';
        this.data.expirationDate = moment(this.form?.get('newExpirationDate').value).format('YYYY-MM-DD');

        this.$links.updateLink(this.data).then(
          (result) => {
            this.loading = false;
            this.$notification.setLoading(false);
            this.$message.success(Message.PAYMENT_LINK_ACTIVE);
          },
          (error) => {
            this.loading = false;
            this.$notification.setLoading(false);
            this.$message.error(Message.ERROR_ACTIVE_PAYMENT_LINK);
          }
        );
      } catch (error: any) {
        this.$message.error(Message.ERROR_ACTIVE_PAYMENT_LINK);
        throw new Error(error);
      } finally {
        this.loading = false;
      }
    } else {
      try {
        this.loading = true;
        this.$notification.setLoading(true);

        if (this.data) {
          if (this.data.status === 'INACTIVE') {
            this.data.status = 'ACTIVE';
          } else {
            this.data.status = 'INACTIVE';
          }

          this.$links.updateLink(this.data).then(
            (result) => {
              this.loading = false;
              this.$notification.setLoading(false);
              this.$message.success(Message.PAYMENT_LINK_INACTIVE);
            },
            (error) => {
              this.loading = false;
              this.$notification.setLoading(false);
              this.$message.error(Message.ERROR_UPDATED_PAYMENT_LINK);
            }
          );
        }
      } catch (error: any) {
        throw new Error(error);
      } finally {
        this.loading = false;
      }
    }
  }

  public editLink(): void {
    this.title = 'Editar Link';

    this.$drawer
      .create({
        nzTitle: this.titleDrawer,
        nzContent: LinkCreatesComponent,
        nzContentParams: { data: this.data },
        nzBodyStyle: { position: 'absolute', top: 0, right: 0, height: '100vh', width: '100%' },
        nzWidth: '500px',
        nzFooter: null,
        nzClosable: false,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.updateLink(res);
        }
      });
  }

  public updateLink(res: LinksModel): void {
    this.loading = true;
    this.$notification.setLoading(true);

    try {
      this.$links.updateLink(res).then(
        (result) => {
          this.loading = false;
          this.$notification.setLoading(false);
          this.$message.success(Message.UPDATED_PAYMENT_LINK);
        },
        (error) => {
          this.loading = false;
          this.$notification.setLoading(false);
          this.$message.error(Message.ERROR_UPDATED_PAYMENT_LINK);
        }
      );
    } catch (error) {
      this.loading = false;
      this.$message.error(error);
    }
  }

  public disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;
}
