import { LinkTypes } from '../../types/link-type';
import { PaymentMethods } from '../../types/payment-methods.type';

export class LinksModel {
  public id?: string;
  public item?: any;
  public itemId?: string;
  public orderId?: string;
  public planId?: string;
  public subscriptionId?: string;
  public allowChangeQuantity?: boolean;
  public collectAddress?: boolean;
  public showConfirmationPage?: boolean;
  public replaceDefaultConfirmationMessage?: boolean;
  public confirmationMessage?: string;
  public redirectAfterConfirmation?: boolean;
  public redirectUrl?: string;
  public expirationDate: string;
  public allowPromotionCodes?: boolean;
  public paymentOptions: Array<PaymentMethods>;
  public status?: string;
  public insertedAt?: string;
  public updatedAt?: string;
  public description?: string;
  public worthlessLink?: boolean;
  public totalCents?: number;
  public type?: LinkTypes;
}
