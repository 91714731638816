import {
  RecaptchaSettings,
  RecaptchaV3Module,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
} from 'ng-recaptcha';
import { PixelModule } from 'ngx-pixel';
import * as Sentry from '@sentry/angular';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Router, RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularFireModule } from '@angular/fire/compat';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { NgxMaskDirective, provideNgxMask, NgxMaskPipe } from 'ngx-mask';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';

import { environment } from '@gen/environments';
import { FromNowPipe } from './pipes/from-now.pipe';
import { ItemTypesPipe } from './pipes/item-types.pipe';
import { PixService } from './services/pix/pix.service';
import { GraphQLModule } from './graphql/graphql.module';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { FeesService } from './services/fees/fees.service';
import { BankService } from './services/bank/bank.service';
import { PlansService } from './services/plans/plans.service';
import { StoreService } from './services/store/store.service';
import { UsersService } from './services/users/users.service';
import { UtilsService } from './services/utils/utils.service';
import { PaymentMethodPipe } from './pipes/payment-method.pipe';
import { StripeService } from './services/stripe/stripe.service';
import { EventsService } from './services/events/events.service';
import { NumberToHoursPipe } from './pipes/number-to-hours.pipe';
import { TicketService } from './services/ticket/ticket.service';
import { OrdersService } from './services/orders/orders.service';
import { TokensService } from './services/tokens/tokens.service';
import { AntDesignModule } from './ant-design/ant-design.module';
import { SystemService } from './services/system/system.service';
import { HidePartialCpfPipe } from './pipes/hide-partial-cpf.pipe';
import { HubspotService } from './services/hubspot/hubspot.service';
import { AddressService } from './services/address/address.service';
import { ReportsService } from './services/reports/reports.service';
import { SellersService } from './services/sellers/sellers.service';
import { ParkingService } from './services/parking/parking.service';
import { HidePartialCNPJPipe } from './pipes/hide-partial-cnpj.pipe';
import { PixComponent } from './components/payment/pix/pix.component';
import { AuthStoreService } from './services/auth/auth.store.service';
import { BalanceService } from './services/balances/balances.service';
import { PaymentsService } from './services/payments/payments.service';
import { PaymentFrequencyPlanPipe } from './pipes/frequency-plan.pipe';
import { InternalService } from './services/internal/internal.service';
import { WebhooksService } from './services/webhooks/webhooks.service';
import { CampaignService } from './services/campaign/campaign.service';
import { FooterComponent } from './components/footer/footer.component';
import { HidePartialEmailPipe } from './pipes/hide-partial-email.pipe';
import { ContactsService } from './services/contacts/contacts.service';
import { ItemsService } from './services/products/items/items.service';
import { CustomerService } from './services/customer/customer.service';
import { MixpanelService } from './services/mixpanel/mixpanel.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { IconsProviderModule } from './ant-design/icons-provider.module';
import { AuthService } from './services/auth/auth-business/auth.service';
import { CouponService } from './services/products/coupon/coupon.service';
import { BlacklistService } from './services/blacklist/blacklist.service';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { DirectiveModule } from './directives/directive/directive.module';
import { LoadingComponent } from './components/loading/loading.component';
import { PaymentComponent } from './components/payment/payment.component';
import { OptionsService } from './services/products/option/option.service';
import { BankComponent } from './components/onboarding/bank/bank.component';
import { CategoryService } from './services/item/category/category.service';
import { OnboardingService } from './services/onboarding/onboarding.service';
import { ProgressiveCountdownPipe } from './pipes/progressive-countdown.pipe';
import { WithdrawlsService } from './services/withdrawals/withdrawls.service';
import { CreditCardService } from './services/credit-card/credit-card.service';
import { ValidationDocuments } from './validators/validation-documents.service';
import { SharedMethodsService } from './services/internal/shared-methods.service';
import { StatusTagComponent } from './components/status-tag/status-tag.component';
import { ReceiptComponent } from './components/payment/receipt/receipt.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { MainTableComponent } from './components/main-table/main-table.component';
import { ApplicationsService } from './services/applications/applications.service';
import { CancellmentsService } from './services/cancellments/cancellments.service';
import { ForgotPasswordService } from './services/external/forgot-password.service';
import { CustomAuthService } from './services/auth/custom-auth/custom-auth.service';
import { SentryErrorHandler } from './services/sentry/sentry-error-handler.service';
import { CompanyComponent } from './components/onboarding/company/company.component';
import { PaymentLinksService } from './services/payment-links/payment-links.service';
import { ModalErrorComponent } from './components/modal-error/modal-error.component';
import { CategoriesService } from './services/products/categories/categories.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { SubscriptionsService } from './services/subscriptions/subscriptions.service';
import { BankSlipComponent } from './components/payment/bank-slip/bank-slip.component';
import { PointOfSalesService } from './services/point-of-sales/point-of-sales.service';
import { GenericPageComponent } from './components/generic-page/generic-page.component';
import { PreviewPageComponent } from './components/preview-page/preview-page.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { BasketButtonComponent } from './components/basket-button/basket-button.component';
import { ModalSuccessComponent } from './components/modal-success/modal-success.component';
import { ModalLoadingComponent } from './components/modal-loading/modal-loading.component';
import { SimpleNavbarComponent } from './components/simple-navbar/simple-navbar.component';
import { ModalGenericComponent } from './components/modal-generic/modal-generic.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { SimpleHeaderComponent } from './components/simple-header/simple-header.component';
import { MallCardInfoComponent } from './components/mall-card-info/mall-card-info.component';
import { LoginRegisterComponent } from './components/login-register/login-register.component';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { SimpleSidenavComponent } from './components/simple-sidenav/simple-sidenav.component';
import { ModalLogoutComponent } from './components/modals/modal-logout/modal-logout.component';
import { ModalFilterComponent } from './components/modals/modal-filter/modal-filter.component';
import { UploadImageComponent } from './components/modals/upload-image/upload-image.component';
import { AddEditAppComponent } from './components/drawers/add-edit-app/add-edit-app.component';
import { InProgressComponent } from './components/onboarding/in-progress/in-progress.component';
import { UserDetailsComponent } from './components/drawers/user-details/user-details.component';
import { SellersEditComponent } from './components/drawers/sellers-edit/sellers-edit.component';
import { ModalPixErrorComponent } from './components/modal-pix-error/modal-pix-error.component';
import { FilterMenuComponent } from './components/sub-header/filter-menu/filter-menu.component';
import { InAnalysisComponent } from './components/onboarding/in-analysis/in-analysis.component';
import { QuantityButtonComponent } from './components/quantity-button/quantity-button.component';
import { ModalBlockedComponent } from './components/modals/modal-blocked/modal-blocked.component';
import { PlanCreateComponent } from './components/drawers/plans/plan-create/plan-create.component';
import { ErrorPaymentComponent } from './components/payment/error-payment/error-payment.component';
import { DrawerSideMenuComponent } from './components/drawer-side-menu/drawer-side-menu.component';
import { ModalRegulationComponent } from './components/modal-regulation/modal-regulation.component';
import { SecondaryButtonComponent } from './components/secondary-button/secondary-button.component';
import { LinkCreatesComponent } from './components/drawers/links/link-creates/link-creates.component';
import { PersonalDataComponent } from './components/onboarding/personal-data/personal-data.component';
import { DrawerDetailsComponent } from './components/drawers/drawer-details/drawer-details.component';
import { SellerDetailsComponent } from './components/drawers/seller-details/seller-details.component';
import { ItemPricesTableComponent } from './components/item-prices-table/item-prices-table.component';
import { LinkDetailsComponent } from './components/drawers/links/link-details/link-details.component';
import { PlanDetailsComponent } from './components/drawers/plans/plan-details/plan-details.component';
import { ScannerAnimationComponent } from './components/scanner-animation/scanner-animation.component';
import { ItemDetailsComponent } from './components/drawers/products/item-details/item-details.component';
import { SuccessPaymentComponent } from './components/payment/success-payment/success-payment.component';
import { ContactDetailsComponent } from './components/drawers/contact-details/contact-details.component';
import { ModalDeleteKeyComponent } from './components/modals/modal-delete-key/modal-delete-key.component';
import { ModalOnboardingComponent } from './components/modals/modal-onboarding/modal-onboarding.component';
import { CheckoutFlowModalComponent } from './components/checkout-flow-modal/checkout-flow-modal.component';
import { ItemEditableTableComponent } from './components/item-editable-table/item-editable-table.component';
import { ProductCardBasketComponent } from './components/product-card-basket/product-card-basket.component';
import { ModalCreateUserComponent } from './components/modals/modal-create-user/modal-create-user.component';
import { OrdersDetailsComponent } from './components/drawers/orders/orders-details/orders-details.component';
import { ValidationPhoneComponent } from './components/security/validation-phone/validation-phone.component';
import { ConfirmationDeleteComponent } from './components/confirmation-delete/confirmation-delete.component';
import { ValidationEmailComponent } from './components/security/validation-email/validation-email.component';
import { SendEmailComponent } from './components/onboarding/email-validation/send-email/send-email.component';
import { ModalTicketWarningComponent } from './components/modal-ticket-warning/modal-ticket-warning.component';
import { ConfirmPhoneNumberComponent } from './components/confirm-phone-number/confirm-phone-number.component';
import { EmailValidationComponent } from './components/onboarding/email-validation/email-validation.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ModalInsertImageComponent } from './components/modals/modal-insert-image/modal-insert-image.component';
import { ModalPreviewPageComponent } from './components/modals/modal-preview-page/modal-preview-page.component';
import { ModalFindAddressComponent } from './components/modals/modal-find-address/modal-find-address.component';
import { ModalChangeEmailComponent } from './components/modals/modal-change-email/modal-change-email.component';
import { ModalChangePhoneComponent } from './components/modals/modal-change-phone/modal-change-phone.component';
import { DrawerCreateUserComponent } from './components/drawers/drawer-create-user/drawer-create-user.component';
import { CodeValidationInputComponent } from './components/code-validation-input/code-validation-input.component';
import { AnonymousCompanyComponent } from './components/onboarding/anonymous-company/anonymous-company.component';
import { FilterCategoriesComponent } from './components/sub-header/filter-categories/filter-categories.component';
import { ModalCreateOptionComponent } from './components/modals/modal-create-option/modal-create-option.component';
import { ModalItemAssociateComponent } from './components/modals/modal-item-associate/modal-item-associate.component';
import { ModalRejectMessageComponent } from './components/modals/modal-reject-message/modal-reject-message.component';
import { ExpandedSubHeaderComponent } from './components/sub-header/expanded-sub-header/expanded-sub-header.component';
import { ModalCreateContactComponent } from './components/drawers/modal-create-contact/modal-create-contact.component';
import { ModalChangePasswordComponent } from './components/modals/modal-change-password/modal-change-password.component';
import { ModalCreateCategoryComponent } from './components/modals/modal-create-category/modal-create-category.component';
import { CreateWebhooksModalComponent } from './components/modals/create-webhooks-modal/create-webhooks-modal.component';
import { DrawerFeesDetailsComponent } from './components/drawers/fees/drawer-fees-details/drawer-fees-details.component';
import { ModalIncorrectImageComponent } from './components/modals/modal-incorrect-image/modal-incorrect-image.component';
import { ModalFilterSynapsesComponent } from './components/modals/modal-filter-synapses/modal-filter-synapses.component';
import { ModalDeleteCreditCardComponent } from './components/modal-delete-credit-card/modal-delete-credit-card.component';
import { DrawerCreateProductComponent } from './components/drawers/drawer-create-product/drawer-create-product.component';
import { DrawerCreateCouponsComponent } from './components/drawers/drawer-create-coupons/drawer-create-coupons.component';
import { ValidateEmailComponent } from './components/onboarding/email-validation/validate-email/validate-email.component';
import { ModalNoValueQrCodeComponent } from './components/modals/modal-no-value-qr-code/modal-no-value-qr-code.component';
import { DrawerCreateEventComponent } from './components/drawers/events/drawer-create-event/drawer-create-event.component';
import { QrCodeSuccessComponent } from './components/modals/modal-no-value-qr-code/qr-code-success/qr-code-success.component';
import { DrawerOrderDetailsComponent } from './components/drawers/orders/drawer-order-details/drawer-order-details.component';
import { QuantityButtonSecondaryComponent } from './components/quantity-button-secondary/quantity-button-secondary.component';
import { DrawerRegisterPixKeyComponent } from './components/drawers/drawer-register-pix-key/drawer-register-pix-key.component';
import { ModalUploadChargebackComponent } from './components/modals/modal-upload-chargeback/modal-upload-chargeback.component';
import { DrawerPaymentsDetailsComponent } from './components/drawers/drawer-payments-details/drawer-payments-details.component';
import { QrCodeGenerateComponent } from './components/modals/modal-no-value-qr-code/qr-code-generate/qr-code-generate.component';
import { ModalWithdrawalRequestComponent } from './components/modals/modal-withdrawal-request/modal-withdrawal-request.component';
import { DrawerCustomersEditComponent } from './components/drawers/customers/drawer-customers-edit/drawer-customers-edit.component';
import { ModalConfirmationDeleteComponent } from './components/modals/modal-confirmation-delete/modal-confirmation-delete.component';
import { TransactionsDetailsComponent } from './components/drawers/transactions/transactions-details/transactions-details.component';
import { ModalWhatsappValidationComponent } from './components/modals/modal-whatsapp-validation/modal-whatsapp-validation.component';
import { ModalConfirmationUpdateComponent } from './components/modals/modal-confirmation-update/modal-confirmation-update.component';
import { DrawerCreateSimpleComponent } from './components/drawers/invoices/simple/drawer-create-simple/drawer-create-simple.component';
import { ModalTermsAndConditionsComponent } from './components/modals/modal-terms-and-conditions/modal-terms-and-conditions.component';
import { DrawerWithdrawDetailsComponent } from './components/drawers/withdraw/drawer-withdraw-details/drawer-withdraw-details.component';
import { DrawerSimpleDetailsComponent } from './components/drawers/invoices/simple/drawer-simple-details/drawer-simple-details.component';
import { DrawerCustomerDetailsComponent } from './components/drawers/customers/drawer-customer-details/drawer-customer-details.component';
import { DrawerCreateBlacklistComponent } from './components/drawers/blacklist/drawer-create-blacklist/drawer-create-blacklist.component';
import { ModalAddVariationProductComponent } from './components/modals/modal-add-variation-product/modal-add-variation-product.component';
import { ModalCreatePixKeySuccessComponent } from './components/modals/modal-create-pix-key-success/modal-create-pix-key-success.component';
import { QrCodeConfirmationComponent } from './components/modals/modal-no-value-qr-code/qr-code-confirmation/qr-code-confirmation.component';
import { SubscriptionDetailsComponent } from './components/drawers/invoices/subscriptions/subscription-details/subscription-details.component';
import { ModalPixQrCodeConfirmationComponent } from './components/modals/modal-pix-qr-code-confirmation/modal-pix-qr-code-confirmation.component';
import { ModalDeleteKeySuccessComponent } from './components/modals/modal-delete-key/modal-delete-key-success/modal-delete-key-success.component';
import { DrawerSubscriptionDetailsComponent } from './components/drawers/subscription/drawer-subscription-details/drawer-subscription-details.component';
import { ModalDeleteKeyConfirmationComponent } from './components/modals/modal-delete-key/modal-delete-key-confirmation/modal-delete-key-confirmation.component';
import { DrawerPendingCompanyDetailsComponent } from './components/drawers/pending-company/drawer-pending-company-details/drawer-pending-company-details.component';
import { DrawerPendingCompanyCreateUserComponent } from './components/drawers/pending-company/drawer-pending-company-create-user/drawer-pending-company-create-user.component';

const customCurrencyMaskConfig = {
  align: '',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};
const PIPES = [
  FromNowPipe,
  ItemTypesPipe,
  PrettyJsonPipe,
  CustomDatePipe,
  PaymentMethodPipe,
  NumberToHoursPipe,
  HidePartialCpfPipe,
  HidePartialCNPJPipe,
  HidePartialEmailPipe,
  ProgressiveCountdownPipe,
  PaymentFrequencyPlanPipe,
];
const DRAWERS = [
  DrawerDetailsComponent,
  DrawerSideMenuComponent,
  DrawerCreateUserComponent,
  DrawerFeesDetailsComponent,
  DrawerCreateEventComponent,
  DrawerOrderDetailsComponent,
  DrawerCreateSimpleComponent,
  DrawerCreateProductComponent,
  DrawerSimpleDetailsComponent,
  DrawerCustomersEditComponent,
  DrawerCreateCouponsComponent,
  DrawerRegisterPixKeyComponent,
  DrawerPaymentsDetailsComponent,
  DrawerWithdrawDetailsComponent,
  DrawerCustomerDetailsComponent,
  DrawerCreateBlacklistComponent,
  DrawerSubscriptionDetailsComponent,
  DrawerPendingCompanyDetailsComponent,
  DrawerPendingCompanyCreateUserComponent,
];
const MODALS = [
  ModalErrorComponent,
  ModalFilterComponent,
  ModalLogoutComponent,
  ModalBlockedComponent,
  ModalSuccessComponent,
  ModalGenericComponent,
  ModalLoadingComponent,
  ModalPixErrorComponent,
  ModalDeleteKeyComponent,
  ModalOnboardingComponent,
  ModalRegulationComponent,
  ModalCreateUserComponent,
  ModalInsertImageComponent,
  ModalChangeEmailComponent,
  ModalFindAddressComponent,
  ModalChangePhoneComponent,
  ModalPreviewPageComponent,
  ModalCreateOptionComponent,
  ModalItemAssociateComponent,
  ModalTicketWarningComponent,
  ModalNoValueQrCodeComponent,
  ModalCreateContactComponent,
  ModalRejectMessageComponent,
  ModalChangePasswordComponent,
  ModalFilterSynapsesComponent,
  ModalCreateCategoryComponent,
  CreateWebhooksModalComponent,
  ModalIncorrectImageComponent,
  ModalUploadChargebackComponent,
  ModalDeleteCreditCardComponent,
  ModalDeleteKeySuccessComponent,
  ModalWithdrawalRequestComponent,
  ModalTermsAndConditionsComponent,
  ModalConfirmationDeleteComponent,
  ModalConfirmationUpdateComponent,
  ModalWhatsappValidationComponent,
  ModalAddVariationProductComponent,
  ModalCreatePixKeySuccessComponent,
  ModalPixQrCodeConfirmationComponent,
  ModalDeleteKeyConfirmationComponent,
];
const BUTTONS = [
  BasketButtonComponent,
  PrimaryButtonComponent,
  QuantityButtonComponent,
  SecondaryButtonComponent,
  QuantityButtonSecondaryComponent,
];
const SERVICES = [
  PixService,
  AuthService,
  FeesService,
  BankService,
  UsersService,
  ItemsService,
  PlansService,
  UtilsService,
  StoreService,
  StripeService,
  OrdersService,
  SystemService,
  EventsService,
  TicketService,
  CouponService,
  TokensService,
  ReportsService,
  ParkingService,
  OptionsService,
  BalanceService,
  HubspotService,
  SellersService,
  AddressService,
  CampaignService,
  MixpanelService,
  InternalService,
  CategoryService,
  ContactsService,
  PaymentsService,
  WebhooksService,
  CustomerService,
  BlacklistService,
  AuthStoreService,
  OnboardingService,
  CategoriesService,
  CreditCardService,
  WithdrawlsService,
  CustomAuthService,
  PointOfSalesService,
  PaymentLinksService,
  CancellmentsService,
  ApplicationsService,
  NotificationsService,
  SharedMethodsService,
  SubscriptionsService,
  ForgotPasswordService,
];

@NgModule({
  declarations: [
    ...PIPES,
    ...MODALS,
    ...DRAWERS,
    ...BUTTONS,
    PixComponent,
    BankComponent,
    HeaderComponent,
    FooterComponent,
    GenericPageComponent,
    CodeValidationInputComponent,
    SubHeaderComponent,
    SimpleHeaderComponent,
    ScannerAnimationComponent,
    MallCardInfoComponent,
    ConfirmationDeleteComponent,
    ExpandedSubHeaderComponent,
    FilterMenuComponent,
    FilterCategoriesComponent,
    NavbarComponent,
    SimpleNavbarComponent,
    ReceiptComponent,
    CompanyComponent,
    PaymentComponent,
    LoadingComponent,
    SidenavComponent,
    SimpleSidenavComponent,
    BankSlipComponent,
    MainTableComponent,
    StatusTagComponent,
    SendEmailComponent,
    ImageCropperComponent,
    PreviewPageComponent,
    InAnalysisComponent,
    InProgressComponent,
    PlanCreateComponent,
    AddEditAppComponent,
    ProductCardComponent,
    ProductCardBasketComponent,
    PlanDetailsComponent,
    ItemDetailsComponent,
    LinkCreatesComponent,
    LinkDetailsComponent,
    UploadImageComponent,
    PersonalDataComponent,
    ErrorPaymentComponent,
    OrdersDetailsComponent,
    PrimaryButtonComponent,
    ValidateEmailComponent,
    PrivacyPolicyComponent,
    QrCodeSuccessComponent,
    SuccessPaymentComponent,
    ContactDetailsComponent,
    QrCodeGenerateComponent,
    EmailValidationComponent,
    ItemPricesTableComponent,
    ValidationEmailComponent,
    ValidationPhoneComponent,
    AnonymousCompanyComponent,
    ItemEditableTableComponent,
    TermsAndConditionsComponent,
    QrCodeConfirmationComponent,
    SubscriptionDetailsComponent,
    TransactionsDetailsComponent,
    UserDetailsComponent,
    SellersEditComponent,
    SellerDetailsComponent,
    LoginRegisterComponent,
    CheckoutFlowModalComponent,
    ConfirmPhoneNumberComponent
  ],
  imports: [
    NgxMaskPipe,
    FormsModule,
    CommonModule,
    RouterModule,
    GraphQLModule,
    DragDropModule,
    NgxQRCodeModule,
    PdfViewerModule,
    AntDesignModule,
    DirectiveModule,
    NgxMaskDirective,
    HttpClientModule,
    RecaptchaV3Module,
    ImageCropperModule,
    ReactiveFormsModule,
    IconsProviderModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    PixelModule.forRoot({ enabled: true, pixelId: environment.metaPixelId }),
  ],
  exports: [
    ...DRAWERS,
    ...PIPES,
    ...BUTTONS,
    ...MODALS,
    FormsModule,
    NgxMaskPipe,
    GenericPageComponent,
    PixComponent,
    ConfirmationDeleteComponent,
    BankComponent,
    GraphQLModule,
    DragDropModule,
    NgxQRCodeModule,
    NavbarComponent,
    SimpleNavbarComponent,
    CodeValidationInputComponent,
    AntDesignModule,
    PdfViewerModule,
    HeaderComponent,
    SimpleHeaderComponent,
    FooterComponent,
    NgxChartsModule,
    ReceiptComponent,
    NgxMaskDirective,
    SubHeaderComponent,
    FilterMenuComponent,
    MallCardInfoComponent,
    ProductCardComponent,
    ProductCardBasketComponent,
    ScannerAnimationComponent,
    FilterCategoriesComponent,
    ExpandedSubHeaderComponent,
    HttpClientModule,
    CompanyComponent,
    SidenavComponent,
    SimpleSidenavComponent,
    LoadingComponent,
    PaymentComponent,
    BankSlipComponent,
    NgxCurrencyModule,
    StatusTagComponent,
    PreviewPageComponent,
    SendEmailComponent,
    MainTableComponent,
    InAnalysisComponent,
    ReactiveFormsModule,
    InProgressComponent,
    AddEditAppComponent,
    IconsProviderModule,
    ImageCropperComponent,
    PlanCreateComponent,
    LinkCreatesComponent,
    ItemDetailsComponent,
    PlanDetailsComponent,
    LinkDetailsComponent,
    UploadImageComponent,
    ErrorPaymentComponent,
    PersonalDataComponent,
    ValidateEmailComponent,
    OrdersDetailsComponent,
    QrCodeSuccessComponent,
    PrivacyPolicyComponent,
    QrCodeGenerateComponent,
    SuccessPaymentComponent,
    ContactDetailsComponent,
    ItemPricesTableComponent,
    EmailValidationComponent,
    AngularFireStorageModule,
    ValidationPhoneComponent,
    ValidationEmailComponent,
    AnonymousCompanyComponent,
    ItemEditableTableComponent,
    QrCodeConfirmationComponent,
    TermsAndConditionsComponent,
    SubscriptionDetailsComponent,
    TransactionsDetailsComponent,
    UserDetailsComponent,
    SellersEditComponent,
    SellerDetailsComponent,
    LoginRegisterComponent,
    CheckoutFlowModalComponent,
    ConfirmPhoneNumberComponent
  ],
  providers: [
    ...PIPES,
    ...SERVICES,
    FilterMenuComponent,
    SubHeaderComponent,
    ExpandedSubHeaderComponent,
    ValidationDocuments,
    { provide: REGION, useValue: 'southamerica-east1' },
    [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_V3_KEY }],
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.RECAPTCHA_V3_KEY } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'pt',
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: false,
    },
    provideNgxMask(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
