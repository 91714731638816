import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { UploadImageComponent } from '../upload-image/upload-image.component';
import { ModalItemAssociateComponent } from './../modal-item-associate/modal-item-associate.component';
import { CategoryModel } from '../../../models/categories/categories.model';
import { ItemImagesModel } from '../../../models/items/item-images.model';

@Component({
  selector: 'app-modal-create-category',
  templateUrl: './modal-create-category.component.html',
  styleUrls: ['./modal-create-category.component.scss'],
})
export class ModalCreateCategoryComponent implements OnInit {
  @Input() public data: CategoryModel = new CategoryModel();

  public categoryForm!: FormGroup;
  public title: string = 'Criar categoria';
  public loading: boolean = false;
  public file: ItemImagesModel = {
    label: 'Foto de capa',
    file: {
      url: '',
    },
  };

  constructor(
    private readonly fb: FormBuilder,
    public readonly $modalRef: NzModalRef,
    private readonly $modal: NzModalService
  ) {
    this.createForm();
  }

  public ngOnInit(): void {
    if (this.data?.id) {
      setTimeout(() => {
        this.updateForm();
      }, 0);
    }
  }

  private updateForm(): void {
    this.title = 'Editar categoria';
    this.categoryForm.patchValue({
      id: this.data?.id,
      file: this.data?.file,
      url: this.data?.imageUrl,
      name: this.data?.name,
    });

    if (this.data?.imageUrl) {
      this.file.file.url = this.data.imageUrl;
    }
  }

  private createForm(): void {
    if (this.data?.imageUrl) {
      this.file.file = { url: this.data?.imageUrl };
    }

    this.categoryForm = this.fb.group({
      id: new FormControl(''),
      file: new FormControl(''),
      url: new FormControl(''),
      name: new FormControl('', [Validators.required]),
    });
  }

  public setHandleSubmit(): void {
    let payload = {
      ...this.categoryForm.value,
    };
    this.$modalRef.close(payload);
  }

  public openUploadImageComponent(): void {
    this.$modal
      .create({
        nzClosable: false,
        nzStyle: { display: 'flex', margin: 'auto' },
        nzComponentParams: {
          images: this.file?.file ? [this.file?.file?.url] : [],
          maxInput: 1,
        },
        nzTitle: 'Enviar Imagem',
        nzContent: UploadImageComponent,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.file.file = res[0];
          this.categoryForm.get('file').setValue(res[0]);
        }
      });
  }

  public openModalAssociateItems(): void {
    this.$modal
      .create({
        nzContent: ModalItemAssociateComponent,
        nzFooter: null,
        nzClosable: false,
        nzTitle: `Itens para ${this.categoryForm.get('name').value}`,
        nzComponentParams: { data: this.categoryForm.value },
      })
      .afterClose.subscribe((res) => {
        if (res) {
          const data: CategoryModel = {
            ...this.categoryForm.value,
            itemsId: res.itemsId,
          };

          this.$modalRef.close(data);
        }
      });
  }

  public deleteImg(): void {
    this.file.file = null;
    this.categoryForm.get('file').setValue(null);
  }
}
