import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';

import { SellerModel } from '../../../models/sellers/sellers.model';

import { StateManagementService } from '../../../state-management/state-management.service';
import { OnboardingService } from '../../../services/onboarding/onboarding.service';
import { StepProgressModel } from '../../../models/onboarding/step-progress.model';
import { OnBoardingModel } from '../../../models/onboarding/onboarding.model';

@Component({
  selector: 'app-modal-onboarding',
  templateUrl: './modal-onboarding.component.html',
  styleUrls: ['./modal-onboarding.component.scss'],
})
export class ModalOnboardingComponent implements OnInit {
  public loading: boolean = false;
  public disabled: boolean = true;
  public realCurrentProgress: number = 0;
  public currentProgress: number = 0;
  public onboardingCollection: OnBoardingModel = new OnBoardingModel();
  public steps: StepProgressModel[] = [
    { id: 0, title: 'Dados pessoais', disabled: true },
    { id: 1, title: 'Validação de email', disabled: true },
    { id: 2, title: 'Dados da empresa', disabled: true },
    { id: 3, title: 'Dados bancários', disabled: true },
  ];
  public seller: SellerModel = new SellerModel();

  public bankSubmit: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public companySubmit: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public anonymousCompanySubmit: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public emailSubmit: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public personalSubmit: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly router: Router,
    private readonly modalRef: NzModalRef,
    private $onboarding: OnboardingService,
    private $notification: StateManagementService
  ) {}

  ngOnInit(): void {
    this.getProgress();
    this.getOnboardingCollection();
    this.getDashboardActivationCollection();
    this.getBtnLoading();
    this.getBtnDisabled();
    this.getSeller();
  }

  private getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.seller = res;
      }
    });
  }

  public onIndexChange(index: number): void {
    this.currentProgress = index;
  }

  private getProgress(): void {
    this.$notification.onboardingsProgress.subscribe((res) => {
      this.realCurrentProgress = this.$onboarding.getStepsByOnboardingProgress(res);
      this.currentProgress = this.realCurrentProgress;

      // Reset steps
      this.steps.forEach((step) => (step.disabled = true));

      // If already dont create seller, block others steps.
      if (this.realCurrentProgress < 3) {
        this.steps.forEach((step) => {
          if (this.realCurrentProgress >= step.id) {
            step.disabled = false;
          }
        });
      } else {
        this.steps.forEach((step) => {
          if (step.id >= 3) {
            step.disabled = false;
          }
        });
      }
    });
  }

  private getOnboardingCollection(): void {
    this.$notification.onboardingsCollection.subscribe((collection) => {
      if (collection) {
        this.onboardingCollection = collection;
        this.$onboarding.setInitOnboarding(collection);
      }
    });
  }

  private getBtnLoading(): void {
    this.$notification.onboardingsBtnLoading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  private getBtnDisabled(): void {
    this.$notification.onboardingsBtnDisabled.subscribe((disabled) => {
      this.disabled = disabled;
    });
  }

  public validateSellerStatus(type: string): boolean {
    if (type === 'PROGRESS') {
      if (!this.seller?.id) {
        return true;
      }

      return ['NEW', 'IN_PROGRESS'].includes(this.seller.status);
    } else {
      return [
        'IN_ANALYSIS',
        'REPROCESS_REVIEW',
        'FAILED_IN_GETNET',
        'FAILED',
        'PENDING_IN_GETNET',
        'IN_COMPLIANCE_REVIEW',
      ].includes(this.seller.status);
    }
  }

  public closeModal(): void {
    this.modalRef.destroy();
  }

  private clearSubmissions(): void {
    this.personalSubmit.next(false);
    this.emailSubmit.next(false);
    this.companySubmit.next(false);
    this.anonymousCompanySubmit.next(false);
    this.bankSubmit.next(false);
  }

  public changeRoute(): void {
    this.router.navigate(['/internal/settings/documents']);
    this.closeModal();
  }

  public handleSubmit(): void {
    this.clearSubmissions();

    switch (this.currentProgress) {
      case 0:
        this.personalSubmit.next(true);
        break;
      case 1:
        this.emailSubmit.next(true);
        break;
      case 2:
        if (this.onboardingCollection?.typeOfTrade?.data?.typeTrade === 'company') {
          this.companySubmit.next(true);
        } else {
          this.anonymousCompanySubmit.next(true);
        }
        break;
      case 3:
        this.bankSubmit.next(true);
        break;
      default:
      case 4:
        break;
    }
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection) {
        this.$onboarding.setFinalOnboarding(collection);
      }
    });
  }
}
