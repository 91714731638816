import { first } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

import { Message } from '../../utils/message';
import { TableType } from '../../types/table-type';
import { OrderModel } from '../../models/orders/order.model';
import { DocumentModel } from '../../models/document/document.model';
import { SellersService } from '../../services/sellers/sellers.service';
import { TableHeaderModel } from '../../models/table/table-header.model';
import { TableFooterModel } from '../../models/table/table-footer.model';
import { InternalService } from '../../services/internal/internal.service';
import { UserClaimsModel } from '../../models/user-claims/user-claims.model';
import { TableSettingsModel } from '../../models/table/table-settings.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.scss'],
})
export class MainTableComponent implements OnInit {
  @ViewChild('itemFooter', { read: TemplateRef }) itemFooter: TemplateRef<any>;
  @ViewChild('walletFooter', { read: TemplateRef }) walletFooter: TemplateRef<any>;
  @ViewChild('paymentFooter', { read: TemplateRef }) paymentFooter: TemplateRef<any>;
  @ViewChild('receiveFooter', { read: TemplateRef }) receiveFooter: TemplateRef<any>;
  @ViewChild('editableTableFooter', { read: TemplateRef }) editableTableFooter: TemplateRef<any>;

  @Input() public settingValue?: TableSettingsModel = {
    pagination: true,
    footer: false,
    expandable: false,
    checkbox: true,
  };
  @Input() public footerData: TableFooterModel = {
    countQuantity: 0,
    totalPayments: 0,
    countPayments: 0,
    countRefunds: 0,
    totalRefunds: 0,
    totalAmount: 0,
    totalCredit: 0,
    totalDebit: 0,
    totalBalance: 0,
  };
  @Input() public listOfData: any[] = [];
  @Input() public listOfHeaders: TableHeaderModel[] = [];
  @Input() public tableType: TableType = null;
  @Input() public widthConfig: string[] = [];

  @Output() public actionBtn = new EventEmitter();
  @Output() public showDetailsDrawer = new EventEmitter();

  public formOrderArray?: FormArray;
  public allChecked: boolean = false;
  public fileList: NzUploadFile[] = [];
  public indeterminate: boolean = false;
  public documents: DocumentModel[] = [];
  public displayData: readonly any[] = [];
  public user: UserClaimsModel = {} as UserClaimsModel;

  constructor(
    private $seller: SellersService,
    private $internal: InternalService,
    private readonly $message: NzMessageService,
    private readonly activeRoute: ActivatedRoute,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.activeRoute.url.subscribe();

    this.getUser();

    if (this.tableType === 'ORDER') {
      this.createOrderForm();
    }
  }

  private createOrderForm(): void {
    this.formOrderArray = new FormArray(
      this.listOfData.map(
        (x: any) =>
          new FormGroup({
            deliveryStatus: new FormControl({
              value: x.deliveryStatus || '',
              disabled: ['CREATED', 'PENDING', 'PARTIALLY_PAID'].includes(x.status) || !x.deliveryMethod,
            }),
          })
      )
    );
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.user = res;
      }
    });
  }

  public currentPageDataChange($event: readonly any[]): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  public refreshStatus(): void {
    const validData = this.displayData.filter((value) => !value.disabled);
    const allChecked = validData.length > 0 && validData.every((value) => value.checked === true);
    const allUnChecked = validData.every((value) => !value.checked);
    const checkedData = validData.filter((value) => value.checked);

    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
    this.$notification.setCheckedData(checkedData);
  }

  public checkAll(value: boolean): void {
    this.displayData.forEach((data) => {
      if (!data.disabled) {
        data.checked = value;
      }
    });

    this.refreshStatus();
  }

  public callToAction(action: string, data?: any): void {
    this.actionBtn.emit({ action, data });
  }

  public showDetails(data: any): void {
    this.showDetailsDrawer.next(data);
  }

  public copyContent(content: string): void {
    this.$internal.copyContent(content);
  }

  public changeDeliveryStatus(order: OrderModel): void {
    order.loading = true;
    const newOrder = Object.assign({}, order, { deliveryStatus: this.getControl('deliveryStatus', order).value });
    this.callToAction('EDIT', newOrder);
  }

  public getControl(controlName: string, order: OrderModel): FormControl {
    const index = this.listOfData.findIndex((data) => data.id === order.id);
    return (this.formOrderArray.at(index) as FormGroup)?.get(controlName) as FormControl;
  }

  public isStatusCancelled(status: string): boolean {
    return ['canceled_without_confirmation', 'denied_mgm', 'reversal'].includes(status);
  }

  public async onSaveImage(event: any, docData: any): Promise<void> {
    try {
      docData.loading = true;

      if (!event.target?.files[0]) {
        this.$message.error(Message.ERROR_LOADING_DOCUMENT);
        return;
      }

      const content = await this.getBase64(event.target?.files[0]);

      if (!content) {
        this.$message.error(Message.ERROR_LOADING_DOCUMENT);
        return;
      }

      const data = {
        name: docData.id,
        contentType: event.target?.files[0]?.type,
        content: content.split(',')[1],
      };

      const response = await this.$seller.uploadSellerDocuments(data).pipe(first()).toPromise();

      if (response.status === 201) {
        this.$message.success(Message.SUCCESS_SEND_DOCUMENTS);
      }
    } catch (error: any) {
      this.$message.error(Message.ERROR_CONECTION);
      throw new Error(error);
    } finally {
      setTimeout(() => {
        docData.loading = false;
      }, 2000);
    }
  }

  private getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(reader.result as string);
      };
      reader.onerror = (error): void => {
        reject(error);
      };
    });
  }
}
