import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { environment } from '@gen/environments';
import {
  BAN_SELLER_MUTATION,
  BLOCK_SELLER_MUTATION,
  EDIT_SELLER_MUTATION,
  REPROCESS_SELLER_MUTATION,
  UNBLOCK_SELLER_MUTATION,
} from '../../graphql/mutations/seller.mutations';
import { SellerModel } from '../../models/sellers/sellers.model';
import { DocumentModel } from '../../models/document/document.model';
import { ResponseModel } from '../../models/response/response.model';
import { FilterSellersParams } from '../../models/sellers/filter-sellers-params.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { GET_SELLER, GET_SELLER_BUSINESS, LIST_SELLERS_QUERY } from '../../graphql/queries/seller.queries';

@Injectable()
export class SellersService {
  public marketplaceId?: string = environment.marketplaceId;

  constructor(
    private readonly apollo: Apollo,
    private $storage: AngularFireStorage,
    private $functions: AngularFireFunctions,
    private readonly fireStore: AngularFirestore,
    private readonly $notification: StateManagementService
  ) {}

  public getSellerImageURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/avatar`).getDownloadURL();
  }

  public getAndSetSellerImageUrl(seller: SellerModel): void {
    this.$storage
      .ref(`sellers/${seller.id!}/avatar`)
      .getDownloadURL()
      .subscribe((imageUrl) => {
        this.$notification.setSellerImageUrl(imageUrl);
      });
  }

  public getSellerImageBannerURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/banner`).getDownloadURL();
  }

  public getSeller(sellerId?: string): Observable<SellerModel> | undefined {
    return this.fireStore
      .doc(`marketplaces/${this.marketplaceId}/sellers/${sellerId}`)
      .valueChanges() as Observable<SellerModel>;
  }

  public setSellerInfo(sellerId: string): void {
    if (sellerId && sellerId !== undefined) {
      this.getSellerImageBannerURL(sellerId).subscribe((banner) => {
        this.$notification.setBanner(banner);
      });

      this.getSellerImageURL(sellerId).subscribe((avatar) => {
        this.$notification.setAvatar(avatar);
      });

      this.getSeller(sellerId)?.subscribe((seller) => {
        this.$notification.setSeller(seller);
      });
    }
  }

  public updateExternalSeller(data: Partial<SellerModel>): Observable<ResponseModel<SellerModel>> {
    return this.$functions.httpsCallable('updateSeller')(data);
  }

  public sellerWithEmailExists(email: string): Observable<ResponseModel<SellerModel>> {
    return this.$functions.httpsCallable('sellerWithEmailExists')(email);
  }

  public uploadSellerDocuments(data: {
    name: string;
    content: string;
    contentType: string;
  }): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('uploadSellerDocuments')(data);
  }

  public getSellerDocuments(sellerId: string): Observable<DocumentModel[]> {
    return this.fireStore
      .collection<DocumentModel>(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/documents`)
      .valueChanges();
  }

  public updateSeller(sellerId: string, data: Partial<SellerModel>): void {
    this.fireStore.collection(`marketplaces/${this.marketplaceId}/sellers`)?.doc(sellerId).set(data, { merge: true });
  }

  public getSellerImageLogoURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/avatar`).getDownloadURL();
  }

  public getSellerList(filter: FilterSellersParams): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: LIST_SELLERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { filter },
    }).valueChanges;
  }

  public getSellerById(id: string): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: GET_SELLER,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public getSellerByIdBusiness(id: string): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: GET_SELLER_BUSINESS,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public banSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: BAN_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public blockSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: BLOCK_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public unblockSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UNBLOCK_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public reprocessSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: REPROCESS_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public editSeller(id: string, params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: EDIT_SELLER_MUTATION,
      variables: {
        id,
        params,
      },
    });
  }
}
