import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, take } from 'rxjs';

import { environment } from '@gen/environments';
import { CategoryModel } from 'libs/shared/src/lib/models/categories/categories.model';
import { ItemsModel } from 'libs/shared/src/lib/models/items/items.model';
import { Apollo, Query } from 'apollo-angular';
import {
  CREATE_ITEM_CATEGORY_MUTATION,
  DELETE_ITEM_CATEGORY_MUTATION,
  UPDATE_ITEM_CATEGORY_MUTATION,
} from '../../../graphql/mutations/category.mutations';
import { LIST_ITEM_CATEGORIES_QUERY } from '../../../graphql/queries/category.queries';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class CategoriesService {
  marketplaceId: string = environment.marketplaceId;

  constructor(
    private readonly apollo: Apollo,
    private afStore: AngularFirestore,
    private $functions: AngularFireFunctions
  ) {
    this.marketplaceId = environment.marketplaceId;
  }

  public getCategoryDetails(sellerId: string, categoryId: string): Observable<CategoryModel> | undefined {
    return this.afStore
      .doc(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/categories/${categoryId}`)
      .valueChanges() as Observable<CategoryModel>;
  }

  public setCategoryName(sellerId: string, productList: Array<ItemsModel>): Observable<Array<ItemsModel>> {
    const list = new BehaviorSubject<Array<ItemsModel>>([]);
    const lists = list.asObservable();

    this.listCategories(sellerId)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          productList.map((product) => {
            const category: CategoryModel = res.find((category) => category.id === product.itemCategoryId);
            if (category) {
              product.categoryName = category.name;
            }
          });

          list.next(productList);
        }
      });

    return lists;
  }

  public groupByCategory(products: Array<ItemsModel>): Object {
    return products.reduce((r, a) => {
      r[a.itemCategoryId] = r[a.itemCategoryId] || [];
      r[a.itemCategoryId].push(a);
      return r;
    }, Object.create(null));
  }

  public createCategory(data: CategoryModel): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_CATEGORY_MUTATION,
      variables: data,
    });
  }

  public getCategoryList(sellerId: string, filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEM_CATEGORIES_QUERY,
      variables: { sellerId, filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public deleteCategory(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_CATEGORY_MUTATION,
      variables: { id },
    });
  }

  public updateCategory(data: Partial<CategoryModel>): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_CATEGORY_MUTATION,
      variables: data,
    });
  }

  public batchAssocCategory(data: CategoryModel): Observable<any> {
    return this.$functions.httpsCallable('batchAssocCategory')(data);
  }

  public listCategories(sellerId: string): Observable<CategoryModel[]> | undefined {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/categories`, (ref) =>
        ref.orderBy('name', 'asc')
      )
      .valueChanges() as Observable<CategoryModel[]>;
  }
}
