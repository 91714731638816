import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-terms-and-conditions',
  templateUrl: './modal-terms-and-conditions.component.html',
  styleUrls: ['./modal-terms-and-conditions.component.scss'],
})
export class ModalTermsAndConditionsComponent implements OnInit {
  public modalForm: FormGroup;

  constructor(private modalRef: NzModalRef, private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.modalForm = this.fb.group({
      termsAccept: new FormControl(false, Validators.requiredTrue),
    });
  }

  public closed(): void {
    this.modalRef.destroy(true);
  }
}
