import { Subject } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { SharedMethodsService } from '../../../services/internal/shared-methods.service';
import { OptionsService } from '../../../services/products/option/option.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { ItemsModel } from '../../../models/items/items.model';
import { SelectModel } from '../../../models/select/select.model';
import { OptionModel, TypeModel } from '../../../models/option/option.model';
import { ItemPriceModel } from '../../../models/items/item-price.model';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-modal-add-variation-product',
  templateUrl: './modal-add-variation-product.component.html',
  styleUrls: ['./modal-add-variation-product.component.scss'],
})
export class ModalAddVariationProductComponent implements OnInit {
  @Input() public data: ItemsModel;

  public updateEvent: Subject<void> = new Subject<void>();
  public getItemPricesEvent: Subject<void> = new Subject<void>();
  public itemOptionsList: Array<SelectModel> = [];
  public form!: FormGroup;
  public options!: FormArray;
  public itemPrices: Array<ItemPriceModel> = [];
  public emptyOption: OptionModel = new OptionModel();
  public emptyType: TypeModel = new TypeModel();
  public sellerId: string = '';

  constructor(
    private readonly fb: FormBuilder,
    private $shared: SharedMethodsService,
    public readonly $modalRef: NzDrawerRef,
    private $optionsService: OptionsService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.initList();
  }

  public updateItemPrices(): void {
    this.updateEvent.next();
  }

  public drop(event: CdkDragDrop<any>, index: number): void {
    const types = this.options.at(index)?.get('types').value;

    moveItemInArray(types, event.previousIndex, event.currentIndex);
  }

  public createForm(): void {
    this.form = this.fb.group({
      options: this.fb.array([]),
      valueByVariation: new FormControl(false),
    });

    this.options = this.form.controls['options'] as FormArray;
    this.initVariations();
  }

  public removeOption(index: number): void {
    this.options.removeAt(index);
    this.updateItemPrices();
  }

  public initVariations(): void {
    if (this.data?.itemOptions?.length > 0) {
      this.data?.itemOptions?.forEach((element, index: number) => {
        this.addVariation(element, index);
      });
    } else {
      this.addVariation(this.emptyOption, 0);
    }

    this.updateItemPrices();
  }

  public addVariation(option: OptionModel, index: number): void {
    const form = this.fb.group({
      name: new FormControl(option.name, [Validators.required]),
      displayName: new FormControl(option.displayName),
      types: new FormArray([this.initTypes()], [Validators.required]),
      id: new FormControl(option.id),
    });

    if (this.options.at(index)?.value) {
      this.options.at(index).patchValue(form);
    } else {
      this.options.push(form);
    }

    this.updateItemPrices();
    this.addTypes(option.types, index, true);
  }

  public addTypes(types: Array<TypeModel>, index: number, clear: boolean): void {
    const controls = this.options.at(index)?.get('types') as FormArray;

    if (types?.length > 0) {
      if (clear) {
        controls.clear();
      }

      types.forEach((element) => {
        const form = new FormGroup({
          id: new FormControl(element.id),
          name: new FormControl(element.name, [Validators.required]),
        });

        controls.push(form);
      });

      this.updateItemPrices();
    }
  }

  public initTypes(): FormGroup {
    return new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),
    });
  }

  public deleteType(i: number, j: number): void {
    const controls = this.options.at(i).get('types') as FormArray;
    controls.removeAt(j);
    this.updateItemPrices();
  }

  public initList(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.sellerId) {
        this.sellerId = res.sellerId;
        this.getOptions();
      }
    });
  }

  public getOptions(): void {
    this.$optionsService.getOptionList(this.sellerId).subscribe({
      next: (res) => {
        if (res?.data?.listItemOptions) {
          const data = [...res.data.listItemOptions];
          const options = this.$shared.sortDataByName(data);

          this.itemOptionsList = options.map((option) => ({ value: option, label: option.name }));
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public sendItemPricesEvent(event: any): void {
    this.itemPrices = event;
  }

  public getTypes(form: AbstractControl<OptionModel>): any {
    return form.get('types')['controls'];
  }

  public submit(): void {
    this.getItemPricesEvent.next();

    const payload = {
      options: this.form.value?.options?.map((option: any) => option.id || option.name?.id),
      itemPrices: this.itemPrices,
    };

    this.$modalRef.close(payload);
  }
}
