import { Observable } from 'rxjs';
import moment from 'moment-timezone';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Apollo, Mutation, MutationResult } from 'apollo-angular';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  FINISH_SELLER_ONBOARDING_MUTATION,
  START_SELLER_ONBOARDING_MUTATION,
  START_CUSTOMER_ONBOARDING_MUTATION,
  FINISH_CUSTOMER_ONBOARDING_MUTATION,
} from '../../graphql/mutations/onboarding.mutations';
import { LifeCycleEnum } from '../../enums/life-cycle.enum';
import { EmailModel } from '../../models/email/email.model';
import { LeadStatusType } from '../../types/lead-status.type';
import { SellerModel } from '../../models/sellers/sellers.model';
import { AddressModel } from '../../models/address/address.model';
import { ActivityModel } from '../../models/activity/activity.model';
import { ResponseModel } from '../../models/response/response.model';
import { DashboardModel } from '../../models/onboarding/dashboard.model';
import { OnBoardingModel } from '../../models/onboarding/onboarding.model';
import { CreateAccountModel } from '../../models/auth/create-account.model';
import { UserClaimsModel } from '../../models/user-claims/user-claims.model';
import { BankDataModel } from '../../models/onboarding/internal/bank-data.model';
import { InitialDataModel } from '../../models/onboarding/external/initial-data.model';
import { PersonalDataModel } from '../../models/onboarding/internal/personal-data.model';
import { DashboardBankModel } from '../../models/onboarding/internal/dashboard-bank.model';
import { CREATE_BANK_ACCOUNT_MUTATION } from '../../graphql/mutations/bank-account.mutations';
import { DashboardDocumentModel } from '../../models/onboarding/internal/dashboard-document.model';
import { OnboardingPersonalModel } from '../../models/onboarding/external/onboarding-personal.model';
import { EmailValidationDataModel } from '../../models/onboarding/internal/email-validation-data.mode';
import { DashboardCompanyDetailsModel } from '../../models/onboarding/internal/dashboard-company-details.mode';
import { DashboardEmailValidationModel } from '../../models/onboarding/internal/dashboard-email-validation.mode';
import { OnboardingVerifyPhoneNumber } from '../../models/onboarding/external/onboarding-verify-phone-number.model';
import { FinishCustomerOnboardingParamsModel } from '../../models/onboarding/external/finish-customer-onboarding-params.model';
import { DashboardCompanyDetailsAnonymousModel } from '../../models/onboarding/internal/dashboard-company-details-anonymous.model';

@Injectable()
export class OnboardingService {
  private initOnboarding: OnBoardingModel = new OnBoardingModel();
  private finalOnboading: DashboardModel = new DashboardModel();

  constructor(
    private readonly router: Router,
    private readonly apollo: Apollo,
    private readonly $auth: AngularFireAuth,
    private readonly $firestore: AngularFirestore,
    private readonly $functions: AngularFireFunctions
  ) { }

  public setInitOnboarding(data: OnBoardingModel): void {
    this.initOnboarding = data;
  }

  public setFinalOnboarding(data: DashboardModel): void {
    this.finalOnboading = data;
  }

  public getActivities(): Observable<ActivityModel[]> {
    return this.$firestore.collection('businessSegments').valueChanges() as Observable<ActivityModel[]>;
  }

  private createOrUpdateHubspotContact(data: {
    properties: any;
    companyProperties?: any;
  }): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('createOrUpdateHubspotContact')(data);
  }

  public savePersonalData(data: OnboardingPersonalModel): void {
    this.initOnboarding.personalData = data;
    this.initOnboarding.phoneNumber = data?.data?.phoneNumber;
    this.initOnboarding.insertedAt = moment.utc().format('YYYY-MM-DDTHH:mm:ss');

    this.router.navigate(['/external/sign-up/code-verify']);
  }

  public savePhoneNumber(uid: string, data: OnboardingVerifyPhoneNumber): void {
    this.initOnboarding.verifyPhoneNumber = data;
    this.setOnboardingCollection(uid);
  }

  public getOnboardingProgress(finalOnboading: DashboardModel): number {
    let count = 0;
    const obj = JSON.parse(JSON.stringify(finalOnboading));

    Object.keys(obj).forEach((key) => {
      if (obj[key]?.done) {
        count += 1;
      }
    });

    const multiplier = obj.sendDocument ? 20 : 25;
    return Number(count * multiplier);
  }

  public changeTypeSave(uid: string, type: string) {
    this.initOnboarding.typeOfTrade.data.typeTrade = type;

    this.setOnboardingCollection(uid);
  }

  private setOnboardingCollection(uid: string): void {
    if (this.initOnboarding) {
      this.$firestore.doc(`onboarding/${uid}`).set(JSON.parse(JSON.stringify(this.initOnboarding)));
    }
  }

  public getOnboardingCollection(uid: string): Observable<OnBoardingModel> | undefined {
    return this.$firestore.doc(`onboarding/${uid}`).valueChanges() as Observable<OnBoardingModel>;
  }

  public getDashboardActivationCollection(uid: string): Observable<DashboardModel> | undefined {
    return this.$firestore.doc(`dashboardActivation/${uid}`).valueChanges() as Observable<DashboardModel>;
  }

  public async saveDashboardPersonalData(claims: any, data?: any): Promise<void> {
    const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss');

    this.finalOnboading.insertedAt = now;
    this.finalOnboading.phoneVerifiedAt = now;
    this.finalOnboading.personalData = data ?? Object.assign({}, this.initOnboarding.personalData, { done: false });
    this.finalOnboading.phoneNumber = data?.data?.phoneNumber ?? this.initOnboarding.personalData?.data?.phoneNumber;

    if (claims) {
      await this.setDashboardActivation(claims?.uid || claims?.user_id);
    }
  }

  public async saveBankData(claims: UserClaimsModel, data: DashboardBankModel): Promise<void> {
    this.finalOnboading.bankData = data;

    await this.setDashboardActivation(claims.user_id);
  }

  public async saveDocuments(claims: UserClaimsModel, data: DashboardDocumentModel): Promise<void> {
    this.finalOnboading.documents = data;
    this.finalOnboading.sendDocument = true;

    await this.setDashboardActivation(claims.user_id);
  }

  public async saveCompanyDetails(claims: UserClaimsModel): Promise<void> {
    await this.setDashboardActivation(claims.user_id);
  }

  public async saveEmailValidation(claims: UserClaimsModel, data: DashboardEmailValidationModel): Promise<void> {
    const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss');

    this.finalOnboading.emailVerifiedAt = now;
    this.finalOnboading.emailValidation = data;

    await this.setDashboardActivation(claims.user_id);
  }

  private setDashboardActivation(uid: string): Promise<void> | void {
    if (this.finalOnboading) {
      return this.$firestore
        .doc(`dashboardActivation/${uid}`)
        .set(JSON.parse(JSON.stringify(this.finalOnboading)), { merge: true });
    }
  }

  public getOnboardingMessage(onboardingProgress: number): string {
    const data = {
      0: '0% do seu cadastro realizado, insira os dados e ative sua conta.',
      20: 'Tudo certo até aqui, insira os dados necessários.',
      25: 'Tudo certo até aqui, insira os dados necessários.',
      40: 'Falta pouco para finalizar, insira os dados e ative sua conta.',
      50: 'Falta pouco para finalizar, insira os dados e ative sua conta.',
      60: 'Quase pronto, insira os dados e libere sua conta.',
      75: 'Quase pronto, insira os dados e libere sua conta.',
      80: 'Últimas informações, insira seus documentos e libere sua conta para transacionar.',
      100: 'Pronto, suas informações foram enviadas com sucesso!',
    };

    return data[onboardingProgress];
  }

  getStepsByOnboardingProgress(onboardingProgress: number): number {
    const data = {
      0: 0,
      20: 1,
      25: 1,
      40: 2,
      50: 2,
      60: 3,
      75: 3,
      80: 4,
      100: 5,
    };

    return data[onboardingProgress];
  }

  public sendCodeValidationEmail(data: EmailModel): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('sendCodeValidationEmail')(data);
  }

  public validateEmailCode(data: EmailModel): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('validateEmailCode')(data);
  }

  public createBankAccount(data: BankDataModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BANK_ACCOUNT_MUTATION,
      variables: {
        bankNumber: data.bank,
        agencyCheckNumber: data.accountCheckNumber,
        type: data.accountType,
        agencyNumber: data.agency,
        accountNumber: data.account,
        accountCheckNumber: data.accountCheckNumber,
        sellerId: data.sellerId,
      },
    });
  }

  public createSeller(type: string, payload: any): Observable<MutationResult<any>> {
    const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss');

    this.finalOnboading.termAcceptedAt = now;

    if (type === 'companyDetails') {
      this.finalOnboading.companyDetails = payload;
    } else {
      this.finalOnboading.companyDetailsAnonymous = payload;
    }

    // Dashboard
    const dataDashboard: DashboardModel = this.finalOnboading;
    const companyDetails: DashboardCompanyDetailsModel = dataDashboard?.companyDetails;
    const personalData: PersonalDataModel = dataDashboard?.personalData?.data;
    const emailValidation: EmailValidationDataModel = dataDashboard?.emailValidation?.data;
    const companyDetailsAnonymous: DashboardCompanyDetailsAnonymousModel = dataDashboard?.companyDetailsAnonymous;

    // Onboarding
    const dataTypeOfTrade = this.initOnboarding.typeOfTrade;
    let { typeTrade } = dataTypeOfTrade?.data;

    let company = null;
    let companyAnonymous = null;

    if (!typeTrade) {
      typeTrade = 'company';
    }

    if (companyDetails?.done && typeTrade === 'company') {
      company = {
        cnpj: companyDetails?.data?.cnpj || '',
      };
    }

    if (companyDetailsAnonymous?.done && typeTrade === 'autonomous') {
      const splitCompany = companyDetailsAnonymous?.data?.businessActivity?.split(' - ');

      companyAnonymous = {
        segmentId: splitCompany[0],
        occupation: splitCompany[1],
      };
    }

    const data = {
      cpf: personalData.cpf,
      person: {
        name: personalData.name,
        cpf: personalData.cpf,
        address: {
          line1: personalData.street,
          line2: personalData.number,
          line3: personalData.complement,
          neighborhood: personalData.neighborhood,
          postalCode: personalData.postalCode,
          city: personalData.city,
          state: personalData.state,
          countryCode: 'BR',
        },
      },
      company,
      email: emailValidation.email,
      phone: `+55${dataDashboard.phoneNumber}`,
      cnpj: typeTrade === 'company' ? companyDetails?.data?.cnpj : null,
      businessSegmentId: companyAnonymous ? companyAnonymous.segmentId : null,
      occupation: companyAnonymous ? companyAnonymous.occupation : null,
      termAcceptedAt: dataDashboard.termAcceptedAt,
      termVersion: '1.0.1',
      emailVerifiedAt: dataDashboard.emailVerifiedAt,
    };

    return this.finishSellerOnboarding(data);
  }

  public createCustomer(account: CreateAccountModel, address: AddressModel): Observable<MutationResult<any>> {
    const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    const data: FinishCustomerOnboardingParamsModel = {
      name: account.username,
      address: {
        line1: address.line1,
        line2: address.line2,
        line3: address.line3,
        neighborhood: address.neighborhood,
        postalCode: address.postalCode,
        city: address.city,
        state: address.state,
        countryCode: 'BR'
      },
      cpf: account.cpf,
      termAcceptedAt: now,
      termVersion: '1.0.1',
    };
    return this.finishCustomerOnboarding(data);
  }

  public startSellerOnboarding(data: InitialDataModel): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: START_SELLER_ONBOARDING_MUTATION,
      variables: {
        cpf: data.cpf,
        name: data.name,
        phoneNumber: `+55${data.phoneNumber}`,
      },
    });
  }

  private finishSellerOnboarding(data: any): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: FINISH_SELLER_ONBOARDING_MUTATION,
      variables: data,
    });
  }

  public startCustomerOnboarding(data: InitialDataModel): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: START_CUSTOMER_ONBOARDING_MUTATION,
      variables: {
        cpf: data.cpf,
        name: data.name,
        phoneNumber: `+55${data.phoneNumber}`,
      },
    });
  }

  private finishCustomerOnboarding(data: any): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: FINISH_CUSTOMER_ONBOARDING_MUTATION,
      variables: data,
    });
  }

  public createContactOnHubspot(seller: SellerModel): Observable<ResponseModel<any>> {
    const name = seller.person?.name || '';
    const firstName = name.split(' ').slice(0, 1).join(' ');
    const lastName = name.split(' ').slice(-1).join(' ');
    const data: any = {
      properties: {
        email: seller.email,
        firstname: firstName,
        lastname: lastName,
        sellerid: seller.id,
        sellerstatus: seller.status,
        usedsystem: 'SAAS',
        hs_lead_status: 'NEW',
        lifecyclestage: LifeCycleEnum.NEW,
        phone: seller.phone,
        city: seller.person?.address?.city || '',
        state: seller.person?.address?.state || '',
        zip: seller.person?.address?.postalCode || '',
      },
    };

    if (seller.company?.businessName && seller.company?.cnpj) {
      data.properties.company = seller.company.businessName;
      data.companyProperties = {
        phone: seller.phone,
        name: seller.company.businessName,
        cnpj: seller.company.cnpj,
        city: seller.company.address?.city || '',
        state: seller.company.address?.state || '',
        zip: seller.company.address?.postalCode || '',
      };
    } else {
      data.properties.businesssegment = seller.occupation;
    }

    return this.createOrUpdateHubspotContact(data);
  }

  public updateContactOnHubspot(leadStatus: LeadStatusType, newEmail?: string): Observable<ResponseModel<any>> {
    const email = newEmail ? newEmail : this.finalOnboading.emailValidation?.data?.email;

    return this.createOrUpdateHubspotContact({
      properties: {
        email,
        hs_lead_status: leadStatus,
        lifecyclestage: LifeCycleEnum[leadStatus],
      },
    });
  }

  public updateDashboardFirebase(action: string, value?: number): void {
    switch (action) {
      case 'resetCompanyDetails':
        this.finalOnboading.companyDetails.done = false;
        break;
      case 'resetCompanyDetailsAnonymous':
        this.finalOnboading.companyDetailsAnonymous.done = false;
        break;
      case 'stage':
        this.finalOnboading.stage = value;
        break;
      case 'sendDocument':
        this.finalOnboading.sendDocument = false;
        break;
    }

    this.$auth.currentUser.then((user) => {
      if (user) {
        this.setDashboardActivation(user.uid);
      }
    });
  }
}
