import { gql } from 'apollo-angular';

const GET_ITEM_QUERY = gql`
  query getItem($id: UUID4!) {
    getItem(id: $id ) {
      id
      name
      type
      active
      updatedAt
      imagesUrls
      insertedAt
      description
      amountCents
      ableSmartpos
      itemPrices {
        amountCents
        id
        quantity
        itemTypes {
          amountCents
          name
          id
          imageUrl
        }
      }
      itemOptions {
        id
        name
        required
        displayName
        maxQuantity
        types {
          id
          name
          amountCents
          imageUrl
        }
      }
      itemCategory {
        id
        name
      }
    }
  }
`;

const LIST_ITEMS_QUERY = gql`
  query ListItems($filters: FilterItemsParams, $sellerId: UUID4!) {
    listItems(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC }, sellerId: $sellerId) {
      id
      name
      type
      active
      updatedAt
      imagesUrls
      insertedAt
      description
      amountCents
      ableSmartpos
      itemPrices {
        amountCents
        id
        quantity
        itemTypes {
          amountCents
          name
          id
          imageUrl
        }
      }
      itemOptions {
        id
        name
        required
        displayName
        maxQuantity
        types {
          id
          name
          amountCents
          imageUrl
        }
      }
      itemCategory {
        id
        name
      }
    }
  }
`;

export { LIST_ITEMS_QUERY,GET_ITEM_QUERY };
