import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { SubscriptionModel } from 'libs/shared/src/lib/models/subscriptions/subscriptions.model';
import { SubscriptionsService } from 'libs/shared/src/lib/services/subscriptions/subscriptions.service';

@Component({
  selector: 'app-drawer-subscription-details',
  templateUrl: './drawer-subscription-details.component.html',
  styleUrls: ['./drawer-subscription-details.component.scss'],
})
export class DrawerSubscriptionDetailsComponent implements OnInit {
  @Input() value: string;

  public subscription: SubscriptionModel;
  public schedule: string = '';
  public loading: boolean = true;

  constructor(public $subscription: SubscriptionsService, private readonly $message: NzMessageService) {}

  public ngOnInit(): void {
    this.getSubscription();
  }

  public getSubscription(): void {
    this.$subscription.getSubscription(this.value).subscribe({
      next: (res) => {
        if (res?.data?.getSubscription) {
          this.subscription = res.data.getSubscription;
          this.schedule = this.getSchedule(this.subscription?.schedulePlans);
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao buscar a recorrência.');
        throw new Error(error);
      },
    });
  }

  public getSchedule(schedule: string): string {
    const schedules: any = {
      daily: 'Diária',
      weekly: 'Semanal',
      biweekly: 'Quinzenal',
      monthly: 'Mensal',
      annually: 'Anual',
    };

    return schedules[schedule];
  }
}
