import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-confirmation-update',
  templateUrl: './modal-confirmation-update.component.html',
  styleUrls: ['./modal-confirmation-update.component.scss'],
})
export class ModalConfirmationUpdateComponent {
  @Input() data: any;

  constructor(public $modalRef: NzModalRef) {}
}
