import { NzMessageService } from 'ng-zorro-antd/message';
import { finalize, Observable, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { environment } from '@gen/environments';
import { Router } from '@angular/router';
import { ItemsModel } from '../../../models/items/items.model';
import { ResponseModel } from '../../../models/response/response.model';
import { PointOfSalesService } from '../../point-of-sales/point-of-sales.service';
import { Apollo } from 'apollo-angular';
import { GET_ITEM_QUERY, LIST_ITEMS_QUERY } from '../../../graphql/queries/item.queries';
import {
  CREATE_ITEM_MUTATION,
  DELETE_ITEM_MUTATION,
  UPDATE_ITEM_MUTATION,
} from '../../../graphql/mutations/item.mutations';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  private marketplaceId: string = environment.marketplaceId;

  constructor(
    private fireStore: AngularFirestore,
    private afFunc: AngularFireFunctions,
    private $message: NzMessageService,
    private readonly apollo: Apollo,
    private router: Router,
    private readonly storage: AngularFireStorage
  ) {}

  public getItemList(sellerId: string, filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEMS_QUERY,
      variables: { sellerId, filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getItem(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createItem(data: ItemsModel): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_MUTATION,
      variables: data,
    });
  }

  public batchCreateItem(data: Array<ItemsModel>): Observable<ResponseModel<ItemsModel>> {
    return this.afFunc.httpsCallable('batchCreateItem')(data);
  }

  public updateItem(data: ItemsModel): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_MUTATION,
      variables: data,
    });
  }

  public deleteItem(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_MUTATION,
      variables: { id },
    });
  }

  public async updateItemImageUrl(userId: string, item: ItemsModel, files: any[]): Promise<void> {
    let imagesUrls: any = [];

    await Promise.all(
      files.map(async (element: any, index: number) => {
        await new Promise<void>((resolve, reject) => {
          if (element.isBackEndImg) {
            imagesUrls.push({ url: element.url, order: element.order });
            resolve();
          } else {
            const payload = {
              sellerId: item.sellerId!,
              userId: userId,
              type: 'image',
              id: uuidv4(), // nome
            };

            const filePath = `sellers/${item.sellerId}/items/${item.id}/images/${payload.id}`;

            this.storage
              .upload(filePath, element, {
                customMetadata: payload,
              })
              .snapshotChanges()
              .pipe(
                finalize(() => {
                  this.storage
                    .ref(filePath)
                    .getDownloadURL()
                    .subscribe(async (url: string) => {
                      if (url) {
                        imagesUrls.push({ url: url, order: element.order });
                        resolve();
                      }
                    });
                })
              )
              .subscribe();
          }
        });
      })
    )
      .then(() => {
        if (imagesUrls.length > 0) {
          imagesUrls = imagesUrls
            .sort((a: { order: number }, b: { order: number }) => a.order - b.order)
            .map((a: any) => a.url);
          this.updateItem({ id: item.id, imagesUrls: imagesUrls, sellerId: item.sellerId } as any).subscribe();
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  public getItemDetails(sellerId: string, productId: string): Observable<ItemsModel> {
    return this._collection(sellerId).doc(productId).valueChanges();
  }

  public getPublicItemDetail(id: string): Observable<ItemsModel> {
    return this._publicCollection().doc(id).valueChanges();
  }

  private _publicCollection(): AngularFirestoreCollection<ItemsModel> {
    const marketplaceId = environment.marketplaceId;

    return this.fireStore.collection(`marketplaces/${marketplaceId}/items`, (ref) => ref.orderBy('insertedAt', 'desc'));
  }

  private _collection(sellerId: string): AngularFirestoreCollection<ItemsModel> {
    return this.fireStore.collection(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/items`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }

  public listItemsFirebase(sellerId: string): Observable<Array<ItemsModel>> {
    return this._collection(sellerId).valueChanges();
  }

  public updateActiveItem(data: any): Observable<ResponseModel<ItemsModel>> {
    return this.afFunc.httpsCallable('updateItemShein')(data);
  }

  public updateItemActive(products: ItemsModel[], sellerId: string): void {
    products.forEach((product) => {
      if (product) {
        const payload = {
          id: product.originalId,
          sellerId: sellerId,
          active: false,
        };

        this.updateActiveItem(payload).subscribe();
      }
    });
  }

  public async checkForInactives(
    sellerId: string,
    pointId: string,
    products: ItemsModel[],
    cartId: string
  ): Promise<boolean> {
    let foundInactive = false;

    const inactiveItens = products.filter((product) => !product.active);

    if (inactiveItens.length > 0) {
      return this.inactiveFound(sellerId, pointId);
    }

    products.map(async (element) => {
      const originalProduct = await this.getItemDetails(sellerId, element.originalId)?.pipe(take(1)).toPromise();

      if (originalProduct.active === false && originalProduct !== undefined) {
        element.active = false;
        foundInactive = true;
      }
    });

    if (foundInactive) {
      return this.inactiveFound(sellerId, pointId);
    }

    return foundInactive;
  }

  public inactiveFound(sellerId: string, pointId: string): boolean {
    this.$message.error('Não é possivel pagar um pedido com produtos inativos.');
    this.router.navigate(['/external/' + sellerId + '/' + pointId + '/basket']);
    return true;
  }

  public calcTotalPrice(products: Array<ItemsModel>): number {
    const totalPrice = products.reduce((acc, product) => (acc += product.amountCents * (product.quantity || 1)), 0);
    return Number(totalPrice);
  }

  public goToStore(sellerId: string): void {
    window.open(`${environment.pwaDomain}/external/${sellerId}/store`);
  }

  public hexFromColorName(colorName: string): string {
    const colors: { [key: string]: string } = {
      preto: '#000000',
      vermelho: '#C73B51',
      verde: '#FEB8C3',
      azul: '#0000FF',
      amarelo: '#48CEA6',
    };
    return colors[colorName.toLowerCase()] || '';
  }
}
