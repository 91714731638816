import { Component, Input, OnInit, Output } from '@angular/core';
import { CategoryModel } from '../../../models/categories/categories.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { StateManagementService } from '../../../state-management/state-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filter-categories',
  templateUrl: './filter-categories.component.html',
  styleUrls: ['./filter-categories.scss'],
})
export class FilterCategoriesComponent implements OnInit {
  @Input() public categories: Array<CategoryModel>;
  @Input() public selectedProductCategory: CategoryModel;

  public form: FormGroup;

  public clickedFavorite: boolean = false;
  public pointId: string = '';

  public imagesUrl = [
    '../../assets/images/shopping-cart.svg',
    '../../assets/images/clicked-heart.svg',
    '../../assets/images/unclicked-heart.svg',
  ];

  constructor(
    private fb: FormBuilder,
    public $modal: NzDrawerRef,
    public route: Router,
    private $notification: StateManagementService) {}

  ngOnInit(): void {
    this.createForm();
    this.getNotification();
    this.getValuesChange();
  }

  private createForm(): void {
    this.form = this.fb.group({
      search: new FormControl(''),
    });
  }

  public onSelectCategory(category: any): void {
    if (category.id) {
      this.selectedProductCategory = category;
      this.$notification.setChangedCategory(category);
    }
  }

  public getNotification(): void {
    this.$notification.routeParams.subscribe((ids => {
      if (ids) {
        this.pointId = ids.pointId;
      }
    }))

    this.$notification.changedCategorys.subscribe((res) => {
      if (res) {
        this.selectedProductCategory = res;
      }
    });
  }

  public getValuesChange(): void {
    this.form.get('search').valueChanges.subscribe((res) => {
      this.$notification.setSearchValue(res);
    });
  }

  public goToCart(): void {
    this.$modal.close();
    this.route.navigate(['/loggedCart/' + this.pointId + '/cart']);
  }
}
