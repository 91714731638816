import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOptionModel } from '../../../models/utils/select-option.model';
import { STATES } from '../../../utils/constants';

@Component({
  selector: 'app-modal-find-address',
  templateUrl: './modal-find-address.component.html',
  styleUrls: ['./modal-find-address.component.scss'],
})
export class ModalFindAddressComponent implements OnInit {
  public formGroup!: FormGroup;
  public states: SelectOptionModel[] = STATES;

  constructor(private fb: FormBuilder, private modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.formGroup = this.fb.group({
      street: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
    });
  }

  public clearFields(): void {
    this.formGroup?.reset();
  }

  public setHandleSubmit(): void {
    if (this.formGroup.valid) {
      this.modalRef.destroy(this.formGroup.value);
    } else {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
