import { Component, Input, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-insert-image',
  templateUrl: './modal-insert-image.component.html',
  styleUrls: ['./modal-insert-image.component.scss'],
})
export class ModalInsertImageComponent implements OnInit {
  @Input() public imgUrl: string;

  public cropperSize: { width: number; height: number };
  public resultImgSize: { width: number; height: number };
  public disabledBtn: boolean;

  private resultImg: any;

  constructor(public modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.getInitialVariables();
  }

  private getInitialVariables(): void {
    this.cropperSize = {
      width: 520,
      height: 520,
    };
    this.resultImgSize = {
      width: 520,
      height: 520,
    };
    this.disabledBtn = false;
  }

  public getCroppedImage(event: any): void {
    this.resultImg = event;
    this.disabledBtn = false;
  }

  public confirm(): void {
    html2canvas(this.resultImg).then((canvas) => {
      const image = new Image(
        this.resultImgSize?.width || this.cropperSize?.width,
        this.resultImgSize?.height || this.cropperSize?.height
      );
      image.src = canvas.toDataURL();
      this.modalRef.close(image.src);
    });
  }
}
