import { gql } from 'apollo-angular';

const DECLINE_WITHDRAW_MUTATION = gql`
  mutation DeclineWithdraw($id: UUID4!) {
    declineWithdraw(id: $id) {
      id
    }
  }
`;

const APPROVE_WITHDRAW_MUTATION = gql`
  mutation ApproveWithdraw($id: UUID4!) {
    approveWithdraw(id: $id) {
      id
    }
  }
`;

export { DECLINE_WITHDRAW_MUTATION, APPROVE_WITHDRAW_MUTATION };
