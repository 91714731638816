import { mergeMap, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TicketModel } from './../../models/ticket/ticket.model';
import { TicketService } from './../../services/ticket/ticket.service';
import { MixpanelService } from '../../services/mixpanel/mixpanel.service';
import { RouteParamsModel } from './../../models/route-params/route-params.model';
import { StateManagementService } from './../../state-management/state-management.service';

@Component({
  selector: 'app-generic-page',
  templateUrl: './generic-page.component.html',
  styleUrls: ['./generic-page.component.scss'],
})
export class GenericPageComponent implements OnInit {
  public title?: string = '';
  public description?: string = '';
  public imgPath?: string = '';
  public primaryButtonText?: string = '';
  public primaryButtonCallback?: () => void;
  public secondaryButtonText?: string = '';
  public secondaryButtonCallback?: () => void;
  public pageType?: string;
  public ids?: RouteParamsModel = new RouteParamsModel();
  public ticket?: TicketModel = new TicketModel();
  public paidValue: string = '';
  public costumerIdLocalStorage: string = localStorage.getItem('parkingUser');

  constructor(
    private $ticket: TicketService,
    private readonly router: Router,
    private $mixpanel: MixpanelService,
    private $notification: StateManagementService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.getData();
    this.getTicket();
    this.trackMixPanel();
  }

  private trackMixPanel(): void {
    if (this.pageType === 'invalidScanTicket') {
      this.$mixpanel.track('init_scan_exceed_time');
    }

    if (this.pageType === 'approvedTicket') {
      this.$mixpanel.track('init_succeed_payment');
    }
  }

  private getData(): void {
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.title = data['title'];
        this.description = data['description'];
        this.imgPath = data['imgPath'];
        this.primaryButtonText = data['primaryButtonText'];
        this.primaryButtonCallback = data['primaryButtonCallback'];
        this.secondaryButtonText = data['secondaryButtonText'];
        this.secondaryButtonCallback = data['secondaryButtonCallback'];
        this.pageType = data['pageType'];
      }
    });
  }

  private getTicket(): void {
    this.$notification.routeParams
      .pipe(
        mergeMap((ids) => {
          if (ids) {
            this.ids = ids;
            return this.$notification.tickets;
          }

          return of(undefined);
        })
      )
      .subscribe({
        next: (ticket) => {
          if (ticket) {
            this.ticket = ticket;
            this.paidValue =
              ticket.historico?.length > 0 ? ticket?.historico[ticket?.historico?.length - 1]?.valorPago : '0';
          } else {
            this.$ticket.getAndSetTicket(this.ids);
          }
        },
        error: (error) => {
          throw new Error(error);
        },
      });
  }

  public handlePrimaryButton(): void {
    if (this?.pageType === 'approvedTicket') {
      this.goToTicket();
    }
    if (this?.pageType === 'undefinedTicket') {
      this.goToTypeNumber();
    }
    if (this?.pageType === 'invalidScanTicket') {
      this.goToTypeNumber();
    }
  }

  public handleSecondaryButton(): void {
    if (this.pageType === 'approvedTicket') {
      this.goToReceipt();
    }
    if (this?.pageType === 'undefinedTicket') {
      this.goToScanTicket();
    }
    if (this?.pageType === 'invalidScanTicket') {
      this.goToScanTicket();
    }
  }

  public goToReceipt(): void {
    this.$mixpanel.track('go_to_receipt_after_payment', { ticket: this.ticket.ticket, mall: this.ids.mallId });
    this.router.navigate([
      `external/${this.ids.mallId}/proof-of-payment/${this.costumerIdLocalStorage}/${this.ticket.ticket}`,
    ]);
  }

  public goToTicket(): void {
    this.$mixpanel.track('go_to_ticket_after_payment', { ticket: this.ticket.ticket, mall: this.ids.mallId });
    this.router.navigate([`external/${this.ids.mallId}/validTicket/${this.ticket.ticket}`]);
  }

  public goToTypeNumber(): void {
    this.router.navigate([`external/${this.ids.mallId}/typeNumber`]);
  }

  public goToScanTicket(): void {
    this.router.navigate([`external/${this.ids.mallId}/scanTicket`]);
  }
}
