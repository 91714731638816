import { gql } from 'apollo-angular';

const LIST_CANCELLMENTS_QUERY = gql`
  query ListCancellations($filter: FilterCancellationParams) {
    listCancellations(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC }) {
      amountCents
      id
      status
      payment {
        id
      }
    }
  }
`;

export { LIST_CANCELLMENTS_QUERY };
