import { Component, OnInit, Input } from '@angular/core';
import { PointModel } from '../../models/points/point-of-sales.model';

@Component({
  selector: 'app-checkout-flow-modal',
  templateUrl: './checkout-flow-modal.component.html',
  styleUrls: ['./checkout-flow-modal.component.scss'],
})
export class CheckoutFlowModalComponent implements OnInit {
  @Input() public point: PointModel;
  public authenticated: boolean = false;

  constructor() { }

  public ngOnInit(): void {
    this.checkAuth();
  }

  private checkAuth(): void {
    if (this.point.auth) {
      this.authenticated = true;
    }
  }

  public isAuthenticated(event): void {
    this.authenticated = event;
  }
}
