export class OptionModel {
  public id?: string = '';
  public name: string = '';
  public required?: boolean = false;
  public maxQuantity?: number = 0;
  public showMultipleItens?: boolean = false;
  public displayName?: string = '';
  public itemsCount?: number = 0;
  public types: TypeModel[] = [];
  public sellerId?: string = '';
  public selected?: string;
  public insertedAt?: string = '';
  public updatedAt?: string = '';
  public typesName?: string = '';
}
export class TypeModel {
  public id?: string = '';
  public name: string = '';
  public color?: string | null = '';
  public contrast?: boolean = false;
  public amountCents?: number = 0;
  public quantity?: number = 0;
  public selected?: string;
  public showHover?: boolean = false;
  public imageUrl?: string = '';
  public file?: any;
  public itemOptionId?: string = '';
  public insertedAt?: string = '';
  public updatedAt?: string = '';
}
