import { OrderModel } from './../orders/order.model';
import { SellerModel } from './../sellers/sellers.model';
import { CreditModel } from '../credit-card/credit.model';
import { ContactModel } from './../contact/contact.model';
import { LocationType } from './../../types/location-type';

export class PaymentsModel {
  public id?: string;
  public sellerId?: string;
  public orderId?: string;
  public totalCents!: number;
  public subtotalCents!: number;
  public feeAmountCents?: number;
  public discountCents?: number;
  public additionCents?: number;
  public order?: OrderModel;
  public location!: LocationType;
  public seller?: SellerModel;
  public status!: string;
  public credit!: CreditModel;
  public method!: string;
  public translatedMethod?: string;
  public description?: string;
  public dueDate?: string;
  public contactId?: string;
  public customerId?: string;
  public customer!: ContactModel;
  public insertedAt?: string;
  public brand?: string;
  public device?: IDevice;
  public pix?: IPIX;
  public endDate?: any;
  public bankSlip?: IBankSlip;
  public sessionId?: string;
  public store?: string;
  public externalId?: string;
  public confirmedAt?: string;
  public updatedAt?: string;
  public subscriptionId?: string;
  public icon?: string;
  public publicCustomer: IPublicCustomer;
  public acquirerPayment: IAcquirerPayment;
}

interface IPIX {
  brcode: string;
  expiration: string;
}

interface IBankSlip {
  bankSlipId: string;
  barcode: string;
  printLinkHtml: string;
  printLinkPdf: string;
  instructions: string;
  typefulLine: string;
  expirationDate: string;
  bank: string;
}

interface IDevice {
  userAgent: string;
  model: string;
  platform: string;
  ip: string;
}

interface IPublicCustomer {
  cpf: string;
  cnpj: string;
  id: string;
  insertedAt: Date;
  name: string;
  updatedAt: Date;
}

interface IAcquirerPayment {
  CreditParty: ICreditParty;
  TransactionId: string;
}

interface ICreditParty {
  Key: string;
  Bank: string;
}

export class PixPaymentsModel extends PaymentsModel {
  public weekDay?: string;
  public active?: boolean;
}
