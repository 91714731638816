import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { environment } from '@gen/environments';
import { PriceModel } from '../../models/plans/price.model';
import { PlansModel } from './../../models/plans/plans.model';
import { InternalService } from '../internal/internal.service';
import { PaymentsModel } from '../../models/payments/payments.model';
import { ResponseModel } from '../../models/response/response.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { PaymentInfo } from '../../models/payments/payment-info.model';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Injectable()
export class PlansService {
  public publicPlansCollection: AngularFirestoreCollection<any> = this.fireStore.collection(
    `marketplaces/${environment.marketplaceId}/plans`
  );

  public selectedPrice: PriceModel;

  constructor(
    private readonly fireStore: AngularFirestore,
    private $notification: StateManagementService,
    private $methods: InternalService,
    private readonly $functions: AngularFireFunctions,
    private $subscription: SubscriptionsService,
    private readonly storage: AngularFireStorage
  ) {}

  public getPublicPlanDetail(id: string): Observable<PlansModel | undefined> | undefined {
    return this.publicPlansCollection?.doc(id).valueChanges();
  }

  public getPlanList(
    marketplaceid: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): Observable<Array<PlansModel>> | undefined {
    return this._collection(marketplaceid, sellerId, filter)?.valueChanges();
  }

  public getPlanDetail(
    marketplaceid: string,
    sellerId: string,
    id: string
  ): Observable<PlansModel | undefined> | undefined {
    return this._collection(marketplaceid, sellerId)?.doc(id).valueChanges();
  }

  public createPlan(data: PlansModel): Observable<ResponseModel<PlansModel>> {
    return this.$functions.httpsCallable('createPlan')(data);
  }

  public updatePlan(data: PlansModel): Observable<ResponseModel<PlansModel>> {
    return this.$functions.httpsCallable('updatePlan')(data);
  }

  public deletePlan(id: string): Observable<ResponseModel<PlansModel>> {
    return this.$functions.httpsCallable('deletePlan')(id);
  }

  public getPlansWithFilters(
    marketplaceid: string,
    sellerId: string,
    filter: ResponseFilterModel
  ): Observable<PlansModel[]> {
    return this._collection(marketplaceid, sellerId, filter).valueChanges();
  }

  private _collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): AngularFirestoreCollection<PlansModel> {
    if (filter && !isEmpty(filter)) {
      return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/plans`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate: string = moment(filter.creationDateFilter.date)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate: string = moment(filter.creationDateFilter.date)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate: string = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate: string = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date: string = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/plans`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }

  public updatePlanImageUrl(plan: PlansModel, data: PlansModel, userId: string): void {
    const payload = {
      userId,
      sellerId: plan.sellerId!,
      type: 'image',
    };
    const filePath = `sellers/${plan.sellerId}/plans/${plan.id}/image`;

    const task = this.storage.upload(filePath, data.file, {
      customMetadata: payload,
    });

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          const fileRef = this.storage.ref(filePath);

          const downloadURL = fileRef.getDownloadURL();

          downloadURL.subscribe(async (url: string) => {
            if (url) {
              await this.updatePlan({ id: plan.id, imageUrl: url }).toPromise();
            }
          });
        })
      )
      .subscribe();
  }

  public onSignPlanOrSubscription(payload: any): void {
    this.$subscription.createSubscription(payload).subscribe({
      next: (response) => {
        const subscription = response.body;
        this.$notification.setSubscription(subscription);

        this.$notification.setLoading(false);

        if (response.status === 201) {
          if (subscription?.status === 'active') {
            this.$notification.setTypeResult('SUCCESS');
            if (payload.redirectTo) {
              window.location.href = payload.redirectTo;
            }
          }

          if (subscription?.status === 'waiting' || subscription?.status === 'failing') {
            this.$notification.setTypeResult('ERROR');
          }
        } else {
          this.$methods.treatError(response);
        }
      },
      error: (error) => {
        this.$notification.setTypeResult('ERROR');
        this.$notification.setErrorMesage(JSON.stringify(error));
        this.$notification.setLoading(false);
        throw new Error(error);
      },
    });
  }

  public createPayload(info: PaymentInfo): Partial<PaymentsModel> {
    let data = {
      sellerId: info?.seller?.id,
      method: info?.method,
      sessionId: uuidv4(),
      redirectTo: info?.redirectTo,
      externalId: info?.externalId,
      planId: info?.plans?.id,
      priceId: info?.prices?.id,
      schedulePlans: info?.prices?.schedulePlans,
      startDate: moment().format('YYYY-MM-DD'),
    };

    if (info?.subscription) {
      data = Object.assign(data, {
        subscriptionId: info?.subscription?.id,
        schedulePlans: info?.subscription?.schedulePlans,
        planId: info?.subscription?.planId,
        priceId: info?.subscription?.priceId,
      });
    }

    if (info.customer?.id) {
      data = Object.assign(data, { customerId: info.customer.id });
    } else {
      if (info.method !== 'PIX' || (info.method === 'PIX' && info.customer.name)) {
        data = Object.assign(data, { customer: info.customer });
      }
    }

    if (info.method === 'CREDIT_CARD') {
      if (info.creditCard?.id) {
        data = Object.assign(data, {
          creditCardId: info?.creditCard.id,
          securityCode: info?.creditCard?.securityCode,
        });
      } else {
        data = Object.assign(data, {
          transactionType: info.installments > 1 ? 'INSTALL_NO_INTEREST' : 'FULL',
          installments: info.installments,
          hash: info.token,
          holderName: info.customer?.name,
          expirationMonth: info.creditCard?.expirationMonth,
          expirationYear: info.creditCard?.expirationYear,
          securityCode: info.creditCard?.securityCode,
        });
      }
    }

    return data;
  }
}
