import { StoreOpeningHours } from './../../models/store/store-opening-hours';
import { Component, Input, ElementRef, ViewChild, OnInit } from '@angular/core';
import { WeekDays } from '../../enums/week-days.enum';

@Component({
  selector: 'app-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss'],
})
export class PreviewPageComponent implements OnInit{
  @ViewChild('previewPage') previewPage: ElementRef<HTMLDivElement>;

  @Input() public coverImg: string;
  @Input() public data: any;

  public openingHours: StoreOpeningHours[];
  public daysOfWeek = WeekDays;

  ngOnInit(): void {
    this.getOpeningHours();
  }

  private getOpeningHours(): void {
    this.openingHours = JSON.parse(this.data.openingHours);
  }
}
