import { gql } from 'apollo-angular';

const SEND_VERIFICATION_CODE_MUTATION = gql`
  mutation SendVerificationCode($phoneNumber: String!) {
    sendVerificationCode(phoneNumber: $phoneNumber)
  }
`;

const VALIDATE_VERIFICATION_CODE_MUTATION = gql`
  mutation ValidateVerificationCodeFirebase($phoneNumber: String!, $code: String!) {
    validateVerificationCodeFirebase(phoneNumber: $phoneNumber, code: $code) {
      accessToken
      refreshToken
      customToken
    }
  }
`;

const RENEW_ACCESS_TOKEN_MUTATION = gql`
  mutation RenewAccessToken($refreshToken: String!) {
    renewAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

const LOGIN_BY_PASSWORD_MUTATION = gql`
  mutation LoginByPassword($email: String!, $password: String!) {
    loginByPassword(email: $email, password: $password, platform: SYNAPSIS) {
      accessToken
      customToken
      refreshToken
    }
  }
`;

export {
  RENEW_ACCESS_TOKEN_MUTATION,
  SEND_VERIFICATION_CODE_MUTATION,
  VALIDATE_VERIFICATION_CODE_MUTATION,
  LOGIN_BY_PASSWORD_MUTATION,
};
