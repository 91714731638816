import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class minValueValidator {
  constructor() {}

  static isValid(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value < minAmount;
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }
}
