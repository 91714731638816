import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentMethod',
})
export class PaymentMethodPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const methods: any = {
        PIX: 'PIX',
        CREDIT_CARD: 'Cartão de crédito',
        BANK_SLIP: 'Boleto',
        MONEY_EXTERNAL: 'Dinheiro',
        DEBIT_EXTERNAL: 'Outros',
      };

      return methods[value] || '';
    }

    return '';
  }
}
