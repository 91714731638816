import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import BankAccountValidator from 'br-bank-account-validator';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzDrawerService, NzDrawerRef } from 'ng-zorro-antd/drawer';

import { trimData } from '../../../utils/utils';
import { Message } from '../../../utils/message';
import { BankModel } from '../../../models/bank/bank.model';
import { BankService } from '../../../services/bank/bank.service';
import { VersionStatusEnum } from '../../../enums/versions-status';
import { DocumentModel } from '../../../models/document/document.model';
import { SellersService } from '../../../services/sellers/sellers.service';
import { TableHeaderModel } from '../../../models/table/table-header.model';
import { TableSettingsModel } from '../../../models/table/table-settings.model';
import { SellersEditComponent } from './../sellers-edit/sellers-edit.component';
import { Limit, SellerModel, Version } from '../../../models/sellers/sellers.model';
import { SharedMethodsService } from '../../../services/internal/shared-methods.service';
import { ConfirmationDeleteComponent } from '../../confirmation-delete/confirmation-delete.component';

@Component({
  selector: 'app-seller-details',
  templateUrl: './seller-details.component.html',
  styleUrls: ['./seller-details.component.scss'],
})
export class SellerDetailsComponent implements OnInit {
  @Input() id: string;

  public documentsListOfHeaders: TableHeaderModel[] = [
    { name: 'Nome' },
    { name: 'Status' },
    { name: 'Data de envio' },
    { name: 'Ver documento' },
    { name: 'Ações' },
  ];
  public limitsListOfHeaders: TableHeaderModel[] = [
    { name: 'Ação' },
    { name: 'Funcionalidade' },
    { name: 'Categoria' },
    { name: 'Valor' },
    { name: 'Período' },
  ];
  public externalConsultsListOfHeaders: TableHeaderModel[] = [
    { name: 'CPF/CNPJ' },
    { name: 'Datasets' },
    { name: 'Resultado' },
  ];
  public bankAccountsListOfHeaders: TableHeaderModel[] = [
    { name: 'Banco' },
    { name: 'Agência' },
    { name: 'Tipo' },
    { name: 'Conta' },
  ];
  public settingValue: TableSettingsModel = {
    checkbox: false,
    pagination: true,
    footer: false,
    expandable: false,
  };
  public addingBankAccount: boolean = false;
  public bankAccountForm!: FormGroup;
  public banks: BankModel[] = [];
  public versions: Version[] = [];
  private sellerName: string = '';
  public loading: boolean = true;
  public loadingBtn: boolean = false;
  public minAccountMask: string = '';
  public accountMask: string = '';
  public accountCheckNumberMask: string = '';
  public agencyMask: string = '';
  public agencyCheckNumberMask: string = '';
  public bankName: string = '';
  public accountErrorMsg: string = '';
  public agencyErrorMsg: string = '';
  public seller: SellerModel = new SellerModel();

  constructor(
    private $bank: BankService,
    private $seller: SellersService,
    private $methods: SharedMethodsService,
    public readonly drawerRef: NzDrawerRef,
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.createFormAddBankAccount();
    this.getBanks();
    this.getSeller();
  }

  private createFormAddBankAccount(): void {
    this.bankAccountForm = new FormGroup({
      bankNumber: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      agencyNumber: new FormControl('', [Validators.required]),
      agencyCheckNumber: new FormControl(''),
      accountNumber: new FormControl('', [Validators.required]),
      accountCheckNumber: new FormControl('', [Validators.required]),
    });
  }

  private getBanks(): void {
    this.$bank.getBanks().subscribe((banks) => {
      if (banks && banks.length > 0) {
        this.banks = banks;
      }
    });
  }

  private getSeller(): void {
    this.$seller.getSellerById(this.id).subscribe({
      next: (res) => {
        if (res?.data?.getSeller) {
          this.seller = JSON.parse(JSON.stringify(res?.data?.getSeller));
          this.sellerName = this.seller?.company?.businessName || this.seller?.person?.name || '';
          this.getLimitsEnum();
          this.getDocumentsEnum();
          this.getVersionsEnum();
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error(Message.ERROR_GET_USERS);
        throw new Error(error);
      },
    });
  }

  private getDocumentsEnum(): void {
    this.seller.documents.map((document: DocumentModel) => (document.url = this.$methods.getDocumentUrl(document)));
  }

  private getLimitsEnum(): void {
    this.seller.limits.map((limit: Limit) => (limit = this.$methods.translateLimits(limit)));
  }

  private getVersionsEnum(): void {
    this.versions = JSON.parse(JSON.stringify(this.seller.versions));
    this.versions.map((version) => (version.event = VersionStatusEnum[version.event]));
  }

  public createBankAccount(): void {
    try {
      this.loadingBtn = true;

      BankAccountValidator.validate({
        bankNumber: this.bankAccountForm.get('bankNumber').value,
        agencyNumber: this.bankAccountForm.get('agencyNumber').value,
        agencyCheckNumber: this.bankAccountForm.get('agencyCheckNumber').value,
        accountNumber: this.bankAccountForm.get('accountNumber').value,
        accountCheckNumber: this.bankAccountForm.get('accountCheckNumber').value,
      });

      if (
        this.bankAccountForm.get('agencyCheckNumber').value.toLowerCase() === 'x' ||
        this.bankAccountForm.get('agencyCheckNumber').value === ''
      ) {
        this.bankAccountForm.get('agencyCheckNumber').setValue('0');
      }

      const payload = {
        ...trimData(this.bankAccountForm.value),
        sellerId: this.seller.id,
      };

      this.$bank.createBankAccount(payload).subscribe({
        next: (res) => {
          if (res?.data?.createBankAccount?.id) {
            this.getSeller();
            this.$message.success('Conta bancária criada com sucesso.');
            this.addingBankAccount = false;
          }

          this.loadingBtn = false;
        },
        error: (error) => {
          this.loadingBtn = false;
          this.$message.error(error?.message || 'Erro ao criar a conta bancária.');
          throw new Error(error);
        },
      });
    } catch (error) {
      this.loadingBtn = false;
      this.$message.error(Message.DIGIT_INVALID_ACCOUNT);
      throw new Error(error);
    }
  }

  public banSeller(): void {
    this.$modal
      .create({
        nzContent: ConfirmationDeleteComponent,
        nzComponentParams: {
          message: `Tem certeza que deseja banir o seller <strong>${this.sellerName}</strong>?`,
          isImportant: true,
          buttonName: 'Banir',
        },
        nzFooter: null,
        nzClosable: true,
      })
      .afterClose.subscribe((resp: boolean) => {
        if (resp) {
          this.$seller.banSeller(this.seller?.id).subscribe({
            next: (res) => {
              if (res) {
                this.$message.success('Seller banido com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error(error?.message || 'Erro ao banir o seller.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public blockSeller(): void {
    this.$modal
      .create({
        nzContent: ConfirmationDeleteComponent,
        nzComponentParams: {
          message: `Tem certeza que deseja reprovar o seller <strong>${this.sellerName}</strong>?`,
          isImportant: false,
          buttonName: 'Reprovar',
        },
        nzFooter: null,
        nzClosable: true,
      })
      .afterClose.subscribe((resp: boolean) => {
        if (resp) {
          this.$seller.blockSeller(this.seller?.id).subscribe({
            next: (res) => {
              if (res) {
                this.$message.success('Seller reprovado com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error(error?.message || 'Erro ao reprovar o seller.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public unblockSeller(): void {
    this.$modal
      .create({
        nzContent: ConfirmationDeleteComponent,
        nzComponentParams: {
          message: `Tem certeza que deseja aprovar o seller <strong>${this.sellerName}</strong>?`,
          isImportant: false,
          buttonName: 'Aprovar',
        },
        nzFooter: null,
        nzClosable: true,
      })
      .afterClose.subscribe((resp: boolean) => {
        if (resp) {
          this.$seller.unblockSeller(this.seller?.id).subscribe({
            next: (res) => {
              if (res) {
                this.$message.success('Seller aprovado com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error(error?.message || 'Erro ao aprovar o seller.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public reprocessSeller(): void {
    this.$modal
      .create({
        nzContent: ConfirmationDeleteComponent,
        nzComponentParams: {
          message: `Tem certeza que deseja reprocessar o seller <strong>${this.sellerName}</strong>?`,
          isImportant: false,
          buttonName: 'Reprocessar',
        },
        nzFooter: null,
        nzClosable: true,
      })
      .afterClose.subscribe((resp: boolean) => {
        if (resp) {
          this.$seller.reprocessSeller(this.seller?.id).subscribe({
            next: (res) => {
              if (res) {
                this.$message.success('Seller reprocessado com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error(error?.message || 'Erro ao reprocessar o seller.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public validateBankForm(option: string): void {
    this.accountMask = '';
    this.agencyMask = '';
    this.accountCheckNumberMask = '';
    this.agencyCheckNumberMask = '';
    this.minAccountMask = '';

    const bank: BankModel = this.banks.find((bank) => bank.code === option);

    this.bankName = bank?.name || '';

    if (bank?.agencyMask) {
      this.bankAccountForm.controls['agencyNumber'].setValidators([
        Validators.required,
        Validators.minLength(bank.agencyMask),
        Validators.maxLength(bank.agencyMask),
      ]);
      this.agencyMask = this.agencyMask.padStart(bank.agencyMask, '0');
      this.agencyErrorMsg = `A agência deve conter ${this.agencyMask.length} dígitos.`;
    } else {
      this.bankAccountForm.controls['agencyNumber'].setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(4),
      ]);
      this.agencyMask = this.agencyMask.padStart(4, '0');
      this.agencyErrorMsg = 'A agência deve conter de 1 a 4 dígitos';
    }

    if (bank?.accountMask) {
      this.bankAccountForm.controls['accountNumber'].setValidators([
        Validators.required,
        Validators.minLength(bank.minAccountMask ? bank.minAccountMask : bank.accountMask),
        Validators.maxLength(bank.accountMask),
      ]);
      this.minAccountMask = bank.minAccountMask ? this.minAccountMask.padStart(bank.minAccountMask, '0') : '';
      this.accountMask = this.accountMask.padStart(bank.accountMask, '0');
      this.accountErrorMsg = bank.minAccountMask
        ? `O numero da conta deve conter de
          ${this.minAccountMask.length} a
          ${this.accountMask.length} dígitos.`
        : `O número da conta deve conter ${this.accountMask.length} dígitos.`;
    } else {
      this.bankAccountForm.controls['accountNumber'].setValidators([
        Validators.required,
        Validators.minLength(bank?.minAccountMask ? bank.minAccountMask : 1),
        Validators.maxLength(9),
      ]);
      this.accountMask = this.accountMask.padStart(9, '0');
      this.accountErrorMsg = bank?.minAccountMask
        ? `O numero da conta deve conter de ${this.minAccountMask.length} a 9 dígitos.`
        : 'O numero da conta deve conter de 1 a 9 dígitos.';
    }

    if (bank?.accountCheckNumberMask) {
      this.bankAccountForm.controls['accountCheckNumber'].setValidators([
        Validators.required,
        Validators.minLength(bank.accountCheckNumberMask),
        Validators.maxLength(bank.accountCheckNumberMask),
      ]);
      this.accountCheckNumberMask = this.accountCheckNumberMask.padStart(bank.accountCheckNumberMask, '0');
    } else {
      this.bankAccountForm.controls['accountCheckNumber'].setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
      ]);
      this.accountCheckNumberMask = '0';
    }

    if (bank?.agencyCheckNumberMask) {
      this.bankAccountForm.controls['agencyCheckNumber'].setValidators([
        Validators.maxLength(bank.agencyCheckNumberMask),
      ]);
      this.agencyCheckNumberMask = this.agencyCheckNumberMask.padStart(bank.agencyCheckNumberMask, 'A');
    } else {
      this.agencyCheckNumberMask = '0';
      this.bankAccountForm.controls['agencyCheckNumber'].patchValue('');
    }

    this.bankAccountForm.controls['agencyNumber'].updateValueAndValidity();
    this.bankAccountForm.controls['accountNumber'].updateValueAndValidity();
    this.bankAccountForm.controls['accountCheckNumber'].updateValueAndValidity();
  }

  public numberAndXonly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode === 120) {
      return true;
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public format(): void {
    if (this.bankName === 'BANCO DO BRASIL') {
      this.bankAccountForm
        .get('accountNumber')
        .setValue(this.padLeft(this.bankAccountForm.get('accountNumber').value, '0', this.accountMask.length));
    }
  }

  private padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size - text.length) + text).substring(size * -1, size);
  }

  public showEditDrawer(): void {
    this.$drawer.create({
      nzContent: SellersEditComponent,
      nzContentParams: {
        seller: this.seller,
      },
      nzWidth: '80%',
      nzClosable: false,
    });
  }

  public handleAction(action: { action: string; data: DocumentModel }): void {
    if (action.action === 'APPROVE') {
      this.$methods.approve(action.data);
    }

    if (action.action === 'REJECT') {
      this.$methods.reject(action.data);
    }
  }
}
