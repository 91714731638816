import { gql } from 'apollo-angular';

const PROCESS_CHARGEBACK_FILE_MUTATION = gql`
  mutation processChargebackFile($file: Upload!) {
    processChargebackFile(file: $file) {
      id
    }
  }
`;

const CANCEL_PAYMENT_MUTATION = gql`
  mutation cancelPayment($id: UUID4!) {
    cancelPayment(id: $id)
  }
`;

export { PROCESS_CHARGEBACK_FILE_MUTATION, CANCEL_PAYMENT_MUTATION };
