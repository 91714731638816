import { gql } from 'apollo-angular';

const SUBSCRIPTION_FIELDS = `
  chargeDay
  chargeMonth
  creditCardId
  customer {
    id
    name
  }
  endDate
  externalId
  id
  insertedAt
  installments
  lastCharge
  location {
    latitude
    longitude
  }
  marketplace {
    id
  }
  method
  methods
  observation
  paymentAttempts
  priceId
  schedulePlans
  seller {
    id
    person {
      name
    }
    company {
      businessName
    }
  }
  sessionId
  startDate
  status
  totalCents
  updatedAt
`;

const LIST_SUBSCRIPTION_QUERY = gql`
  query ListSubscriptionsAdmin($filter: FilterSubscriptionsParams) {
    listSubscriptionsAdmin(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC }) {
      ${SUBSCRIPTION_FIELDS}
    }
  }
`;

const GET_SUBSCRIPTION_QUERY = gql`
  query getSubscription($id: UUID4!) {
    getSubscription(id: $id ) {
      ${SUBSCRIPTION_FIELDS}
    }
  }
`;

export { LIST_SUBSCRIPTION_QUERY, GET_SUBSCRIPTION_QUERY };
