import { AddressModel } from '../address/address.model';

export class ContactModel {
  public representative?: {
    name?: string;
    address?: AddressModel | null;
    cpf?: string;
  };
  public email?: string;
  public name?: string;
  public address?: AddressModel | null;
  public id?: string;
  public cnpj?: string | null;
  public cpf?: string | null;
  public insertedAt?: string;
  public phone?: string | null;
  public marketplaceId?: string;
  public shippingAddress?: AddressModel;
  public customerId?: string;
  public sellerId?: string;
  public birthDate?: string;
}
