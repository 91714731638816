import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidePartialCpf',
})
export class HidePartialCpfPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }

    const hidden = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.***.***-**');
    return hidden;
  }
}
