export class AddressModel {
  public id?: string;
  public city: string;
  public countryCode?: string;
  public line1: string; // Street
  public line2: string; // Number
  public line3: string; // Complement
  public neighborhood: string;
  public postalCode: string;
  public state: string;
}
