import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth/auth-business/auth.service';

@Component({
  selector: 'app-modal-blocked',
  templateUrl: './modal-blocked.component.html',
  styleUrls: ['./modal-blocked.component.scss'],
})
export class ModalBlockedComponent {
  constructor(private $auth: AuthService) {}

  public closeModal(): void {
    this.$auth.logout();
  }
}
