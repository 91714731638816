import { isEmpty } from 'lodash';
import { latLng } from 'leaflet';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

import { ContactDetailsComponent } from '../../contact-details/contact-details.component';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { PaymentsService } from 'libs/shared/src/lib/services/payments/payments.service';
import { ContactsService } from 'libs/shared/src/lib/services/contacts/contacts.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { PaymentsModel } from 'libs/shared/src/lib/models/payments/payments.model';
import { ContactModel } from 'libs/shared/src/lib/models/contact/contact.model';
import { SellerModel } from 'libs/shared/src/lib/models/sellers/sellers.model';
import { Message } from 'libs/shared/src/lib/utils/message';

@Component({
  selector: 'app-transactions-details',
  templateUrl: './transactions-details.component.html',
  styleUrls: ['./transactions-details.component.scss'],
})
export class TransactionsDetailsComponent implements OnInit {
  @Input() param: { id: string };

  public tariff: string = '';
  public methods: string = '';
  public calcDistance: string = '';
  public percentageTariff: string = '';
  public data: PaymentsModel = new PaymentsModel();
  public contact: ContactModel = new ContactModel();
  public form!: FormGroup;
  public loading: boolean = true;

  constructor(
    private readonly fb: FormBuilder,
    private $internal: InternalService,
    private $payments: PaymentsService,
    private $contacts: ContactsService,
    public readonly $modalRef: NzModalRef,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  ngOnInit(): void {
    this.getNotification();
    this.createForm();
  }

  public createForm(): void {
    this.form = this.fb.group({
      cancelReason: new FormControl(),
    });
  }

  public getNotification(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.getTransactions(res.marketplaceId, res.sellerId);
      }
    });
  }

  public getTransactions(marketplaceId: string, sellerId: string): void {
    this.$payments.getPaymentDetail(sellerId, this.param.id)?.subscribe({
      next: (payment) => {
        if (payment) {
          this.data = payment;
          const seller = payment?.seller || new SellerModel();

          this.data.discountCents = this.data?.discountCents ? this.data?.discountCents / 100 : 0;
          this.data.subtotalCents = this.data?.subtotalCents ? this.data?.subtotalCents / 100 : 0;
          this.getTariff(this.data?.additionCents);

          if (this.data?.customer && !isEmpty(this.data.customer)) {
            this.getLocation(seller);

            this.getContact(marketplaceId, sellerId);
          }

          if (this.data.order && !isEmpty(this.data.order)) {
            this.methods = this.$internal.getMethods(this.data?.order?.methods);
          }
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        throw new Error(error);
      },
    });
  }

  private getContact(marketplaceId: string, sellerId: string): void {
    this.$contacts
      .getContactDetailByCustomerId(marketplaceId, sellerId, this.data?.customer?.id || this.data?.customerId || '')
      ?.subscribe((contact) => {
        this.contact = contact[0];
      });
  }

  public getTariff(val?: number): void {
    if (val) {
      this.tariff = Number(val / 100).toString();
      const percent = (this.data.totalCents / 100) * (val / 100 / 100);
      this.percentageTariff = percent.toString();
    } else {
      this.tariff = '0';
    }
  }

  public copyContent(): void {
    const brcode = this.data?.pix?.brcode;

    if (brcode) {
      this.$internal.copyContent(brcode);
    }
  }

  public getLocation(seller: SellerModel): void {
    const lat1: number = this.data.location?.latitude || 0;
    const lng1: number = this.data.location?.longitude || 0;
    const lat2: number = seller?.location?.latitude || 0;
    const lng2: number = seller?.location?.longitude || 0;

    if (lat1 || lng1) {
      this.calcDistance =
        this.$internal.formatDistance(latLng(lat1, lng1).distanceTo(latLng(lat2, lng2))) || 'Indisponível';
    }
  }

  public cancelPayment(): void {
    if (this.data?.status === 'PAID' && this.data?.method === 'CREDIT_CARD') {
      this.loading = true;

      this.$payments
        .cancelPayment({
          id: this.param.id,
          cancelReason: this.form.get('cancelReason').value,
        })
        .subscribe({
          next: (response) => {
            if (response.status !== 200 && response.status !== 201) {
              this.$message.error(Message.ERROR_REQUESTING_CANCELLATION);
            } else {
              this.$message.success(Message.CANCELLATION_REQUESTED_SUCCESSFULLY);
            }

            this.loading = false;
          },
          error: (error) => {
            this.loading = false;
            throw new Error(error);
          },
        });
    }
  }

  public showContactDetails(): void {
    this.$drawer.create({
      nzTitle: 'Detalhes do Contato',
      nzWidth: '800px',
      nzContent: ContactDetailsComponent,
      nzContentParams: {
        value: this.contact,
      },
    });
  }
}
