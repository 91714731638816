import moment from 'moment';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { environment } from '@gen/environments';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';
import { SubscriptionModel } from '../../models/subscriptions/subscriptions.model';
import { GET_SUBSCRIPTION_QUERY, LIST_SUBSCRIPTION_QUERY } from '../../graphql/queries/subscription.queries';
import { FilterSubscriptionsParamsModel } from '../../models/subscriptions/filter-subscriptions-params.model';

@Injectable()
export class SubscriptionsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly firestore: AngularFirestore,
    private readonly $functions: AngularFireFunctions
  ) {}

  public createSubscription(data: any): Observable<any> {
    return this.$functions.httpsCallable('createSubscription')(data);
  }

  public getPublicSubscription(id: string): Observable<SubscriptionModel | undefined> {
    return this.firestore
      .doc<SubscriptionModel>(`marketplaces/${environment.marketplaceId}/subscriptions/${id}`)
      .valueChanges();
  }

  public getSubscriptionListWithCustomer(customerId: string, marketplaceId: string, sellerId: string): Observable<any> {
    return this.firestore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) =>
        ref.where('customerId', '==', customerId).orderBy('insertedAt', 'desc')
      )
      .valueChanges() as Observable<any>;
  }

  public getSubscriptionList(marketplaceId: string, sellerId: string): Observable<Array<SubscriptionModel>> {
    return this.collection(marketplaceId, sellerId)?.valueChanges();
  }

  public updateSubscription(data: any): Observable<any> {
    return this.$functions.httpsCallable('updateSubscription')(data);
  }

  public deleteSubscription(id: string): Observable<any> {
    return this.$functions.httpsCallable('deleteSubscription')(id);
  }

  public getDetailSubscription(marketplaceId: string, sellerId: string, id: string): Observable<SubscriptionModel> {
    return this.collection(marketplaceId, sellerId).doc(id).valueChanges();
  }

  public getActiveSubscriptions(marketplaceId: string, sellerId: string): Observable<SubscriptionModel[]> | undefined {
    return this.firestore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) =>
        ref.where('status', '==', 'active')
      )
      .valueChanges() as Observable<SubscriptionModel[]>;
  }

  public getSubscriptionsWithFilters(
    marketplaceId: string,
    sellerId: string,
    filter: ResponseFilterModel
  ): Observable<SubscriptionModel[]> {
    return this.collection(marketplaceId, sellerId, filter).valueChanges();
  }

  private collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): AngularFirestoreCollection<SubscriptionModel> {
    if (filter && !isEmpty(filter)) {
      return this.firestore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate = moment(filter.creationDateFilter.date).set({ hour: 0, minute: 0, second: 0 }).format();
            const endDate = moment(filter.creationDateFilter.date).set({ hour: 23, minute: 59, second: 59 }).format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }
        }

        if (filter.valuesFilter && !filter.creationDateFilter) {
          if (filter.valuesFilter.value) {
            switch (filter.valuesFilter.type) {
              case 0:
                query = query.where('totalCents', '==', filter.valuesFilter.value);
                break;
              case 2:
                query = query.where('totalCents', '>', filter.valuesFilter.value).orderBy('totalCents', 'asc');
                break;
              case 3:
                query = query.where('totalCents', '<', filter.valuesFilter.value).orderBy('totalCents', 'asc');
                break;
              default:
                break;
            }
          } else {
            query = query
              .where('totalCents', '>=', filter.valuesFilter.lowerValue)
              .where('totalCents', '<=', filter.valuesFilter.highestValue)
              .orderBy('totalCents', 'asc');
          }
        }

        if (filter.statusFilter) {
          const data: string[] = [];
          filter.statusFilter.forEach((status: any) => data.push(status.toLowerCase()));
          query = query.where('status', 'in', data);
        }

        if (filter.paymentMethodFilter && !filter.statusFilter) {
          query = query.where('method', 'in', filter.paymentMethodFilter);
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    return this.firestore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }

  public getSubscriptionListApollo(filter?: FilterSubscriptionsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SUBSCRIPTION_QUERY,
      variables: { filter },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSubscriptionFilterList(filter?: {
    beforeOrEqual?: string;
    afterOrEqual?: string;
    sellerId?: string;
    customerId?: string;
  }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SUBSCRIPTION_QUERY,
      variables: {
        afterOrEqual: filter.afterOrEqual,
        beforeOrEqual: filter.beforeOrEqual,
        sellerId: filter.sellerId,
        customerId: filter.customerId,
      },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSubscription(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SUBSCRIPTION_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }
}
