export const Message = {
  ERROR_CONECTION: 'Erro de conexão. Por favor, tente novamente mais tarde.',
  ERROR_LOAD_DATA:
    'Suas informações não foram carregadas corretamente. Por favor, atualize a página e tente novamente.',
  ERROR_SEARCHING_INFORMATION: 'Erro ao buscar as informações. Por favor, tente novamente mais tarde.',
  NOT_USER_REGISTER:
    'Não há registro de usuário correspondente a este identificador. O usuário pode ter sido excluído.',
  LOGIN_ERROR: 'Erro ao fazer o login. Por favor, tente novamente mais tarde...',
  LOGIN_SUCCESS: 'Login realizado com sucesso!',
  USER_NOT_FOUND: 'O email ou a senha estão incorretos.',
  ADRESS_EMAIL_ALREADY_EXISTS: 'O endereço de e-mail já está sendo usado por outra conta.',
  INVALID_NUMBER_PHONE: 'O número de telefone é inválido, verifique o valor informado e tente novamente.',
  REPORT_NUMBER_PHONE: 'Informe o número de telefone.',
  MANY_ATTEMPTS_LOGIN: 'Muitas tentativas de login. Aguarde alguns minutos e tente novamente.',
  ACCOUNT_OFF: 'A conta associada a este e-mail foi desativada.',
  MANY_ATTEMPTS_FAILED: 'Muitas tentativas com falha. Aguarde alguns minutos e tente novamente.',
  PHONE_ALREADY_IN_USE: 'Este celular já está sendo utilizado.',
  CPF_ALREADY_IN_USE: 'Este CPF já está sendo utilizado.',
  PHONE_NOT_FOUND: 'O telefone inserido não possui uma conta.',
  PHONE_ALREADY_EXISTS: 'O telefone inserido já está sendo usado por outra conta.',
  CODE_EXPIRED: 'O código SMS expirou. Reenvie o código de verificação para tentar novamente.',
  MANY_ATTEMPTS: 'Diversas tentativas realizadas. Aguarde alguns minutos e tente novamente.',
  INVALID_CODE: 'O código digitado é inválido. Tente novamente.',
  COULD_NOT_VERIFY_CODE: 'Não foi possível verificar o código. Aguarde alguns instante e tente novamente.',
  SOLICITATION_ERROR: 'Sua solicitação não pôde ser realizada. Tente novamente em alguns minutos',
  INCORRECT_PASSWORD: 'A senha está incorreta.',
  ERROR_ALTER_PASSWORD: 'Não foi possível alterar a senha. Aguarde alguns instante e tente novamente.',
  ERROR_SEND_EMAIL: 'Não foi possível enviar o e-mail. Aguarde alguns instante e tente novamente.',
  ERROR_SEND_EMAIL_PASSWORD: 'Erro ao enviar o email de recuperação de senha. Por favor, tente novamente mais tarde...',
  DIGIT_INVALID_ACCOUNT: 'Dígito da agência ou conta inválido. Revise e tente novamente.',
  NOT_USER: 'Não há registro de usuário correspondente a este identificador. O usuário pode ter sido excluído.',
  INVALID_EMAIL: 'O endereço de e-mail está formatado incorretamente.',
  CREATED_BANK_ERROR: 'Erro ao criar o banco. Por favor, tente novamente mais tarde.',
  BANK_ACCOUNT_CREATED: 'Conta bancária criada com sucesso.',
  ERROR_REGISTER_ACCOUNT: 'Não foi possível criar sua conta. Aguarde alguns instante e tente novamente.',
  CEP_NOT_FOUND: 'CEP não encontrado.',
  CONTACT_CREATED: 'Contato criado com sucesso!',
  CONTACT_UPDATED: 'Contato atualizado com sucesso!',
  CONTACT_DELETED: 'Contato deletado com sucesso!',
  ERROR_UPDATED_CONTACT: 'Ocorreu um erro ao editar o contato. Por favor, tente novamente mais tarde.',
  ERROR_DELETED_CONTACT: 'Ocorreu um erro ao deletar o contato. Por favor, tente novamente mais tarde.',
  PLAN_CREATED: 'Plano criado com sucesso!',
  ERROR_CREATED_PLAN: 'Erro ao criar um plano. Por favor, tente novamente mais tarde.',
  ERROR_DELETED_PLAN: 'Ocorreu um erro ao excluir o plano. Por favor, tente novamente mais tarde.',
  PLAN_REMOVED_SUCCESS: 'Plano removido com sucesso!',
  ERROR_SAVE_PLAN: 'Erro ao salvar o plano. Por favor, tente novamente mais tarde.',
  PLAN_INACTIVE: 'Plano inativado com sucesso!',
  ERROR_INACTIVE_PLAN: 'Erro ao inativar o plano. Por favor, tente novamente mais tarde.',
  PLAN_ACTIVE: 'Plano ativado com sucesso!',
  ERROR_ACTIVE_PLAN: 'Erro ao ativar o plano. Por favor, tente novamente mais tarde.',
  UPDATE_PLAN: 'Plano atualizado com sucesso!',
  ERROR_UPDATE_PLAN: 'Erro ao atualizar o plano. Por favor, tente novamente mais tarde.',
  LIMIT_OPTION_PLAN: 'Limite máximo de modalidades atingido.',
  ERROR_CREATED_CONTACT: 'Ocorreu um erro ao criar o contato. Por favor, tente novamente mais tarde.',
  SIZE_FILE_SUPPORTED_2MB: 'Documento com tamanho maior que o suportado, tamanho suportado até 2mb',
  SIZE_IMG_2MB: 'Por favor, insira uma imagem de no máximo 2 MB.',
  EXCEL_FORMATS: 'Apenas os formatos .xls,.xlsx,.csv,.ods,.xlam,.xlsm são aceitos.',
  NOT_FILE: 'Selecione um arquivo para importar.',
  EMPTY_FILE: 'Arquivo vazio...',
  SUBSCRIPTION_CREATED: 'Fatura recorrente criada com sucesso!',
  ERROR_CREATED_SUBSCRIPTION: 'Erro ao criar uma fatura recorrente. Por favor, tente novamente mais tarde.',
  CREATED_ERROR_INVOICE: 'Ocorreu um erro ao criar sua fatura. Por favor, tente novamente mais tarde.',
  COUPON_CREATED: 'Cupom criado com sucesso!',
  ERROR_CREATED_COUPON: 'Erro ao criar o cupom. Por favor, tente novamente mais tarde.',
  ERROR_SAVE_COUPON: 'Ocorreu um erro ao salvar o cupom. Por favor, tente novamente mais tarde.',
  UPDATE_COUPON: 'Cupom atualizado com sucesso!',
  ERROR_UPDATED_COUPON: 'Erro ao atualizar o cupom. Por favor, tente novamente mais tarde.',
  COUPON_DELETED: 'Cupom deletado com sucesso!',
  ERROR_DELETED_COUPON: 'Erro ao deletar o cupom. Por favor, tente novamente mais tarde.',
  CATEGORY_CREATED: 'Categoria criada com sucesso!',
  ERROR_CREATED_CATEGORY: 'Erro ao criar a categoria. Por favor, tente novamente mais tarde.',
  ERROR_UPDATED_CATEGORY: 'Erro ao atualizar a categoria. Por favor, tente novamente mais tarde.',
  UPDATED_CATEGORY: 'Categoria atualizada com sucesso!',
  ERROR_SAVE_CATEGORY: 'Erro ao salvar a categoria. Por favor, tente novamente mais tarde.',
  ERROR_CONNECT_CATEGORY: 'Erro ao associar a categoria aos itens selecionados. Por favor, tente novamente mais tarde.',
  CONNECT_CATEGORY: 'Categoria associada com sucesso!',
  ERROR_DELETED_CATEGORY: 'Erro ao deletar a categoria. Por favor, tente novamente mais tarde.',
  DELETED_CATEGORY: 'Categoria deletada com sucesso!',
  CREATED_OPTION: 'Opção criada com sucesso!',
  OPTION_IMPORTED: 'Opções importadas com sucesso!',
  OPTION_UPDATE: 'Opção atualizada com sucesso!',
  ERROR_UPDATE_OPTION: 'Erro ao atualizar a opção. Por favor, tente novamente mais tarde.',
  ERROR_CREATED_OPTION: 'Erro ao criar a opção. Por favor, tente novamente mais tarde.',
  ERROR_SAVE_OPTION: 'Erro ao salvar a opção. Por favor, tente novamente mais tarde.',
  ERROR_REMOVE_OPTION: 'Erro ao remover a opção. Por favor, tente novamente mais tarde.',
  OPTION_REMOVED: 'Opção removida com sucesso!',
  CREATED_ITEM: 'Item criado com sucesso!',
  ERROR_CREATED_ITEM: 'Erro ao criar o item. Por favor, tente novamente mais tarde.',
  ERROR_SAVE_ITEM: 'Erro ao salvar o item. Por favor, tente novamente mais tarde.',
  ERROR_IMPORT_ITEM: 'Erro ao importar os items. Por favor, tente novamente mais tarde.',
  IMPORT_ITEM: 'Items importados com sucesso!',
  UPDATE_ITEM: 'Item atualizado com sucesso!',
  ERROR_UPDATE_ITEM: 'Erro ao atualizar o item. Por favor, tente novamente mais tarde.',
  COPY_PAYMENT_LINK: 'Link de pagamento copiado com sucesso!',
  ERROR_COPY_PAYMENT_LINK: 'Não é possível copiar um link de itens inativados!',
  NOT_POSSIBLE_DELETE_ITEM_ASSOCIATED_INVOICE: 'Não é possível excluir um item associada à uma fatura.',
  ERROR_REMOVED_ITEM: 'Erro ao remover o item. Por favor, tente novamente mais tarde.',
  ERROR_DELETED_ITEM: 'Erro ao deletar o item. Por favor, tente novamente mais tarde.',
  ERROR_UPDATED_ITEM: 'Erro ao atualizar o item. Por favor, tente novamente mais tarde.',
  ITEM_REMOVED_SUCCESS: 'Item removido com sucesso!',
  ITEM_DELETED: 'Item deletado com sucesso!',
  ITEM_UPDATED: 'Item atualizado com sucesso!',
  URL_NOT_STANDARDS: 'A URL inserida não esta dentro dos padrões.',
  URL_NOT_IFOOD: 'A URL inserida deve ser a do Ifood.',
  CNPJ_EXISTS: 'O CNPJ digitado já está sendo utilizado.',
  CPF_EXISTS: 'O CPF digitado já está sendo utilizado.',
  ERROR_UPDATED_ORDER_TYPE: 'Erro! Não foi possível alterar o tipo dos pedidos.',
  ERROR_UPDATED_UNIT: 'Erro ao atualizar a unidade. Por favor, tente novamente mais tarde.',
  UPDATE_UNIT: 'Unidade atualizada com sucesso!',
  ERROR_UNIT_CREATED: 'Erro ao criar a unidade. Por favor, tente novamente mais tarde.',
  UNIT_CREATED: 'Unidade criada com sucesso!',
  ERROR_SAVE_UNIT: 'Erro ao salvar a unidade. Por favor, tente novamente mais tarde.',
  REMOVE_UNIT: 'Unidade removida com sucesso!',
  FILL_ALL_FIELDS: 'Preencha todos os campos.',
  CONFIRM_EMAIL: 'E-mail de confirmação enviado com sucesso!',
  EMAIL_ALREADY_BEING_USED: 'O e-mail inserido já está sendo utilizado.',
  CONFIRM_EMAIL_SEND_ERROR: 'Erro ao enviar o e-mail de confirmação.',
  PHONE_UPDATED: 'Telefone atualizado com sucesso!',
  ERROR_UPDATE_PASSWORD: 'Erro! Sua senha não foi alterada. Por favor, tente novamente mais tarde.',
  UPDATE_PASSWORD: 'Senha alterada com sucesso!',
  ORDER_UPDATED: 'Pedido atualizado com sucesso.',
  INVOICE_UPDATED: 'Fatura simples atualizada com sucesso.',
  ERROR_UPDATED_ORDER: 'Erro ao atualizar o seu pedido. Por favor, tente novamente mais tarde.',
  ERROR_CREATE: 'Erro ao criar. Por favor, tente novamente mais tarde.',
  ACCOUNT_ENVIRONMENT_ERROR:
    'Os ambientes ainda não foram criados para esta conta. Por favor, entre em contato com o nosso suporte para mais informações.',
  ACCOUNT_SANDBOX_ERROR:
    'O ambiente de sandbox ainda não foram criado para esta conta. Por favor, entre em contato com o nosso suporte para mais informações.',
  ERROR_UPDATE_ENVIRONMENT: 'Erro ao alterar o ambiente. Por favor, tente novamente mais tarde.',
  ENVIRONMENT_UPDATED: 'Ambiente alterado com sucesso!',
  ERROR_DELETED_USER: 'Erro ao deletar o usuário. Por favor, tente novamente mais tarde.',
  USER_DELETED: 'Usuário deletado com sucesso.',
  USER_UPDATED: 'Usuário atualizado com sucesso.',
  ERROR_USER_UPDATED: 'Erro ao atualizar o usuário. Por favor, tente novamente mais tarde.',
  ERROR_CREATED_USER: 'Erro ao criar o usuário. Por favor, tente novamente mais tarde.',
  USER_CREATED: 'Usuário criado com sucesso.',
  ERROR_SAVE_USER: 'Erro ao criar o usuário. Por favor, tente novamente mais tarde.',
  ERROR_CONFIRM_PAYMENT: 'Erro ao confirmar o pagamento dos pedidos. Por favor, tente novamente mais tarde.',
  ERROR_SELLER_ADMIN_EMAIL_SAME_AS_USER: 'Você não pode criar um usuário com o mesmo email da sua conta principal.',
  USER_EMAIL_ALREADY_USED: 'Email já esta sendo utilizado.',
  SUCCESS_DONWLOAD_EXTRACTS: 'Extrato baixado com sucesso!',
  ERROR_SEND_DOCUMENTS: 'Erro ao realizar o envio do documento. Por favor, tente novamente mais tarde.',
  SUCCESS_SEND_DOCUMENTS: 'Documento enviado com sucesso.',
  ERROR_LOADING_DOCUMENT: 'O documento não foi carregado corretamente. Por favor, tente novamente mais tarde.',
  ERROR_REQUESTING_WITHDRAWAL: 'Ocorreu um erro ao solicitar um saque. Por favor, tente novamente mais tarde.',
  SUCCESSFUL_WITHDRAWAL: 'Solicitação do saque recebida com sucesso!',
  ERROR_CAPTURE_BALANCE: 'Erro ao capturar seu saldo. Recarregue a tela e tente novamente.',
  ERROR_CONFIRMATED_SALES: 'Ocorreu um erro ao confirmar as transações. Por favor, tente novamente mais tarde.',
  TRANSACTIONS_CONFIRMED: 'Transações confirmadas com sucesso!',
  TRANSACTION_CONFIRMED: 'Transação confirmada com sucesso!',
  CANCELLATION_REQUESTED_SUCCESSFULLY: 'Cancelamento solicitado com sucesso!',
  ERROR_REQUESTING_CANCELLATION: 'Erro ao solicitar o cancelamento. Por favor, tente novamente mais tarde.',
  PIX_CODE_COPIED_SUCCESSFULLY: 'Código Pix copiado com sucesso!',
  CHOOSE_AT_LEAST_ONE_PRODUCT: 'Escolha ao menos um produto para vincular com a categoria escolhida.',
  ERROR_ACTIVE_ITEMS: 'Tente novamente mais tarde, não foi possível ativar o item',
  ERROR_CREATED_PAYMENT_LINK: 'Erro ao criar o link de pagamento. Por favor, tente novamente mais tarde.',
  PAYMENT_LINK_CREATED: 'Link de pagamento criado com sucesso!',
  PAYMENT_LINK_DELETED: 'Link de pagamento excluído com sucesso!',
  PAYMENT_LINK_ACTIVE: 'Link de pagamento ativado com sucesso!',
  PAYMENT_LINK_INACTIVE: 'Link de pagamento desativado com sucesso!',
  UPDATED_PAYMENT_LINK: 'Link de pagamento atualizado com sucesso!',
  ERROR_UPDATED_PAYMENT_LINK: 'Erro ao atualizar o link de pagamento. Por favor, tente novamente mais tarde.',
  ERROR_ACTIVE_PAYMENT_LINK: 'Erro ao ativar o link de pagamento. Por favor, tente novamente mais tarde.',
  ERROR_DELETED_PAYMENT_LINK: 'Erro ao excluir o link de pagamento. Por favor, tente novamente mais tarde.',
  MIN_VALUE: 'Valor mínimo de $ 10,00',
  MESSAGE_ERROR_PASSWORD_PATTERN: 'Preencha todos os requisitos para criar sua senha mais segura.',
  MESSAGE_ERROR_PASSWORD_REQUIRED: 'Campo obrigatório.',
  MESSAGE_ERROR_CONFIRM_PASSWORD_VALID: 'As senhas devem ser iguais!',
  UPDATE_DATA_SUCCESS: 'Dados atualizados com sucesso!',
  MESSAGE_DATA_ERROR: 'Erro ao atualizar as informações. Por favor, tente novamente mais tarde.',
  EVENT_CREATED: 'Evento cadastrado com sucesso!',
  ERROR_GET_USERS: 'Erro ao carregar a lista de usuários!',
  MESSAGE_BLACKLIST_SUCCESS: 'Bloqueado com sucesso!',
  MESSAGE_UPDATE: 'Atualizado com sucesso',
  MESSAGE_BLACKLIST_ERROR: 'Erro ao bloquear.',
  MESSAGE_UPDATE_ERROR: 'Erro ao atualizar',
};
