import { Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-modal-upload-chargeback',
  templateUrl: './modal-upload-chargeback.component.html',
  styleUrls: ['./modal-upload-chargeback.component.scss'],
})
export class ModalUploadChargebackComponent {
  public previewImage: string | undefined = '';
  public previewVisible: boolean = false;
  public file: File | undefined;

  constructor(private modalRef: NzModalRef) {}

  public closed(value: any): void {
    if (value) {
      this.modalRef.destroy(this.file);
    } else {
      this.modalRef.destroy();
    }
  }

  public getBase64(file: File): string | ArrayBuffer | null {
    const reader = new FileReader();
    let result: string | ArrayBuffer = '';
    reader.readAsDataURL(file);
    reader.onload = () => (result = reader.result);
    return result;
  }

  public handleChange(event: NzUploadChangeParam): void {
    if (event.type === 'start') {
      event.file.status = 'success';
      this.file = event.file.originFileObj;
    }
  }

  public customRequest(file: NzUploadXHRArgs): Subscription {
    return new Subscription();
  }

  public handlePreview = (file: NzUploadFile): void => {
    if (!file.url && !file['preview']) {
      file['preview'] = this.getBase64(file.originFileObj!);
    }

    this.previewImage = file.url || file['preview'];
    this.previewVisible = true;
  };
}
