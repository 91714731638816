import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgxQrcodeElementTypes } from '@techiediaries/ngx-qrcode';
import { PaymentsModel } from '../../../models/payments/payments.model';

@Component({
  selector: 'app-pix',
  templateUrl: './pix.component.html',
  styleUrls: ['./pix.component.scss'],
})
export class PixComponent {
  public elementType = NgxQrcodeElementTypes.URL;

  constructor(public clipBoard: Clipboard, public $message: NzMessageService) {}
  @Input() payment: PaymentsModel;

  public copyLink(): void {
    const brcode = this.payment?.pix?.brcode;
    this.clipBoard.copy(brcode || '');
    this.$message.success('Código copiado com sucesso!');
  }
}
