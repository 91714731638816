import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment-timezone';
import html2pdf from 'html2pdf.js';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StateManagementService } from '../../../state-management/state-management.service';
import { PaymentInfo } from '../../../models/payments/payment-info.model';
import { ItemModel } from '../../../models/items/item.model';
import { base64ToBlob } from '../../../utils/files';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {
  @Input() paymentInfo: PaymentInfo;

  public items: ItemModel[];

  constructor(private readonly $notification: StateManagementService, private $message: NzMessageService) {}

  public ngOnInit(): void {
    this.getItems();
  }

  public back(): void {
    this.$notification.setTypeResult('SUCCESS');
  }

  public async shareReceipt(): Promise<void> {
    try {
      const element = document.getElementById('receiptPDF');
      const filename = `recibo-${moment().format()}.pdf`;
      const navigatorExtends: any = navigator;

      if (navigator.share) {
        const data = await html2pdf().from(element).outputImg('dataurlstring');

        const blob = base64ToBlob(data.split(',')[1]);

        const image = new File([blob], 'gen.png', { type: blob.type });
        const files = [image];
        const payload = {
          files,
          title: `Comprovante - ${this.paymentInfo.seller?.nickname}`,
        };

        if (!navigatorExtends.canShare(payload)) {
          throw new Error("Can't share data.");
        }

        await navigatorExtends.share(payload);
      } else {
        this.$message.error('essa navegador não suporta compartilhamento de arquivos.');
      }

      await html2pdf().from(element).save(filename);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public getItems(): void {
    if (this.paymentInfo.typePage === 'SUBSCRIPTION') {
      this.items = this.paymentInfo?.subscription?.items;
    }

    if (this.paymentInfo.typePage === 'PLAN') {
      this.items = this.paymentInfo?.prices?.items;
    }

    if (this.paymentInfo.typePage === 'ORDER') {
      this.items = this.paymentInfo?.order?.items;
    }
  }
}
