import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CpfValidator } from '../../../../validators/cpfValidator';
import { CnpjValidator } from '../../../../validators/cnpjValidator';

@Component({
  selector: 'app-drawer-create-blacklist',
  templateUrl: './drawer-create-blacklist.component.html',
  styleUrls: ['./drawer-create-blacklist.component.scss'],
})
export class DrawerCreateBlacklistComponent implements OnInit {
  @Input() data: any;

  public formDescription!: FormGroup;
  public textButton: string = '';

  constructor(private fb: FormBuilder, private drawerRef: NzDrawerRef) {}

  public ngOnInit(): void {
    this.createForm();
    this.getInfosField(this.data.infos);
  }

  public createForm(): void {
    this.formDescription = this.fb.group({
      kindOfBlacklist: new FormControl('ESTABLISHMENT', [Validators.required]),
      name: new FormControl(''),
      id: new FormControl({ value: '', disabled: true }),
      telephone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      personalDocument: new FormControl('', [Validators.required, CpfValidator.isValid()]),
      businessDocument: new FormControl('', [Validators.required, CnpjValidator.isValid()]),
    });
  }

  public getInfosField(data: any): void {
    if (this.data.type === 'edit') {
      this.formDescription.patchValue({
        id: data.id,
        telephone: data.phone,
        email: data.email,
        personalDocument: data.cpf,
        businessDocument: data.cnpj || '',
      });
    }
  }

  public getFormValues(): void {
    this.drawerRef.close(this.formDescription.value);
  }
}
