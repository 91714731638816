import { LocationType } from '../../types/location-type';
import { PaymentMethods } from '../../types/payment-methods.type';
import { CouponModel } from '../coupon/coupon.model';
import { CustomerModel } from '../customer/customer.model';
import { ItemsModel } from '../items/items.model';
import { LinksModel } from '../links/links.model';
import { OrderModel } from '../orders/order.model';
import { PlansModel } from '../plans/plans.model';
import { PriceModel } from '../plans/price.model';
import { SellerModel } from '../sellers/sellers.model';
import { SubscriptionModel } from '../subscriptions/subscriptions.model';
import { CreditCardModel } from './../credit-card/credit-card.model';
import { PaymentsModel } from './payments.model';

export class PaymentInfo {
  public order?: OrderModel;
  public paymentLink?: LinksModel;
  public customer?: CustomerModel;
  public seller?: SellerModel;
  public method?: string;
  public item?: ItemsModel;
  public payment?: PaymentsModel;
  public redirectTo?: string;
  public installments?: number;
  public token?: string;
  public location?: LocationType;
  public coupon?: CouponModel;
  public typePage?: string;
  public externalId?: string;
  public paymentMethods?: PaymentMethods[];
  public creditCard?: CreditCardModel;
  public expirationDate?: string;
  public plans?: PlansModel;
  public scheduleList?: Array<{ value: string; label: string }>;
  public prices?: PriceModel;
  public subscription?: SubscriptionModel;
}
