import { PersonModel } from './person.model';
import { CompanyModel } from './company.model';
import { SettingsModel } from './settings.model';
import { LocationModel } from 'libs/shared/src/lib/models/utils/location.model';
import { SellerStatusType } from '../../types/seller/seller-status-type.type';
import { BankAccountModel } from '../bank-account/bank-account.model';
import { DocumentModel } from '../document/document.model';

export class SellerModel {
  public id?: string = '';
  public name: string = '';
  public email: string = '';
  public cpf: string = '';
  public nickname?: string = '';
  public formatedDocument?: string = '';
  public cnpj?: string = '';
  public phone: string = '';
  public occupation: string = '';
  public location: LocationModel = new LocationModel();
  public businessSegmentId: string = '';
  public company?: CompanyModel = new CompanyModel();
  public person: PersonModel = new PersonModel();
  public birthDate?: string = '';
  public status: SellerStatusType;
  public marketplaceId?: string = '';
  public imageUrl?: string = '';
  public insertedAt: string = '';
  public updatedAt: string = '';
  public termVersion?: string = '';
  public termAcceptedAt?: string = '';
  public settings?: SettingsModel = new SettingsModel();
  public versions?: Version[];
  public limits?: Limit[];
  public externalConsults?: ExternalConsult[];
  public bankAccounts?: BankAccountModel[];
  public getSeller?: SellerModel;
  public listSellers?: SellerModel[];
  public emailVerified?: boolean = false;
  public phoneVerified?: boolean = false;
  public receivingLimit?: number = 0;
  public displayName?: string = '';
  public documents?: DocumentModel[] = [];
  public isStore?: boolean = false;
  public observation?: string = '';
}

export type Version = {
  event?: string;
  insertedAt?: Date;
};

export type Limit = {
  action?: Action;
  functionality?: Functionality;
  category?: Category;
  perPeriod?: Period;
  amount?: number;
};

export type ExternalConsult = {
  cpfCnpj?: string;
  datasets?: string[];
  dataJson?: JSON;
};

export type Action = {
  name?: string;
};

export type Functionality = {
  name?: string;
};

export type Category = {
  name?: string;
};

export type Period = {
  name?: string;
};
