import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SellerModel } from '../../../models/sellers/sellers.model';

import { AuthService } from '../../../services/auth/auth-business/auth.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { Message } from '../../../utils/message';
import { ValidationPhoneComponent } from '../../security/validation-phone/validation-phone.component';

@Component({
  selector: 'app-modal-change-phone',
  templateUrl: './modal-change-phone.component.html',
  styleUrls: ['./modal-change-phone.component.scss'],
})
export class ModalChangePhoneComponent implements OnInit {
  public formDescription: any;
  public seller: SellerModel;

  public isConfirmPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private $notification: StateManagementService,
    private $auth: AuthService,
    private modalRef: NzModalRef,
    private $modal: NzModalService,
    private $message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getSeller();
  }

  public createForm(): void {
    this.formDescription = this.fb.group({
      phone: new FormControl(),
      password: new FormControl(),
    });
  }

  public getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      this.seller = res;
    });
  }

  public setHandleSubmit(value?): void {
    if (value) {
      this.modalRef.close(this.formDescription.value);
    } else {
      this.modalRef.close();
    }
  }

  public nextStep(): void {
    this.isConfirmPassword = true;
  }

  public async confirmPassword(): Promise<void> {
    try {
      const result = await this.$auth.validatePassword(this.seller.email, this.formDescription.get('password').value);

      if (result.user) {
        const params: Object = {
          data: {
            ...this.seller,
            phone: this.formDescription.get('phone').value,
          },
        };

        this.$modal.create({
          nzComponentParams: params,
          nzTitle: 'Validação do telefone',
          nzWidth: '500px',
          nzContent: ValidationPhoneComponent,
        });
      }
    } catch (error: any) {
      this.$message.error(Message.INCORRECT_PASSWORD);
    }
  }
}
