import { ItemsModel } from './../../models/items/items.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent {
  @Input() product: ItemsModel;
  @Input() loading: boolean;

  public favoriteHearthUrl = '../../assets/images/clicked-heart.svg';
  public unFavoriteHearthUrl = '../../assets/images/clicked-heart.svg';

  public clickedFavorite: boolean = false;
  public standardImg: string = 'https://www.surfdome.ie/images/shared/pdp-placeholder.png';

  constructor() {}
}
