export class RouteParamsModel {
  public mallId?: string = '';
  public itemId?: string = '';
  public planId?: string = '';
  public orderId?: string = '';
  public pointId?: string = '';
  public sellerId?: string = '';
  public ticketId?: string = '';
  public redirectTo?: string = '';
  public subscriptionId?: string = '';
}
