import { gql } from 'apollo-angular';

const UPDATE_DOCUMENT_STATUS = gql`
  mutation UpdateDocumentStatus($id: UUID4!, $reason: String!, $status: String!) {
    updateDocument(id: $id, params: { reason: $reason, status: $status }) {
      id
      status
    }
  }
`;

export { UPDATE_DOCUMENT_STATUS };
