import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ignoreHotkeys } from '../../../utils/utils';
import { ShowFilterModel } from '../../../models/filters/show-filter.model';
import { FilterDataModel } from '../../../models/filters/filter-data.model';
import { CategoryModel } from '../../../models/categories/categories.model';
import { CategoryService } from '../../../services/item/category/category.service';
import { ResponseFilterModel } from '../../../models/filters/response-filter.model';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.scss'],
})
export class ModalFilterComponent implements OnInit {
  @Input() public inputName?: string = 'Nome';
  @Input() public filters: ShowFilterModel = new ShowFilterModel();
  @Input() public statusList?: { [key: string]: string } = {};

  public formGroup!: FormGroup;
  public dateFormat: string = 'dd/MM/yyyy';
  public gutter: { [key: string]: number } = { xs: 8, sm: 16, md: 24, lg: 32 };
  private selectedFilters: FilterDataModel = new FilterDataModel();
  public categories: Array<CategoryModel> = [];

  constructor(
    private readonly fb: FormBuilder,
    private $category: CategoryService,
    private readonly modalRef: NzModalRef,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getFilterData();

    if (this.filters.isFilterCategory) {
      this.getSeller();
    }
  }

  private getFilterData(): void {
    this.$notification.filterDatas.subscribe((filterData) => {
      if (filterData) {
        this.selectedFilters = filterData;
        this.updateForm();
      }
    });
  }

  private getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.getCategories(res.id);
      }
    });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      name: new FormControl(''),
      document: new FormControl(''),
      email: new FormControl(''),
      creationDate: new FormControl([]),
      expirationDate: new FormControl([]),
      fromAmount: new FormControl(0),
      toAmount: new FormControl(0),
      paymentMethod: new FormControl([]),
      schedule: new FormControl([]),
      status: new FormControl([]),
      category: new FormControl([]),
    });
  }

  private updateForm(): void {
    let creationDate = [];
    let expirationDate = [];

    if (!isEmpty(this.selectedFilters?.selectedCreationDate)) {
      creationDate = [
        moment(this.selectedFilters?.selectedCreationDate?.startDate).toDate(),
        moment(this.selectedFilters?.selectedCreationDate?.endDate).toDate(),
      ];
    }

    if (!isEmpty(this.selectedFilters?.selectedExpirationDate)) {
      expirationDate = [
        moment(this.selectedFilters?.selectedExpirationDate?.startDate).toDate(),
        moment(this.selectedFilters?.selectedExpirationDate?.endDate).toDate(),
      ];
    }

    this.formGroup.patchValue({
      name: this.selectedFilters?.selectedName || '',
      document: this.selectedFilters?.selectedDocument || '',
      email: this.selectedFilters?.selectedEmail || '',
      creationDate,
      expirationDate,
      fromAmount: Number(this.selectedFilters?.selectedFromAmount / 100) || 0,
      toAmount: Number(this.selectedFilters?.selectedToAmount / 100) || 0,
      paymentMethod: this.selectedFilters?.selectedPaymentMethod || [],
      schedule: this.selectedFilters?.selectedSchedule || [],
      status: this.selectedFilters?.selectedStatus || [],
      category: this.selectedFilters?.selectedCategory || [],
    });
  }

  public clearFields(): void {
    this.selectedFilters = {};
    this.formGroup?.reset();
    this.$notification.setFilterData({});
  }

  private getCategories(sellerId: string): void {
    this.$category.getCategoryList(sellerId)?.subscribe({
      next: (res) => {
        if (res?.data?.listItemCategories) {
          this.categories = res.data.listItemCategories;
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public setHandleSubmit(): void {
    if (this.formGroup.valid) {
      this.computeFilter();
    } else {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  public onlyNumbers(event: any): void {
    return ignoreHotkeys(event);
  }

  public computeFilter(): void {
    let response = {} as ResponseFilterModel;
    const form = this.formGroup.value;

    if (form.creationDate && form.creationDate.length > 0) {
      let date: any = {};
      if (typeof form.creationDate[0] === 'string') {
        date = {
          startDate: moment(moment(form.creationDate[0], 'DD/MM/YYYY')).format('YYYY-MM-DD'),
          endDate: moment(moment(form.creationDate[1], 'DD/MM/YYYY')).format('YYYY-MM-DD'),
        };
      } else {
        date = {
          startDate: moment(moment(form.creationDate[0])).format('YYYY-MM-DD'),
          endDate: moment(moment(form.creationDate[1])).format('YYYY-MM-DD'),
        };
      }

      if (
        date.endDate !== 'Invalid date' &&
        date.startDate !== 'Invalid date' &&
        date.startDate !== 'Data inválida' &&
        date.endDate !== 'Data inválida'
      ) {
        response = Object.assign(response, { creationDateFilter: date });
        this.selectedFilters.selectedCreationDate = date;
      }
    }

    if (form.expirationDate && form.expirationDate.length > 0) {
      let date: any = {};
      if (typeof form.expirationDate[0] === 'string') {
        date = {
          startDate: moment(moment(form.expirationDate[0], 'DD/MM/YYYY')).format('YYYY-MM-DD'),
          endDate: moment(moment(form.expirationDate[1], 'DD/MM/YYYY')).format('YYYY-MM-DD'),
        };
      } else {
        date = {
          startDate: moment(moment(form.expirationDate[0])).format('YYYY-MM-DD'),
          endDate: moment(moment(form.expirationDate[1])).format('YYYY-MM-DD'),
        };
      }

      if (
        date.endDate !== 'Invalid date' &&
        date.startDate !== 'Invalid date' &&
        date.startDate !== 'Data inválida' &&
        date.endDate !== 'Data inválida'
      ) {
        response = Object.assign(response, { expirationDateFilter: date });
        this.selectedFilters.selectedExpirationDate = date;
      }
    }

    if (form.paymentMethod && form.paymentMethod.length > 0) {
      this.selectedFilters.selectedPaymentMethod = form.paymentMethod;
      response = Object.assign(response, { paymentMethodFilter: form.paymentMethod });
    }

    if (form.status && form.status.length > 0) {
      this.selectedFilters.selectedStatus = form.status;
      response = Object.assign(response, { statusFilter: form.status });
    }

    if (form.name && form.name.length > 0) {
      this.selectedFilters.selectedName = form.name;
      response = Object.assign(response, { nameFilter: form.name });
    }

    if (form.category && form.category.length > 0) {
      this.selectedFilters.selectedCategory = form.category;
      response = Object.assign(response, { categoryFilter: form.category });
    }

    if (form.document && form.document.length > 0) {
      this.selectedFilters.selectedDocument = form.document;
      response = Object.assign(response, { documentFilter: form.document });
    }

    if (form.email && form.email.length > 0) {
      this.selectedFilters.selectedEmail = form.email;
      response = Object.assign(response, { emailFilter: form.email });
    }

    if (form.toAmount && form.toAmount > 0) {
      const fromAmount = Number(form.fromAmount * 100);
      const toAmount = Number(form.toAmount * 100);

      this.selectedFilters.selectedToAmount = toAmount;
      let values = {};

      if (fromAmount && fromAmount > 0) {
        this.selectedFilters.selectedFromAmount = fromAmount;
        values = {
          lowerValue: fromAmount,
          highestValue: toAmount,
        };
      } else {
        values = {
          value: toAmount,
          type: 3,
        };
      }

      response = Object.assign(response, { valuesFilter: values });
    }

    if (form.schedule && form.schedule.length > 0) {
      this.selectedFilters.selectedSchedule = form.schedule;
      response = Object.assign(response, { scheduleFilter: form.schedule });
    }

    response = this.checkRemovedFilters(response);
    this.$notification.setFilterData(this.selectedFilters);
    this.modalRef.destroy(response);
  }

  public checkRemovedFilters(response: ResponseFilterModel): ResponseFilterModel {
    const form = this.formGroup.value;

    if (form.status?.length === 0 && this.selectedFilters.selectedStatus?.length > 0) {
      delete this.selectedFilters.selectedStatus;
      delete response.statusFilter;
    }

    if (form.expirationDate?.length === 0 && !isEmpty(this.selectedFilters.selectedExpirationDate)) {
      delete this.selectedFilters.selectedExpirationDate;
      delete response.expirationDateFilter;
    }

    if (form.creationDate?.length === 0 && !isEmpty(this.selectedFilters.selectedCreationDate)) {
      delete this.selectedFilters.selectedExpirationDate;
      delete response.expirationDateFilter;
    }

    if (form.category?.length === 0 && this.selectedFilters.selectedCategory?.length > 0) {
      delete this.selectedFilters.selectedCategory;
      delete response.categoryFilter;
    }

    if (form.paymentMethod?.length === 0 && this.selectedFilters.selectedPaymentMethod?.length > 0) {
      delete this.selectedFilters.selectedPaymentMethod;
      delete response.paymentMethodFilter;
    }

    if (form.name?.length === 0 && this.selectedFilters.selectedName?.length > 0) {
      delete this.selectedFilters.selectedName;
      delete response.nameFilter;
    }

    if (form.email?.length === 0 && this.selectedFilters.selectedEmail?.length > 0) {
      delete this.selectedFilters.selectedEmail;
      delete response.emailFilter;
    }

    if (form.document?.length === 0 && this.selectedFilters.selectedDocument?.length > 0) {
      delete this.selectedFilters.selectedDocument;
      delete response.documentFilter;
    }

    if (form.schedule?.length === 0 && this.selectedFilters.selectedSchedule?.length > 0) {
      delete this.selectedFilters.selectedSchedule;
      delete response.scheduleFilter;
    }

    if (
      (form.toAmount?.length === 0 && this.selectedFilters.selectedToAmount > 0) ||
      (form.toAmount === 'R$ 0,00' && this.selectedFilters.selectedToAmount > 0)
    ) {
      delete this.selectedFilters.selectedToAmount;
      delete response.valuesFilter;
    }

    if (
      (form.fromAmount?.length === 0 && this.selectedFilters.selectedFromAmount > 0) ||
      (form.fromAmount === 'R$ 0,00' && this.selectedFilters.selectedFromAmount > 0)
    ) {
      delete this.selectedFilters.selectedFromAmount;
      delete response.valuesFilter;
    }

    return response;
  }
}
