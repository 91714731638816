import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  CREATE_ITEM_CATEGORY_MUTATION,
  DELETE_ITEM_CATEGORY_MUTATION,
  UPDATE_ITEM_CATEGORY_MUTATION,
} from 'libs/shared/src/lib/graphql/mutations/category.mutations';
import { CategoryModel } from 'libs/shared/src/lib/models/categories/categories.model';
import { LIST_ITEM_CATEGORIES_QUERY } from 'libs/shared/src/lib/graphql/queries/category.queries';

@Injectable()
export class CategoryService {
  constructor(private readonly apollo: Apollo, private readonly afFunc: AngularFireFunctions) {}

  public createCategory(data: CategoryModel): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_CATEGORY_MUTATION,
      variables: data,
    });
  }

  public getCategoryList(sellerId: string, filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEM_CATEGORIES_QUERY,
      variables: { sellerId, filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public deleteCategory(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_CATEGORY_MUTATION,
      variables: { id },
    });
  }

  public updateCategory(data: Partial<CategoryModel>): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_CATEGORY_MUTATION,
      variables: data,
    });
  }

  public batchAssocCategory(data: CategoryModel): Observable<any> {
    return this.afFunc.httpsCallable('batchAssocCategory')(data);
  }
}
