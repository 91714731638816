import moment from 'moment-timezone';
import * as Sentry from '@sentry/angular';
import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Message } from '../../utils/message';
import { onlyNumbers } from '../../utils/utils';
import { SellerModel } from '../../models/sellers/sellers.model';
import { HubspotService } from '../../services/hubspot/hubspot.service';
import { UserClaimsModel } from '../../models/user-claims/user-claims.model';
import { AuthService } from '../../services/auth/auth-business/auth.service';
import { NotificationsModel } from '../../models/notifications/notifications.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { ModalLogoutComponent } from '../modals/modal-logout/modal-logout.component';

declare const window: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public title: string = '';
  public user: UserClaimsModel = {} as UserClaimsModel;
  public notifications: NotificationsModel[] = [];
  public read: NotificationsModel[] = [];
  public unRead: NotificationsModel[] = [];
  public readLimited: NotificationsModel[] = [];
  public unReadLimited: NotificationsModel[] = [];
  public formDashboard!: FormGroup;
  public seller: SellerModel = new SellerModel();

  constructor(
    private $auth: AuthService,
    private $hubspot: HubspotService,
    private readonly fb: FormBuilder,
    private $message: NzMessageService,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService,
    private $notificationsService: NotificationsService
  ) {}

  public ngOnInit(): void {
    this.getTitle();
    this.getUser();
    this.getSeller();
    this.createForm();
    this.getValueChanges();
  }

  public getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.seller = res;
      }
    });
  }

  private configureSentry(): void {
    Sentry.configureScope((scope: any) => {
      scope.setUser({
        id: this.user?.user_id,
        username: this.user?.name,
        email: this.user?.email,
        sellerId: this.user?.sellerId,
      });
      scope.setLevel('error');
    });
  }

  private createForm(): void {
    this.formDashboard = this.fb.group({
      dates: new FormControl([moment().subtract(1, 'month').toDate(), moment().toDate()], [Validators.required]),
    });

    this.$notification.setDashboardDates(this.formDashboard.get('dates').value);
  }

  private getValueChanges(): void {
    this.formDashboard.get('dates').valueChanges.subscribe((res) => {
      if (res) {
        this.$notification.setDashboardDates(res);
      }
    });
  }

  private initChat(): void {
    try {
      if (this.user?.email) {
        const name = this.user?.displayName || this.user?.name || '';
        const firstName = name.split(' ').slice(0, 1).join(' ');
        const lastName = name.split(' ').slice(-1).join(' ');

        this.$hubspot.createHubspotToken(this.user.email, firstName, lastName).subscribe((res) => {
          if (res.status === 200 && res.body?.token) {
            window.hsConversationsSettings = {
              loadImmediately: false,
              identificationEmail: this.user.email,
              identificationToken: res.body.token,
            };
          }
        });
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public toggleChat(): void {
    this.$hubspot.toggleChat();
  }

  public logout(): void {
    this.$modal
      .create({
        nzContent: ModalLogoutComponent,
      })
      .afterClose.subscribe((res: boolean) => {
        if (res) {
          this.$auth.logout();
        }
      });
  }

  private getTitle(): void {
    this.$notification.titles.subscribe((res) => {
      this.title = res;
    });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;

        if (this.user?.sellerId) {
          this.configureSentry();
          this.getNotifications();
        }

        this.initChat();
      }
    });
  }

  public getNotifications(): void {
    this.$notificationsService.getNotifications(this.user.marketplaceId, this.user.sellerId).subscribe({
      next: (res) => {
        if (res) {
          this.notifications = res;
          this.read = this.notifications.filter((notification) => notification.read);
          this.readLimited = this.read.slice(0, 3);

          this.unRead = this.notifications.filter((notification) => notification.read === false);
          this.unReadLimited = this.unRead.slice(0, 3);
        }
      },
      error: (error) => {
        this.$message.error(Message.ERROR_SEARCHING_INFORMATION);
        throw new Error(error);
      },
    });
  }

  public async markAllAsRead(): Promise<void> {
    try {
      if (this.user?.sellerId) {
        this.unRead.map(async (res) => {
          if (!res.read) {
            await this.$notificationsService.updateNotification(
              this.user?.marketplaceId,
              this.user?.sellerId,
              res,
              true
            );
          }
        });
      } else {
        this.$message.error(Message.ERROR_LOAD_DATA);
      }
    } catch (error: any) {
      this.$message.error(Message.ERROR_CONECTION);
      throw new Error(error);
    }
  }

  public onlyNumbers(event: any): void {
    return onlyNumbers(event);
  }
}
